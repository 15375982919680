<template>
  <div>
  <v-card>

    <v-card-title>
      <v-toolbar-title>Intermodal Dashboard</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn icon>
          <v-icon color ='primary' @click="closeDialog">cancel</v-icon>
      </v-btn>
      </v-card-title>
      <v-card-text>
        <v-row class="md-2">
          <v-col cols="12" md="4">
            <v-select
              :items="isoWeeks"
              label="Select Week"
              v-model="isoWeek"
              @change="getVesselData"
              outlined
              dense
            ></v-select>
          </v-col>
          <v-col cols="12" md="4">
            <v-select
              :items="years"
              label="Select Year"
              v-model="isoYear"
              outlined
              dense
              :disabled="true"
            ></v-select>
          </v-col>
          <v-col cols="12" md="4">
            <v-select
              :items="movementTypes"
              label="Select Movement Type"
              v-model="movementType"
              outlined
              dense
              :disabled="true"
            ></v-select>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" md="4">
            <v-list class="ml-3" dense>
                <v-chip-group row>
                <v-chip             @click="addPol(pol)"
                     v-for="(pol, index) in uniquePOL" :key="index" small :color="selectedPol.includes(pol)? 'success' : ''"
                >{{ pol }}</v-chip>
              </v-chip-group>
            </v-list>
          </v-col>
          <v-col cols="12" md="4">

          </v-col>
          <v-col cols="12" md="4" >
            <v-row>
              <v-col cols="6">
                <v-text-field
              v-model="search"
              append-icon="search"
              label="Search"
              single-line
              hide-details
              dense
            ></v-text-field>
              </v-col>
        
            <v-col cols="6" >
            <v-switch
            class="ml-4 mt-2"
              v-model="filterAll"
              label="Show All"
              color="primary"
              @change="getVesselData"
              dense></v-switch>
            </v-col>
            </v-row>

          </v-col>
        </v-row>
        <v-row>
        <v-col cols="10" md="12">
        <v-data-table
          :items=" filteredVesselData"
          :headers="intermodalHeaders"
          :items-per-page="10"
          :loading="vesselDataLoader"
          dense
           >
          <template v-slot:[`item.vesselVoyage`]="{ item }">
            <span>{{ item.vessel.name + '-' + item.exportVoyage }}</span>
          </template>
           <template v-slot:[`item.stackOpen`]="{ item }">
             <v-chip
              :color="item.rmsVesselVoyageStacks[0].reeferStackStartConfirmed ? 'success' : 'error'"
              small
              class="fixed-size-chip"
              >
              {{ item.rmsVesselVoyageStacks[0].reeferStackStart ? formatDate(item.rmsVesselVoyageStacks[0].reeferStackStart) : 'N/A'}}
              </v-chip>
          </template>
         <template v-slot:[`item.stackClose`]="{ item }">
          <v-chip
              :color="item.rmsVesselVoyageStacks[0].reeferStackFinishConfirmed ? 'success' : 'error'"
              small
              class="fixed-size-chip"

              >
              {{ item.rmsVesselVoyageStacks[0].reeferStackFinish ? formatDate(item.rmsVesselVoyageStacks[0].reeferStackFinish) : 'N/A'}}
              </v-chip>
          </template>
           <template v-slot:[`item.total`]="{ item }">
            <!-- <span @click="getRelevantVesselData(total,item.vessel.id)">{{ item.vessel.total ? item.vessel.total : '-' }}</span> -->
           
            <v-btn text @click="getSelectedVesselData('total',item.vessel.id,item.rmsVesselVoyageStacks[0].id)"
             v-if="item.vessel.total"
              small
              >
              {{ item.vessel.total ? item.vessel.total : '-'}}
              </v-btn>
              <v-span v-else>{{  }}</v-span>
          </template>
          <template v-slot:[`item.noLoadingDate`]="{ item }">
            <v-btn text @click="getSelectedVesselData('noLoadingDate',item.vessel.id,item.rmsVesselVoyageStacks[0].id)"
             v-if="item.vessel.noLoadingDate"
              small
              >
              {{ item.vessel.noLoadingDate ? item.vessel.noLoadingDate : '-'}}
              </v-btn>
              <v-span v-else>{{  }}</v-span>
            <!-- <span>{{ item.vessel.noLoadingDate ? item.vessel.noLoadingDate : '-' }}</span> -->
          </template>
          <template v-slot:[`item.privCarrierTotal`]="{ item }">
            <v-btn text @click="getSelectedVesselData('carrierTotal',item.vessel.id,item.rmsVesselVoyageStacks[0].id)"
             v-if="item.vessel.carrierTotal"
              small
              >
              {{ item.vessel.carrierTotal ? item.vessel.carrierTotal : '-'}}
              </v-btn>
              <v-span v-else>{{  }}</v-span>
            <!-- <span>{{ item.vessel.carrierTotal ? item.vessel.carrierTotal : '-'}}</span> -->
          </template>
          <template v-slot:[`item.notReady`]="{ item }">
            <v-btn text @click="getSelectedVesselData('notReady',item.vessel.id,item.rmsVesselVoyageStacks[0].id)"
             v-if="item.vessel.notReady"
              small
              >
              {{ item.vessel.notReady ? item.vessel.notReady : '-'}}
              </v-btn>
              <v-span v-else>{{  }}</v-span>
            <!-- <span>{{ item.vessel.notReady ? item.vessel.notReady:'-' }}</span> -->
          </template>
          <template v-slot:[`item.notStacked`]="{ item }">
            <v-btn text @click="getSelectedVesselData('notStacked',item.vessel.id,item.rmsVesselVoyageStacks[0].id)"
             v-if="item.vessel.notStacked"
              small
              >
              {{ item.vessel.notStacked ? item.vessel.notStacked : '-'}}
              </v-btn>
              <v-span v-else>{{  }}</v-span>
            <!-- <span>{{ item.vessel.notStacked ? item.vessel.notStacked : '-' }}</span> -->
          </template>
          <template v-slot:[`item.notPreadvised`]="{ item }">
            <v-btn text @click="getSelectedVesselData('notPreadvised',item.vessel.id,item.rmsVesselVoyageStacks[0].id)"
             v-if="item.vessel.notPreadvised"
              small
              >
              {{ item.vessel.notPreadvised ? item.vessel.notPreadvised : '-'}}
              </v-btn>
              <v-span v-else>{{  }}</v-span>
            <!-- <span>{{ item.vessel.notPreadvised ?  item.vessel.notPreadvised : '-' }}</span> -->
          </template> 
        </v-data-table>
        </v-col>
        </v-row>
      </v-card-text>
      
 

  </v-card>

  <v-dialog v-model="relevantDialog">
        <v-card>
          <v-card-title>
            <v-toolbar-title>Vessel Data - {{ selectedType }}</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn icon @click="relevantDialog = false,selectedType=''">
              <v-icon color ='primary'>cancel</v-icon>
            </v-btn>
          </v-card-title>
          <v-card-text>
            <v-data-table
              :items="selectedVesselData"
              :headers="vesselHeader"
              :items-per-page="10"
              :loading="selectedVesselLoading"
              dense
              >
              <template v-slot:[`item.loadingPoint1Date`]="{ item }">
                <span>{{ formatDate(item.containerMilestones.find(milestone => milestone.type==='LOADING_POINT_1')?.date) }}</span>
                </template>

                <template v-slot:[`item.loadingPoint1`]="{ item }">
                <span>{{ item.containerMilestones.find(milestone => milestone.type==='LOADING_POINT_1')?.description }}</span>
                </template>

                <template v-slot:[`item.loadingPoint2`]="{ item }">
                <span>{{ item.containerMilestones.find(milestone => milestone.type==='LOADING_POINT_2')?.description }}</span>
                </template>
            </v-data-table>
            </v-card-text>
        </v-card>
          
      </v-dialog>
  </div>
</template>

<script>
import moment from 'moment'

export default {
  data: () => ({
    // Your data here
    filterAll:false,
    relevantDialog:false,
    vesselData:[],
    selectedPol: [],
    vesselDataLoader:false,
    isoWeek: moment().isoWeek(),
    isoYear : moment().year(),
    movementType: 'Export',
    movementTypes: ['Export','Import'],
    search:'',
    selectedVesselData:[],
    selectedVesselLoading:false,
    selectedType:'',
    vesselHeader:[
      {
        text:'CTO No',
        value:'ctoNo'
      },
      {
        text:'LP-1 Date',
        value:'loadingPoint1Date'
      },
      {
        text:'LP-1',
        value:'loadingPoint1'
      },
      {
        text:'LP-2',
        value:'loadingPoint2'
      },
      {
        text:'Shipper',
        value:'booking.shipper.name'
      },
      {
        text:'Customer Ref',
        value:'customerContainerRef'
      },
      {
        text:'Booking Ref',
        value:'booking.carrierReferenceNumber'
      },
      {
        text:'POL',
        value:'booking.portOfLoadValue'
      },
      {
        text:'Final Destination',
        value:'booking.finalDestinationCity'
      }, 
   
    ],
    intermodalHeaders:[
      {
        text:'POL',
        value:'pol',
        allign:'center'
      },
      {
        text:'Vessel & Voyage',
        value:'vesselVoyage',
        allign:'center'

      },
      {
        text:'Stack Open',
        value:'stackOpen',
        allign:'center'

      },
      {
        text:'Stack Close',
        value:'stackClose',
        allign:'center'

      },
      {
        text:'Total',
        value:'total',
        allign:'center'

      },
      {
        text:'No Loading Date',
        value:'noLoadingDate',
        allign:'center'
      },
      {
        text:'Priv. & Carrier Total',
        value:'privCarrierTotal',
        allign:'center'

      },
      {
        text:'Not Ready',
        value:'notReady',
        allign:'center'

      },
      {
        text:'Not Stacked',
        value:'notStacked',
        allign:'center'

      },
      {
        text:'Not Preadvised',
        value:'notPreadvised',
        allign:'center'

      }
    ]
  }),
  computed: {
    isoWeeks(){
      let isoWeeks = []
      //take the current isoWeek -6 + 6
      for(let i = this.isoWeek-6; i<=this.isoWeek+6; i++){
        if (i >= 1 && i <= 52) {
        isoWeeks.push(i);
        }
      }
      return isoWeeks
    },
    filteredVesselData() {
      return this.vesselData.filter(item =>
        (item.vessel.name + '-' + item.exportVoyage).toLowerCase().includes(this.search.toLowerCase()) &&
        (this.selectedPol.length === 0 || this.selectedPol.includes(item.pol))

      );
    },
    years(){
      let years = []
      for(let i = this.isoYear-2; i<=this.isoYear+2; i++){
        years.push(i)
      }
      return years
    },
    uniquePOL(){
      let uniquePOL = []
      this.vesselData.forEach(vessel=>{
        if(!uniquePOL.includes(vessel.pol)){
          uniquePOL.push(vessel.pol)
        }
      })
      return uniquePOL
    }
  },
  mounted() {
    // Your mounted hook code here
    this.getVesselData()
  },
  methods: {
    closeDialog(){
      // this.vesselData=[]
      // this.selectedVesselData=[]
      // this.selectedType=''
      this.$emit('close-dialog');
    },
    formatDate(dateString){
      if (!dateString) return 'N/A';
      
      const date = new Date(dateString);
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, '0');
      const day = String(date.getDate()).padStart(2, '0');
      const hours = String(date.getHours()).padStart(2, '0');
      const minutes = String(date.getMinutes()).padStart(2, '0');

      return `${year}-${month}-${day} ${hours}:${minutes}`;
    },
    async getVesselData(){
      this.vesselDataLoader =true
      this.vesselData = await this.$API.getIntermodalDashData({
        isoWeek: this.isoWeek
      })
      if(this.filterAll === false){
        //if vessel dont have all of the above total,notPreadvised,notStacked,notReady,carrierTotal,noLoadingDate then remove the whole entry from vesselData
        this.vesselData = this.vesselData.filter(vessel=>{
          return vessel.vessel.total || vessel.vessel.notPreadvised || vessel.vessel.notStacked || vessel.vessel.notReady || vessel.vessel.carrierTotal || vessel.vessel.noLoadingDate
        })

      }
      console.log('vesselData',this.vesselData)
      this.vesselDataLoader=false
    },
    async getSelectedVesselData(type,vesselId,stackDateId){
      this.relevantDialog = true
      this.selectedVesselLoading=true
      this.selectedType = type
      this.selectedVesselData = await this.$API.getSelectedDashData({
        isoWeek: this.isoWeek,
        type: type,
        vesselId: vesselId,
        stackDateId: stackDateId
      })
      console.log('selectedVesselData',this.selectedVesselData)
      this.selectedVesselLoading=false

    },
    addPol(pol){
      if(this.selectedPol.includes(pol)){
        //remove it
        this.selectedPol = this.selectedPol.filter(item=>item !== pol)
      }
      else{
        this.selectedPol.push(pol)
      }
    }
  }
}
</script>

<style scoped>
.fixed-size-chip {
  min-width: 80px; /* Adjust this value as needed */
  max-width:120px;
  padding: 0 10px; /* Adjust padding as needed */
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
