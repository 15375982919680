<template>
  <div>
    <v-card flat :loading="loading">
      <v-row class="pa-3" align="center">
        <v-col cols="12" sm="6" class="mb-0 pb-0">
          <v-row>
            <v-col cols="12">
              <!-- <v-chip class="mx-1" small outlined v-for="(date, index) in quickSelectDates" :key="index"
                :value="date.value" @click="selectDate(date.value)"
                :color="params.selectedDate && params.selectedDate == date.value ? 'teal' : ''">
                <v-icon left v-if="params.selectedDate == date.value" color="teal" small>check</v-icon>
                {{ date.text }}</v-chip> -->
              <v-menu offset-y>
                <template v-slot:activator="{ on }">
                  <v-chip small outlined class="primaryText--text mx-1 smallText" color="blue-grey" v-on="on">
                    <v-icon x-small left>event</v-icon>
                    {{
      quickSelectDates.find(
        (x) => x.value == params.selectedDate
      )
        ? quickSelectDates.find(
          (x) => x.value == params.selectedDate
        ).text
        : "Select Date"
    }}<v-icon small right>expand_more</v-icon>
                  </v-chip>
                </template>
                <v-list style="overflow: hidden" dense>
                  <v-list-item v-for="(date, index) in quickSelectDates" :key="index" @click="selectDate(date.value)">
                    <v-list-item-content>
                      <v-list-item-title>
                        {{ date.text }}
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </v-menu>
              <v-menu ref="menu" v-model="dateMenu" :close-on-content-click="false" transition="scale-transition"
                offset-y min-width="auto">
                <template v-slot:activator="{ on, attrs }">
                  <v-chip :color="params.selectedDate ? 'blue' : 'orange'" class="mx-1" outlined v-bind="attrs"
                    v-on="on" small><v-icon small left>date_range</v-icon>{{ dateText }}
                    <v-icon small right>expand_more</v-icon></v-chip>
                </template>
                <v-date-picker v-model="params.dates" range color="primary">
                  <v-spacer></v-spacer>
                  <v-btn text color="primary"> Cancel </v-btn>
                  <v-btn text color="primary" @click="setDateFilter">
                    OK
                  </v-btn>
                </v-date-picker>
              </v-menu>
              <v-menu offset-y>
                <template v-slot:activator="{ on }">
                  <v-chip small class="primaryText--text mx-1" color="blue-grey" v-on="on">
                    <v-icon small left>event</v-icon> {{ dateType
                    }}<v-icon small right>expand_more</v-icon>
                  </v-chip>
                </template>
                <v-list style="overflow: hidden" dense>
                  <v-list-item v-for="(miletype, index) in milestoneTypes" :key="index"
                    @click="(dateType = miletype.name), getContainers()">
                    <v-list-item-content>
                      <v-list-item-title>
                        {{ miletype.name }}
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </v-menu>


            </v-col>

            <v-col cols="12">
              <v-chip class="mb-1 mr-1" v-for="(status, index) in availableTransportStatuses" :key="index" :color="filters.transportStatus &&
      filters.transportStatus.includes(status.text)
      ? status.color
      : ''
      " small @click="addToFilter(status.text, 'transportStatus')">
                <v-icon small left v-if="filters.transportStatus &&
      filters.transportStatus.includes(status.text)
      ">check</v-icon>
                {{ status.text }}
              </v-chip>
            </v-col>
          </v-row>
        </v-col>

        <!-- <v-chip v-if="!loading" color="blue-grey" class="mx-1" small>
                    <v-icon small left>widgets</v-icon> {{ displayData.length }} Containers
                </v-chip> -->
        <!-- <v-spacer></v-spacer> -->
        <!-- <v-text-field hide-details prepend-inner-icon="search" placeholder="Search" v-model="search" outlined dense
                    rounded clearable></v-text-field> -->

        <v-col cols="12" sm="6" class="text-right mb-0 pb-0">
          <v-row align="center" justify="end" class="mb-2">
            <v-btn color="blue" class="mr-1" small rounded outlined @click="getContainers(), getContainerWithNoPOH()"
              :loading="loading">
              <v-icon small>refresh</v-icon></v-btn>
            <v-btn v-if="settingsChange" color="blue" class="mr-2" small rounded outlined @click="saveCustomHeaders()"
              :loading="loadingSaveCustom">
              <v-icon small left>save</v-icon> Save Layout</v-btn>

            <v-chip style="height: 40px" outlined small class="pr-1 text-center">
              <v-menu offset-y :disabled="selectedContainers.length == 0 || selectedContainers.length > 50">
                <template v-slot:activator="{ on }">
                  <!-- <v-chip
                  small
                  class="primaryText--text mx-1 mr-2"
                  :color="selectedContainers.length > 0 ? 'blue' : 'grey'"
                  v-on="on"
                  style="border: none"
                >
                  Mass Actions<v-icon small>expand_more</v-icon>
                </v-chip> -->
                  <v-chip small class="primaryText--text mx-1" :color="selectedContainers.length > 0 ? 'blue' : 'grey'"
                    v-on="on" style="border: none">
                    Mass Actions<v-icon small right>expand_more</v-icon>
                  </v-chip>
                </template>
                <v-list style="overflow: hidden" dense>
                  <!-- <v-list-item
                    style="height: 35px"
                    @click="bulkCTOModal = true"
                  >
                    <v-list-item-action>
                      <v-icon color="grey">article</v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                      <v-list-item-title> Bulk Create CTO </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item> -->

                  <v-list-item style="height: 35px" @click="bulkDownload = true">
                    <v-list-item-action>
                      <v-icon color="grey">cloud_download</v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                      <v-list-item-title>
                        Bulk Download CTO's
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>

                  <v-list-item style="height: 35px" @click="bulkUpdateStatusModal = true">
                    <v-list-item-action>
                      <v-icon color="grey">fiber_manual_record</v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                      <v-list-item-title> Update Status </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item style="height: 35px" @click="assignContainers(selectedContainers)">
                    <v-list-item-action>
                      <v-icon color="grey">local_shipping</v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                      <v-list-item-title>
                        Assign Transporter
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>

                  <v-list-item style="height: 35px" @click="pluggedInMail">
                    <v-list-item-action>
                      <v-icon color="grey">email</v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                      <v-list-item-title>
                        Plug In Mail
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>

                  <v-list-item style="height: 35px" @click="confirmRemoveTransporter = true">
                    <v-list-item-action>
                      <v-icon color="red">cancel</v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                      <v-list-item-title>
                        Remove Transporter
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </v-menu>
              <v-chip small class="mr-1 pr-3">TOTAL:
                <span style="padding-right: 10px; margin-left: 5px">
                  {{ displayData.length }}</span>
              </v-chip>
              <v-menu offset-y>
                <template v-slot:activator="{ on }">
                  <v-chip small class="primaryText--text mx-1" v-on="on" style="border: none">

                    Manage<v-icon small right>expand_more</v-icon>
                  </v-chip>
                </template>
                <v-list style="overflow: hidden" dense>
                  <v-list-item style="height: 35px" @click="bulkUploadCTO">
                    <v-list-item-action>
                      <v-icon color="grey">cloud_upload</v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                      <v-list-item-title> Load data upload </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>

                  <v-list-item style="height: 35px" @click="clientReportModal = true">
                    <v-list-item-action>
                      <v-icon color="grey">summarize</v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                      <v-list-item-title> Client Report </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>

                  <v-list-item style="height: 35px" @click="pluggedInReport = true">
                    <v-list-item-action>
                      <v-icon color="grey">electrical_services</v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                      <v-list-item-title> Plugged In Report </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>

                  <v-list-item style="height: 35px" @click="transporterReportModal = true">
                    <v-list-item-action>
                      <v-icon color="grey">email</v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                      <v-list-item-title> Email CTO </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>

                  <v-list-item style="height: 35px" @click="transportDashBoard = true">
                    <v-list-item-action>
                      <v-icon color="grey">assessment</v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                      <v-list-item-title> Vessel Data </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>

                </v-list>
              </v-menu>
              <v-tooltip top open-delay="500">
                <template v-slot:activator="{ on }">
                  <div v-on="on">
                    <v-menu offset-y nudge-right :close-on-content-click="false"
                      style="height: 20vh; margin-right: 8vw">
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn v-bind="attrs" v-on="on" icon><v-icon>view_week</v-icon></v-btn>
                      </template>
                      <v-card style="
                          background: var(--v-modal-base) !important;
                          border-radius: 20px;
                        ">
                        <v-card-text>
                          <v-list style="
                              overflow: hidden;
                              column-count: 3;
                              width: 400px;
                              height: fit-content;
                            ">
                            <v-checkbox v-for="(item, index) in headers.filter(
      (x) => x.value != 'action'
    )" :key="index" v-model="item.show" @change="toggleColumn(item)" dense :label="item.text"
                              hide-details></v-checkbox>
                          </v-list>
                          <v-row class="pt-5 pb-4" style="overflow-y: hidden">
                            <v-col cols="12" md="12" sm="12" lg="12" class="d-flex justify-center">
                              <v-btn @click="saveCustomHeaders" :loading="loadingSaveCustom" outlined
                                color="primary">SAVE</v-btn>
                            </v-col>
                          </v-row>
                        </v-card-text>
                      </v-card>
                    </v-menu>
                  </div>
                </template>
                Customize Layout
              </v-tooltip>
              <v-text-field placeholder="Search" class="mb-1" hide-details rounded clearable dense style="width: 300px"
                v-model="search"></v-text-field>
            </v-chip>
          </v-row>
          <!-- <v-chip style="height: 40px" outlined small class="pr-1 text-center">
                        <v-chip small class="mr-1 pr-3">TOTAL:
                            <span style="padding-right: 5px; margin-left: 5px"> {{
                                displayData.length
                            }}</span>
                        </v-chip>
                    </v-chip> -->

          <v-row class="my-3" :key="updateFilterValue" justify="end">
            <div :key="updateFilterValue">
              <v-chip color="red" class="mx-1" small @click="resetFilters()" v-if="filteredValues.length > 0">
                <v-icon small left>filter_alt_off</v-icon> Clear Filters
              </v-chip>
              <v-chip class="mx-1" close small @click:close="addToFilter(filterValue.value, filterValue.field)"
                v-for="(filterValue, index) in filteredValues" :key="index">{{ filterValue.value }}</v-chip>
            </div>
          </v-row>
        </v-col>
      </v-row>
      <v-row class="pa-3 mb-3 mt-1 bordered-contaniner" align="center">
        <v-col cols="6" sm="6" class="text mb-0 pb-0">

          <v-row>
            <v-chip small outlined :key="index" :value="total" class="mb-1 mr-1" v-if="noPOHTotal > 0"
              @click="openPOHDialog = true">
              {{ 'No Port of Holding' }}
              <v-avatar :color="'red'" right>
                <h4 style="color:black">
                  {{ noPOHTotal }}
                </h4>
              </v-avatar>
            </v-chip>
          </v-row>

          <v-row>
            <v-chip small outlined v-for="(total, index) in uniqueUnallocated" :key="index" :value="total"
              class="mb-1 mr-1" @click="addToFilter(total.text, 'transportStatus')" :color="filters.transportStatus &&
      filters.transportStatus.includes(total.text)
      ? 'teal'
      : ''
      ">
              <v-tooltip bottom>
                <template>
                  <!-- <v-avatar :color="'orange'" left>
                      <v-icon v-on="on" style="color: black">electrical_services</v-icon>
                    </v-avatar> -->
                </template>
                Unallocated
              </v-tooltip>
              {{ total.text }}
              <v-avatar :color="'red'" right>
                <h4 style="color:black"> {{ total.value }}
                </h4>
              </v-avatar>
            </v-chip>
            <!-- <p>Date from WebSocket: {{ receivedDate }}</p> -->
            <v-chip class="mb-1 mr-1" v-for="(transporter, index) in uniqueTransporters" :key="index" small
              @click="addToFilter(transporter.text, 'transporterName')" :color="filters.transporterName &&
      filters.transporterName.includes(transporter.text)
      ? 'teal'
      : ''
      ">
              <v-icon small left v-if="filters.transporterName &&
      filters.transporterName.includes(transporter.text)
      ">check</v-icon>
              {{ transporter.text }}
              <v-avatar right>
                <h4>
                  {{ transporter.value }}
                </h4>
              </v-avatar>
            </v-chip>
          </v-row>

        </v-col>
        <v-col cols="6" sm="6" class="mb-0 pb-0">
          <v-row class="mb-1">
            <v-chip small outlined v-for="(port, index) in favPorts" :key="index" :value="port.locode"
              @click="selectPort(port)"
              :color="params.selectedPorts && params.selectedPorts.length > 0 && params.selectedPorts.some(p => p.locode === port.locode) ? 'blue' : ''">
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-avatar :color="'blue'" left>
                    <v-icon v-on="on" style="color: black">directions_boat</v-icon>
                  </v-avatar>
                </template>
                Port
              </v-tooltip>
              {{ port.name }}
            </v-chip>
          </v-row>

          <v-row>
            <v-chip small outlined color="red" @click="getContainers(), selectedBookingContainerIds = []"
              v-if="plugInData.length > 0 || selectedBookingContainerIds.length > 0">
              Clear Plug-in
              <v-icon right small>clear</v-icon>
            </v-chip>
            <v-chip small outlined v-for="(total, index) in plugInData" :key="index" :value="total"
              :color="total.bookingContainerIds.some(id => selectedBookingContainerIds.includes(id)) ? 'teal' : ''"
              @click="
      addPlugInData(
        total
      )
      ">
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-avatar :color="'orange'" left>
                    <v-icon v-on="on" style="color: black">electrical_services</v-icon>
                  </v-avatar>
                </template>
                Plugged
              </v-tooltip>
              {{ total.text }}
              <v-avatar :color="'red'" right>
                <h4 style="color: black">{{ total.count }}</h4>
              </v-avatar>
            </v-chip>
          </v-row>

        </v-col>
      </v-row>

      <v-card-text style="max-width: 100%; overflow-x: auto" class="my-0 py-0">
        <div :style="{ width: tableWidth }" v-if="!loading && displayData.length > 0">
          <table :width="'100%'">
            <tr class="mx-0 px-0">
              <draggable v-model="selectedHeaders" tag="tr" :style="{ cursor: columnDrag ? 'grabbing' : 'grab' }"
                :animation="200" @change="updateUserLayout()" ghost-class="ghost-card" @start="columnDrag = true"
                @end="columnDrag = false">
                <td class="text-center mx-0 px-0 non-sticky-header" style="cursor: grab; border-right: 1px solid grey"
                  v-for="(header, index) in selectedHeaders" :key="index" :width="header.width ?? '150px'" :class="{
      'sticky-column': header.value === 'actions',
    }">
                  <div style="display: flex;flex-direction: row;justify-content: space-around">
                    <v-row align="center" justify="center" class="py-2">
                      <b style="font-size: 12px;width: 100%">{{ header.text }}</b>
                      <v-checkbox v-if="header.value == 'actions'" :indeterminate="selectedContainers.length > 0 &&
      selectedContainers.length < filteredResults.length
      " hide-details v-model="selectAllContainers" dense class="mx-0 px-0 mb-2"></v-checkbox>

                      <v-btn v-if="!header.disableSort" class="ml-1" icon x-small @click="changeSortBy(header.value)">
                        <v-icon v-if="sortBy != header.value" small color="grey">arrow_upward</v-icon>
                        <v-icon v-else-if="sortDesc" small>arrow_downward</v-icon>
                        <v-icon v-else small>arrow_upward</v-icon>
                      </v-btn>
                      <v-badge v-if="!header.disableFilter" :content="filters[header.value]
      ? filters[header.value].length
      : false
      " :value="filters[header.value] &&
      filters[header.value].length > 0
      " color="primary" offset-y="10">
                        <v-menu offset-y left :close-on-content-click="false">
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn v-bind="attrs" v-on="on" x-small color="grey" icon><v-icon
                                small>filter_alt</v-icon></v-btn>
                          </template>
                          <v-card style="
                              background: var(--v-modal-base) !important;
                              border-radius: 20px;
                            ">
                            <v-card-text>
                              <v-list dense style="
                                  background: var(--v-modal-base) !important;
                                  border-radius: 20px;
                                ">
                                <v-text-field hide-details rounded dense outlined clearable prepend-inner-icon="search"
                                  placeholder="Search" autofocus v-model="searchHeaders[header.value]"
                                  @input="updateSearchKey()"></v-text-field>
                                <!--  <v-list dense style="
                                    max-height: 50vh;
                                    overflow-y: auto;
                                    background: var(--v-modal-base) !important;
                                  " :key="searchKey">
                                  <v-list-item v-for="(item, index) in headerOptions(
                                    header.value
                                  )" :key="index">
                                    <v-list-item-action class="pr-0">
                                      <v-checkbox v-model="filters[header.value]" :value="item.value" hide-details dense
                                        @change="calculateFilteredResults()" @click="addToFilter(item.value, header.value)"></v-checkbox>
                                    </v-list-item-action>
                                    <v-list-item-content>
                                      <v-list-item-title>
                                        {{ item.name }}
                                      </v-list-item-title>
                                    </v-list-item-content>
                                  </v-list-item>
                                  <v-list-item v-if="headerOptions(header.value).length == 0
                                    ">
                                    <v-list-item-content class="text-center">
                                      <span style="font-size: 12px">No results found</span>
                                    </v-list-item-content>
                                  </v-list-item>
                                </v-list>
                              </v-list> -->
                                <v-list dense
                                  style="max-height: 50vh; overflow-y: auto; background: var(--v-modal-base) !important;"
                                  :key="searchKey">
                                  <v-list-item v-for="(item, index) in headerOptions(header.value)" :key="index">
                                    <v-list-item-action class="pr-0">
                                      <!-- Updated Checkbox Binding -->
                                      <v-checkbox v-model="checkboxModels[header.value][item.value]" hide-details dense
                                        @change="toggleFilter(item.value, header.value)"></v-checkbox>

                                    </v-list-item-action>
                                    <v-list-item-content>
                                      <v-list-item-title>
                                        {{ item.name }}
                                      </v-list-item-title>
                                    </v-list-item-content>
                                  </v-list-item>
                                  <v-list-item v-if="headerOptions(header.value).length == 0">
                                    <v-list-item-content class="text-center">
                                      <span style="font-size: 12px">No results found</span>
                                    </v-list-item-content>
                                  </v-list-item>
                                </v-list>
                              </v-list>
                            </v-card-text>
                          </v-card>
                        </v-menu>
                      </v-badge>
                    </v-row>
                  </div>
                </td>
              </draggable>
            </tr>
          </table>

          <v-divider></v-divider>
          <div class="mt-1 pt-1 px-1" :style="{ width: '100%' }">
            <v-virtual-scroll v-if="displayData.length > 0" :bench="10" :items="displayData" :height="calculatedHeight"
              :width="'100%'" item-height="35" class="px-1">
              <template v-slot:default="{ item }">
                <div :style="{
      display: 'flex',
      'flex-direction': 'row',
      width: '100%',
    }">
                  <td v-for="(header, index) in selectedHeaders" :key="index"
                    :style="{ width: header.width ?? '150px' }" class="text-center non-sticky-header" :class="{
      'sticky-column': header.value === 'actions',
    }">
                    <div v-if="header.value == 'actions'">
                      <v-row align="center" justify="center" class="py-1">
                        <v-tooltip top v-if="!(item.isCarrierHaulage || item.privateLoad)">
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn small icon :color="item.isConfirmedBooking ? 'success' : 'red'" @click="
      addToFilter(
        item.isConfirmedBooking,
        'isConfirmedBooking'
      )
      ">
                              <v-icon :color="item.isConfirmedBooking ? 'success' : 'red'" v-on="on" v-bind="attrs">
                                {{ item.isConfirmedBooking ? 'check_circle_outline' : 'cancel' }}
                              </v-icon>
                            </v-btn>
                          </template>
                          <span>{{ item.isConfirmedBooking ? 'Ready' : 'Not Ready' }}</span>
                        </v-tooltip>
                        <v-tooltip top v-if="item.booking.user">
                          <template v-slot:activator="{ on }">
                            <v-avatar size="24" class="ml-1" v-on="on"
                              :color="item.booking.user.avatar ? 'white' : 'secondary'">
                              <v-img v-if="item.booking.user.avatar" contain :src="item.booking.user.avatar"></v-img>
                              <h4 v-else>{{ item.booking.user.firstname.charAt(0) }}</h4>
                            </v-avatar>
                          </template>
                          <span style="font-size: 12px">{{
      item.booking.user.firstname
    }} {{ item.booking.user.surname }}</span>
                        </v-tooltip>

                        <v-checkbox hide-details :value="selectedContainers
      .map((x) => x.id)
      .includes(item.id)
      " @change="selectContainer(item)" dense class="mb-2 mx-0 px-0"
                          v-if="!(item.isCarrierHaulage || item.privateLoad)"></v-checkbox>
                        <v-btn v-if="item" icon small @click="openContainer(item)" class="ml-0 mb-0 pb-0"><v-icon
                            small>launch</v-icon></v-btn>
                        <v-menu right offset-x :close-on-content-click="false">
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn :color="item.hasComments ? 'tertiary' : ''" v-on="on" class="mb-0 pb-0"
                              v-bind="attrs" v-if="item" small icon @click="getContainerComments(item)"><v-icon
                                small>forum</v-icon></v-btn>
                          </template>
                          <v-card height="50vh" width="20vw" style="overflow-y: hidden">
                            <v-subheader>{{ item.ctoNo }}</v-subheader>
                            <v-divider></v-divider>
                            <!-- CHAT AREA -->
                            <v-card height="37.5vh" width="inherit" style="overflow-y: auto" flat
                              :loading="loadingComments">
                              <v-list>
                                <v-list-item v-if="item.containerComments?.length == 0">
                                  <v-list-item-content class="text-center">
                                    <span style="font-size: 12px; color: grey">
                                      No comments.
                                    </span>
                                  </v-list-item-content>
                                </v-list-item>
                                <div v-if="item.containerComments">
                                  <v-list-item v-for="(
                                      comment, index
                                    ) in item.containerComments" :key="comment.id" :id="'comment_' + index"
                                    class="py-0 my-1">
                                    <v-list-item-avatar v-if="comment.user &&
      comment.userId != $store.state.user.id
      ">
                                      <v-tooltip top v-if="comment.user">
                                        <template v-slot:activator="{ on, attrs }">
                                          <v-avatar v-bind="attrs" v-on="on" style="cursor: pointer" color="secondary"
                                            size="48" class="mt-0 pt-0">
                                            <span v-if="!comment.user.avatar" class="primaryText--text text-h5">{{
     $Format.avatarAbbreviation(
        comment.user.firstName +
        " " +
        comment.user.surname
      )
    }}</span>
                                            <img v-else :src="comment.user.avatar" referrerpolicy="no-referrer" />
                                          </v-avatar>
                                        </template>
                                        <span style="font-size: 12px" v-if="comment.user">{{ comment.user.firstName }}
                                          {{ comment.user.surname }}</span>
                                      </v-tooltip>
                                    </v-list-item-avatar>
                                    <v-list-item-content :class="comment.userId == $store.state.user.id
      ? 'text-right'
      : 'text-left'
      ">
                                      <v-list-item-title class="text-wrap">
                                        {{ comment.comment }}
                                      </v-list-item-title>
                                      <v-list-item-subtitle>
                                        <i style="font-size: 12px; color: grey">{{
      formatCommentDateTime(
        comment.createdAt
      )
    }}</i>
                                      </v-list-item-subtitle>
                                    </v-list-item-content>
                                    <v-list-item-avatar v-if="comment.userId == $store.state.user.id
      ">
                                      <v-tooltip top>
                                        <template v-slot:activator="{ on, attrs }">
                                          <v-avatar v-bind="attrs" v-on="on" style="cursor: pointer" color="secondary"
                                            size="48" class="mt-0 pt-0">
                                            <span v-if="!comment.user.avatar" class="primaryText--text text-h5">{{
     $Format.avatarAbbreviation(
        comment.user.firstName +
        " " +
        comment.user.surname
      )
    }}</span>
                                            <img v-else :src="comment.user.avatar" referrerpolicy="no-referrer" />
                                          </v-avatar>
                                        </template>
                                        <span style="font-size: 12px" v-if="comment.user">{{ comment.user.firstName }}
                                          {{ comment.user.surname }}</span>
                                      </v-tooltip>
                                    </v-list-item-avatar>
                                  </v-list-item>
                                </div>
                              </v-list>
                            </v-card>
                            <v-card flat height="8vh" width="inherit" style="
                                position: absolute;
                                bottom: 0;
                                background-color: var(
                                  --v-greyRaised-base
                                ) !important;
                                display: flex;
                                align-items: center;
                                overflow-y: hidden;
                              ">
                              <v-textarea class="ml-2" no-resize hide-details outlined autofocus height="7vh"
                                style="width: 80%; contain: content" placeholder="Send a message"
                                v-model="containerChatArea" :value="containerChatArea"></v-textarea>
                              <v-btn :loading="sendingMessage" icon class="ml-3 mr-2"
                                @click="commentContainer(item)"><v-icon>send</v-icon></v-btn>
                            </v-card>
                          </v-card>
                        </v-menu>

                      </v-row>

                      <!-- </v-row> -->
                    </div>
                    <div v-else-if="header.value == 'grossWeight'">
                      <v-chip v-if="item.grossWeight" small class="primaryText--text" outlined style="border: none">
                        <v-avatar class="mr-1" left size="24" v-if="item.loadOutReceived" color="orange darken-3">
                          <h4>LI</h4>
                        </v-avatar>
                        {{ parseFloat(item.grossWeight).toFixed(2) }}
                      </v-chip>
                    </div>
                    <div v-else-if="header.value == 'ctoNo'">
                      <!-- <template>
                          <v-chip
                            small
                            class="primaryText--text"
                            v-on="on"
                            style="border: none;margin-right:5px"
                          >
                            {{ item.containerTypeCode }}
                          </v-chip>
                        </template> -->
                      <v-tooltip top>

                        <template v-slot:activator="{ on }">


                          <v-chip v-on="on" small class="primaryText--text" outlined style="border: none">
                            <v-avatar :color="item.booking.movementType == 'EXPORT'
      ? 'deep-orange'
      : 'blue'
      " left>
                              <h4 style="color: white">
                                {{ item.booking.serviceType == 'BREAKBULK' ? item.booking.movementType.charAt(0) + ' ' +
      'B' + ' ' : item.booking.movementType.charAt(0) }}
                              </h4>
                            </v-avatar>

                            <!-- {{ item[header.value] }} -->
                            <span
                              :class="{ 'red--text': item.temporaryProducts.some(product => product.product === 'SmartFresh') }">
                              {{ item[header.value] }}
                            </span>
                          </v-chip>
                          <v-btn @click="
      addToFilter(item.ctoNo, 'ctoNo')
      " icon><v-icon small>filter_alt</v-icon></v-btn>
                          <v-btn icon color="#FFBFFF" v-if="item.temporaryProducts.some(
      (product) => product.product === 'SmartFresh'
    )
      "><v-icon small>smart_screen</v-icon></v-btn>

                        </template>
                        <span>{{
      (item.booking.serviceType == 'BREAKBULK'
        ? 'Breakbulk'
        : '') + ' ' +
      $Format.capitalizeFirstLetter(item.booking.movementType)
    }}
                          Booking</span>
                      </v-tooltip>
                    </div>
                    <div v-else-if="header.value == 'documents'" class="text-left">
                      <v-row justify="center" class="pt-2" align="center">
                        <v-chip v-if="item.ctoDocumentId" class="mt-1" x-small @click="getCTO(item)"
                          color="teal">CTO</v-chip>
                      </v-row>
                    </div>
                    <!-- <div
                      v-else-if="header.value == 'vesselVoyage'"
                      class="text-left"
                    >
                      <v-chip
                        small
                        class="primaryText--text"
                        outlined
                        @click="addToFilter(item.vesselVoyage, 'vesselVoyage')"
                        style="border: none; font-size: 11px"
                        ><span>{{ item.vesselVoyage }}</span>
                      </v-chip>
                    </div> -->
                    <div v-else-if="header.value == 'portOfLoadValue'" class="text-left">
                      <v-tooltip top v-if="item.portOfLoadValue">
                        <template v-slot:activator="{ on }">
                          <v-chip outlined style="border: none; font-size: 11px" v-on="on" small @click="
      addToFilter(
        item.portOfLoadValue,
        'portOfLoadValue'
      )
      ">
                            <v-avatar size="20" left>
                              <v-img contain :src="`https://cdn.loglive.io/flags/4x3/${item.portOfLoadValue
      .substring(0, 2)
      .toLowerCase()}.svg`"></v-img>
                            </v-avatar>
                            {{ item.portOfLoadValue }}
                          </v-chip>
                        </template>
                        <span style="font-size: 12px">{{
      item.portOfLoadCity
    }}</span>
                      </v-tooltip>
                    </div>
                    <!-- <div v-else-if="header.value == 'portOfDischargeValue'" class="text-left">
                      <v-tooltip top v-if="item.sailingSchedule.sailingScheduleLegs[0].portOfDischargeValue">
                        <template v-slot:activator="{ on }">
                          <v-chip outlined style="border: none; font-size: 11px" v-on="on" small @click="
                            addToFilter(
                              item.sailingSchedule.sailingScheduleLegs[0].portOfDischargeValue,
                              'portOfDischargeValue'
                            )
                            ">
                            <v-avatar size="20" left>
                              <v-img contain :src="`https://cdn.loglive.io/flags/4x3/${item.sailingSchedule.sailingScheduleLegs[0].portOfDischargeValue
                                .substring(0, 2)
                                .toLowerCase()}.svg`"></v-img>
                            </v-avatar>
                            {{ item.sailingSchedule.sailingScheduleLegs[0].portOfDischargeValue }}
                          </v-chip>
                        </template>
                        <span style="font-size: 12px">{{
                          item.portOfDischargeCity
                        }}</span>
                      </v-tooltip>

                      <v-tooltip top v-if="item.portOfDischargeValue">
                        <template v-slot:activator="{ on }">
                          <v-chip outlined style="border: none; font-size: 11px" v-on="on" small @click="
                            addToFilter(
                              item.portOfDischargeValue,
                              'portOfDischargeValue'
                            )
                            ">
                            <v-avatar size="20" left>
                              <v-img contain :src="`https://cdn.loglive.io/flags/4x3/${item.portOfDischargeValue
                                .substring(0, 2)
                                .toLowerCase()}.svg`"></v-img>
                            </v-avatar>
                            {{ item.portOfDischargeValue }}
                          </v-chip>
                        </template>
                        <span style="font-size: 12px">{{
                          item.portOfDischargeCity
                        }}</span>
                      </v-tooltip>
                    </div> -->

                    <div v-else-if="header.value == 'finalDestinationValue'" class="text-left">
                      <v-tooltip top v-if="item.finalDestinationValue">
                        <template v-slot:activator="{ on }">
                          <v-chip outlined style="border: none; font-size: 11px" v-on="on" small @click="
      addToFilter(
        item.finalDestinationValue,
        'finalDestinationValue'
      )
      ">
                            <v-avatar size="20" left>
                              <v-img contain :src="`https://cdn.loglive.io/flags/4x3/${item.finalDestinationValue
      .substring(0, 2)
      .toLowerCase()}.svg`"></v-img>
                            </v-avatar>
                            {{ item.finalDestinationValue }}
                          </v-chip>
                        </template>
                        <span style="font-size: 12px">{{
      item.finalDestinationCity
    }}</span>
                      </v-tooltip>
                    </div>

                    <!-- 
                    <div
                      v-else-if="header.value == 'gensetRequired'"
                      class="text-center"
                    >
                      <v-btn
                        small
                        icon
                        :color="item.gensetRequired ? 'success' : 'red'"
                        @click="
                          addToFilter(item.gensetRequired, 'gensetRequired')
                        "
                      >
                        <v-icon v-if="item.gensetRequired"
                          >check_circle_outline</v-icon
                        >
                        <v-icon v-else color="red">cancel</v-icon>
                      </v-btn>
                    </div>
                    <div
                      v-else-if="header.value == 'isConfirmedBooking'"
                      class="text-center"
                    >
                      <v-btn
                        small
                        icon
                        :color="item.isConfirmedBooking ? 'success' : 'red'"
                        @click="
                          addToFilter(
                            item.isConfirmedBooking,
                            'isConfirmedBooking'
                          )
                        "
                      >
                        <v-icon v-if="item.isConfirmedBooking"
                          >check_circle_outline</v-icon
                        >
                        <v-icon v-else color="red">cancel</v-icon>
                      </v-btn>
                    </div> -->

                    <div v-else-if="header.value == 'features'">
                      <v-tooltip top v-if="item.temporaryProducts.some(
      (product) => product.product === 'Genset'
    )
      ">
                        <template v-slot:activator="{ on }">
                          <v-btn icon outlined small class="mx-1" v-on="on" color="#c4c166"><v-icon
                              small>bolt</v-icon></v-btn>
                        </template>
                        <span>Genset Required</span>
                      </v-tooltip>

                      <v-tooltip top v-if="item.steri
      ">
                        <template v-slot:activator="{ on }">
                          <v-btn icon outlined small class="mx-1" v-on="on" color="#90E0eF"><v-icon
                              small>thermostat</v-icon></v-btn>
                        </template>
                        <span>Steri load</span>
                      </v-tooltip>

                      <v-tooltip top v-if="item.temporaryProducts.some(
      (product) => product.product === 'SmartFresh'
    )
      ">
                        <template v-slot:activator="{ on }">
                          <v-btn icon outlined small class="mx-1" v-on="on" color="#FFBFFF"><v-icon
                              small>smart_screen</v-icon></v-btn>
                        </template>
                        <span>Smart Fresh Required</span>
                      </v-tooltip>

                      <v-tooltip top v-if="item.booking.caProduct && !avoBox(item.containerProducts)">
                        <template v-slot:activator="{ on }">
                          <v-btn icon outlined small class="mx-1" v-on="on" color="blue" v-if="item.containerMilestones.some(milestone => {
      if (milestone.type === 'GASSING') {
        const loadingPoint1 = item.containerMilestones.find(m => m.type === 'LOADING_POINT_1');
        const loadingPoint2 = item.containerMilestones.find(m => m.type === 'LOADING_POINT_2');
        return milestone.description === loadingPoint1?.description || milestone.description === loadingPoint2?.description;
      }
      return false;
    })"><v-icon small>ac_unit</v-icon></v-btn>
                          <v-btn icon outlined small class="mx-1" v-on="on" color="orange" v-else><v-icon
                              small>ac_unit</v-icon></v-btn>
                        </template>
                        <span>
                          {{
      getGassingDescription(item)
    }}
                        </span>

                      </v-tooltip>

                      <v-tooltip top v-if="item.temporaryProducts.some(
        (product) => product.product === 'TelemPlus'
      )
      ">
                        <template v-slot:activator="{ on }">
                          <v-btn icon outlined small class="mx-1" v-on="on" color="#18e00d"><v-icon
                              small>router</v-icon></v-btn>
                        </template>
                        <span>
                          Devices Required:
                          <template v-if="item.temporaryProducts.some(
      (product) => product.product === 'TelemPlus'
    )
      ">
                            <ul>
                              <li v-for="(
                                  [variation, count], index
                                ) in Object.entries(
      item.temporaryProducts
        .filter(
          (product) =>
            product.product === 'TelemPlus'
        )
        .reduce((acc, product) => {
          if (!acc[product.variation]) {
            acc[product.variation] = 0;
          }
          acc[product.variation] += product.count;
          return acc;
        }, {})
    )" :key="index">
                                {{ variation }}: {{ count }}
                              </li>
                            </ul>
                          </template>
                        </span>
                      </v-tooltip>
                    </div>





                    <div v-else-if="header.value == 'stackOpen'">
                      <div v-if="item.booking.stackDate">
                        <v-tooltip top v-if="item.containerType">
                          <template v-slot:activator="{ on }">

     
        <v-chip small outlined v-on="on"
                              :color="(item.booking.stackDate.reeferStackStartConfirmed || item.booking.stackDate.dryStackStartConfirmed) ? 'success' : 'red'" @click="
      addToFilter(
        item.booking.stackDate.reeferStackStart,
        'stackOpen'
      )
      ">
                            <div v-if="formatReeferContainer(item.containerType)">
                              {{ convertDate(item.booking.stackDate.reeferStackStart) }}
                            </div>

                            <div v-else>
                              {{ convertDate(item.booking.stackDate.dryStackStart) }}
                            </div>
                            </v-chip>
      
  
                          </template>
                          <span style="font-size: 12px">{{
      (item.booking.stackDate.reeferStackStartConfirmed || item.booking.stackDate.dryStackStartConfirmed ) ? 'Firmed' : 'Not Firmed'
    }}</span>
                        </v-tooltip>
                      </div>
                      <div v-else>
                        <template>
                          <v-chip small class="primaryText--text" v-on="on" style="border: none">
                            N/A
                          </v-chip>
                        </template>
                      </div>
                    </div>







                    
                    <div v-else-if="header.value == 'stackClosed'">
                  <div v-if="item.booking.stackDate">
                        <v-tooltip top v-if="item.containerType">
                          <template v-slot:activator="{ on }">
                            
        <v-chip small outlined v-on="on"
                              :color="(item.booking.stackDate.reeferStackFinishConfirmed || item.booking.stackDate.dryStackFinishConfirmed) ? 'success' : 'red'" @click="
      addToFilter(
        convertDate(item.booking.stackDate.reeferStackFinish),
        'stackClosed'
      )
      ">
      <div v-if="formatReeferContainer(item.containerType)"> 
        {{ convertDate(item.booking.stackDate.reeferStackFinish) }}</div>
      <div v-else>
        {{ convertDate(item.booking.stackDate.dryStackFinish) }}

      </div>
     
                              
                            </v-chip>
                          </template>
                          <span style="font-size: 12px">{{
      (item.booking.stackDate.reeferStackFinishConfirmed || item.booking.stackDate.dryStackFinishConfirmed) ? 'Firmed' : 'Not Firmed'
    }}</span>
                        </v-tooltip>
                      </div>
                      <div v-else>
                        <template>
                          <v-chip small class="primaryText--text" v-on="on" style="border: none">
                            N/A
                          </v-chip>
                        </template>
                      </div>
                    </div>

                    
                    <div v-else-if="header.value == 'eta'">
                      {{ constructEta(item.containerMilestones, item.transportStatus) }}
                    </div>
                    <!-- 
                      <div v-else>
                        <template>
                          <v-chip small class="primaryText--text" v-on="on" style="border: none">
                            N/A
                          </v-chip>
                        </template>
                      </div>
                    </div> -->

                    <div v-else-if="header.value == 'containerProduct'">
                      <v-tooltip top v-if="item.containerProducts">
                        <template v-slot:activator="{ on }">
                          <v-chip small class="primaryText--text" v-on="on" outlined style="border: none">
                            {{ getProductNames(item.containerProducts) }}

                          </v-chip>
                        </template>
                        <span> {{ getProductNames(item.containerProducts) }}
                        </span>
                      </v-tooltip>
                    </div>

                    <div v-else-if="header.value === 'preadviseStatus'" class="text-center">

                      <div v-if="item.preAdviseRequired">
                        <div v-if="item.preadviseStatus">
                          <v-icon class="mx-1" v-if="item.manuallyPreadvised">
                            account_circle
                          </v-icon>
                          <v-tooltip top>
                            <template v-slot:activator="{ on }">
                              <v-btn v-on="on" small icon :color="preadviseStatusColor(item.preadviseStatus)"
                                @click="addToFilter(item.preadviseStatus, 'preadviseStatus')">
                                <v-icon>{{ preadviseIcon(item.preadviseStatus) }}</v-icon>
                              </v-btn>
                            </template>
                            <span> {{ item.preadviseStatus }} </span>
                          </v-tooltip>
                          <!-- <v-btn small icon :color="item.preadvised ? 'success' : 'red'"
                            @click="addToFilter(item.preadviseStatus, 'preadviseStatus')">
                            <v-icon v-if="item.preadvised">check_circle_outline</v-icon>
                            <v-icon v-else color="red">cancel</v-icon>
                          </v-btn> -->
                          <!-- {{ item.navisStatus ? item.navisStatus.substring(0, 3).toUpperCase() : '' }} -->
                        </div>
                        <div v-else>
                          <v-tooltip top>
                            <template v-slot:activator="{ on }">
                              <v-btn small icon v-on="on" @click="addToFilter(null, 'preadviseStatus')">
                                <v-icon color="orange">pending</v-icon>
                              </v-btn>
                            </template>
                            <span>{{ 'Outstanding' }}</span>
                          </v-tooltip>
                        </div>
                        <!-- <div v-if="item.preadviseStatus">
                          <v-tooltip top>
                            <template v-slot:activator="{ on }">
                              <v-btn v-on="on" small icon :color="preadviseStatusColor(item.preadviseStatus)"
                                @click="addToFilter(item.preadviseStatus, 'preadviseStatus')">
                                <v-icon>{{ preadviseIcon(item.preadviseStatus) }}</v-icon>
                              </v-btn>
                            </template>
                            <span> {{ item.preadviseStatus }} </span>
                          </v-tooltip>
                        </div>
                        <div v-else>
                          <v-btn small icon :color="item.preadvised ? 'success' : 'red'"
                            @click="addToFilter(item.preadviseStatus, 'preadviseStatus')">
                            <v-icon color="red">cancel</v-icon>
                          </v-btn>
                        </div> -->

                      </div>
                      <div v-else>N/A</div>
                    </div>

                    <div v-else-if="header.value == 'consigneeName'" class="text-left">
                      <v-tooltip top v-if="item.booking.consignee">
                        <template v-slot:activator="{ on }">
                          <v-chip v-if="item.booking.consignee" small class="primaryText--text" outlined @click="
      addToFilter(item.consigneeName, 'consigneeName')
      " v-on="on" style="border: none">
                            <v-avatar size="24" :color="item.booking.consignee &&
      item.booking.consignee.logo
      ? 'white'
      : 'secondary'
      " left>
                              <v-img style="" v-if="item.booking.consignee.logo" :src="item.booking.consignee.logo"
                                contain></v-img>
                              <h3 v-else style="color: white">
                                {{ item.consigneeName.charAt(0) }}
                              </h3>
                            </v-avatar>
                            {{ item.consigneeName }}
                          </v-chip>
                        </template>
                        <span>{{ item.booking.consignee.name }}</span>
                      </v-tooltip>
                    </div>
                    <div v-else-if="header.value == 'customerName'">
                      <v-tooltip top v-if="item.booking.customer">
                        <template v-slot:activator="{ on }">
                          <v-chip v-if="item.booking.customer" small class="primaryText--text" v-on="on" outlined
                            @click="
      addToFilter(item.customerName, 'shipperName')
      " style="border: none">
                            <v-avatar size="20" :color="item.booking.customer &&
      item.booking.customer.logo
      ? 'white'
      : 'secondary'
      " left>
                              <v-img v-if="item.booking.customer.logo" :src="item.booking.customer.logo"
                                contain></v-img>
                              <h3 v-else style="color: white">
                                {{ item.customerName.charAt(0) }}
                              </h3>
                            </v-avatar>
                            {{ item.customerName }}
                          </v-chip>
                        </template>
                        <span>{{ item.booking.customer.name }}</span>
                      </v-tooltip>
                    </div>
                    <div v-else-if="header.value == 'transporterName'" :class="'text-center'">
                      <v-tooltip top v-if="item.transporter">
                        <template v-slot:activator="{ on }">
                          <v-chip small class="primaryText--text" v-on="on" outlined style="border: none">
                            <v-avatar size="20" :color="item.transporter && item.transporter.logo
      ? 'white'
      : 'secondary'
      " left>
                              <v-img v-if="item.transporter.logo" :src="item.transporter.logo" contain></v-img>
                              <h3 v-else style="color: white">
                                {{ item.transporterName.charAt(0) }}
                              </h3>
                            </v-avatar>
                            {{ item.transporterName }}
                            <v-btn @click="
      addToFilter(
        item.transporterName,
        'transporterName'
      )
      " icon><v-icon small>filter_alt</v-icon></v-btn>
                            <v-btn right @click="removeContainerTransporter(item)" icon color="red" small><v-icon
                                small>cancel</v-icon></v-btn>
                          </v-chip>
                        </template>
                        <span>{{ item.transporter.name }}</span>
                      </v-tooltip>
                      <div v-else>
                        <v-btn color="grey" small icon v-if="!(item.isCarrierHaulage || item.privateLoad)"
                          @click="assignContainers([item])"><v-icon small>add_circle_outline</v-icon></v-btn>
                      </div>
                    </div>

                    <div v-else-if="header.value == 'shipperName'">
                      <v-tooltip top v-if="item.booking.shipper">
                        <template v-slot:activator="{ on }">
                          <v-chip v-if="item.booking.shipper" small class="primaryText--text" v-on="on" outlined @click="
      addToFilter(item.shipperName, 'shipperName')
      " style="border: none">
                            <v-avatar size="20" :color="item.booking.shipper &&
      item.booking.shipper.logo
      ? 'white'
      : 'secondary'
      " left>
                              <v-img v-if="item.booking.shipper.logo" :src="item.booking.shipper.logo" contain></v-img>
                              <h3 v-else style="color: white">
                                {{ item.shipperName.charAt(0) }}
                              </h3>
                            </v-avatar>
                            {{ item.shipperName }}
                          </v-chip>
                        </template>
                        <span>{{ item.booking.shipper.name }}</span>
                      </v-tooltip>
                    </div>
                    <div v-else-if="header.value == 'shippingLineName'" class="text-left">
                      <v-chip v-if="item.booking.shippingLine" small class="primaryText--text" outlined @click="
      addToFilter(item.shippingLineName, 'shippingLineName')
      " style="border: none">
                        <v-avatar size="24" :color="item.booking.shippingLine &&
      item.booking.shippingLine.logo
      ? 'white'
      : 'secondary'
      " left>
                          <v-img v-if="item.booking.shippingLine.logo" :src="item.booking.shippingLine.logo"
                            contain></v-img>
                          <h3 v-else style="color: white">
                            {{ item.shippingLineName.charAt(0) }}
                          </h3>
                        </v-avatar>
                        {{ item.shippingLineName }}
                      </v-chip>
                    </div>
                    <div v-else-if="header.value == 'GATE_OUT_EMPTY_DESCRIPTION'">
                      <v-tooltip top v-if="item.GATE_OUT_EMPTY_DESCRIPTION">
                        <template v-slot:activator="{ on }">
                          <v-chip small class="primaryText--text" outlined v-on="on" @click="
      addToFilter(
        item.EMPTY_PICKUP_DESCRIPTION,
        'EMPTY_PICKUP_DESCRIPTION'
      )
      " style="border: none">
                            {{ item.EMPTY_PICKUP_DESCRIPTION }}
                          </v-chip>
                        </template>
                        <span style="font-size: 12px">{{
      item.EMPTY_PICKUP_DESCRIPTION
    }}</span>
                      </v-tooltip>
                    </div>
                    <div v-else-if="header.value == 'FULL_PICKUP_DESCRIPTION'">
                      <v-tooltip top>
                        <template v-slot:activator="{ on }">
                          <v-chip small class="primaryText--text" outlined v-on="on" @click="
      addToFilter(
        item.FULL_PICKUP_DESCRIPTION,
        'FULL_PICKUP_DESCRIPTION'
      )
      " style="border: none">
                            {{ item.FULL_PICKUP_DESCRIPTION}}
                          </v-chip>
                        </template>
                        <span style="font-size: 12px">{{
      item.FULL_PICKUP_DESCRIPTION
    }}</span>
                      </v-tooltip>
                    </div>
                    <div v-else-if="header.value == 'EMPTY_DROP_OFF_DESCRIPTION'">
                      <v-tooltip top>
                        <template v-slot:activator="{ on }">
                          <v-chip small class="primaryText--text" outlined v-on="on" @click="
      addToFilter(
        item.EMPTY_DROP_OFF_DESCRIPTION,
        'EMPTY_DROP_OFF_DESCRIPTION'
      )
      " style="border: none">
                            {{ item.EMPTY_DROP_OFF_DESCRIPTION}}
                          </v-chip>
                        </template>
                        <span style="font-size: 12px">{{
      item.EMPTY_DROP_OFF_DESCRIPTION
    }}</span>
                      </v-tooltip>
                    </div>
                    <div v-else-if="header.value == 'EMPTY_PICKUP_DATE'">
                      <v-tooltip top v-if="item.EMPTY_PICKUP_DATE">
                        <template v-slot:activator="{ on }">
                          <v-chip small class="primaryText--text" outlined v-on="on" @click="
      addToFilter(
        item.EMPTY_PICKUP_DATE,
        'EMPTY_PICKUP_DATE'
      )
      " style="border: none">
                            {{
      formatDate(
        item.EMPTY_PICKUP_DATE,
      )
    }}
                          </v-chip>
                        </template>
                        <span style="font-size: 12px">{{
        formatDate(
          item.EMPTY_PICKUP_DATE,
        )
      }}</span>
                      </v-tooltip>
                    </div>
                    <div v-else-if="header.value == 'EMPTY_PICKUP_TIME' && item.booking.movementType == 'EXPORT'">
                      <v-tooltip top>
                        <template v-slot:activator="{ on }">
                          <v-chip small class="primaryText--text" outlined v-on="on" @click="
      addToFilter(
       item.EMPTY_PICKUP_DATE,
      'EMPTY_PICKUP_DATE'
      )
      " style="border: none">
                            {{
     item.EMPTY_PICKUP.time
    }}
                          </v-chip>
                        </template>
                        <span style="font-size: 12px">{{
        
        item.EMPTY_PICKUP.time
        
      }}</span>
                      </v-tooltip>
                    </div>
                    <div v-else-if="header.value == 'LOADING_POINT_1_DESCRIPTION' && item.booking.movementType == 'EXPORT'">
                      <v-tooltip top>
                        <template v-slot:activator="{ on }">
                          <v-chip small class="primaryText--text" outlined v-on="on" @click="
      addToFilter(
        item.LOADING_POINT_1_DESCRIPTION,
        'LOADING_POINT_1_DESCRIPTION'
      )
      " style="border: none">
                            {{ item.LOADING_POINT_1_DESCRIPTION}}
                          </v-chip>
                        </template>
                        <span style="font-size: 12px">{{
      item.LOADING_POINT_1_DESCRIPTION
    }}</span>
                      </v-tooltip>
                    </div>


                    <div v-else-if="header.value == 'DELIVERY_POINT_1_DESCRIPTION' && item.booking.movementType == 'IMPORT'">
                      <v-tooltip top>
                        <template v-slot:activator="{ on }">
                          <v-chip small class="primaryText--text" outlined v-on="on" @click="
      addToFilter(
        item.DELIVERY_POINT_1_DESCRIPTION,
        'DELIVERY_POINT_1_DESCRIPTION'
      )
      " style="border: none">
                            {{ item.DELIVERY_POINT_1_DESCRIPTION}}
                          </v-chip>
                        </template>
                        <span style="font-size: 12px">{{
      item.DELIVERY_POINT_1_DESCRIPTION
    }}</span>
                      </v-tooltip>
                    </div>
                    <div v-else-if="header.value == 'EMPTY_DROP_OFF_DATE'">
                      <v-tooltip top>
                        <template v-slot:activator="{ on }">
                          <v-chip small class="primaryText--text" outlined v-on="on" @click="
      addToFilter(
        item.EMPTY_DROP_OFF_DATE,
        'EMPTY_DROP_OFF_DATE'
      )
      " style="border: none">
                            {{
      formatDate(
        item.EMPTY_DROP_OFF_DATE,

      )
    }}
                          </v-chip>
                        </template>
                        <span style="font-size: 12px">{{
        formatDate(
          item.EMPTY_DROP_OFF_DATE
        )
      }}</span>
                      </v-tooltip>
                    </div>

                    <div v-else-if="header.value == 'EMPTY_DROP_OFF_TIME' && item.booking.movementType == 'IMPORT'">
                      <v-tooltip top>
                        <template v-slot:activator="{ on }">
                          <v-chip small class="primaryText--text" outlined v-on="on" @click="
      addToFilter(
        item.EMPTY_DROP_OFF_DATE,
        'EMPTY_DROP_OFF_DATE'
      )
      " style="border: none">
                            {{item.EMPTY_DROP_OFF.time }}
                          </v-chip>
                        </template>
                        <span style="font-size: 12px">{{item.EMPTY_DROP_OFF_DATE }}</span>
                      </v-tooltip>
                    </div>
                    <div v-else-if="header.value == 'FULL_PICKUP_DATE'">
                      <v-tooltip top>
                        <template v-slot:activator="{ on }">
                          <v-chip small class="primaryText--text" outlined v-on="on" @click="
      addToFilter(
        item.FULL_PICKUP_DATE,
        'FULL_PICKUP_DATE'
      )
      " style="border: none">
                            {{
      formatDate(
        item.FULL_PICKUP_DATE,

      )
    }}
                          </v-chip>
                        </template>
                        <span style="font-size: 12px">{{
        formatDate(
          item.FULL_PICKUP_DATE
        )
      }}</span>
                      </v-tooltip>
                    </div>
                    <div v-else-if="header.value == 'LOADING_POINT_1_DATE' && item.booking.movementType == 'EXPORT'">
                      <v-tooltip top>
                        <template v-slot:activator="{ on }">
                          <v-chip small class="primaryText--text" outlined v-on="on" @click="
      addToFilter(
        item.LOADING_POINT_1_DATE,
        'LOADING_POINT_1_DATE'
      )
      " style="border: none">
                            {{
      formatDate(
        item.LOADING_POINT_1_DATE,
        item.LOADING_POINT_1

      )
    }}
                          </v-chip>
                        </template>
                        <span style="font-size: 12px">{{
        formatDate(
          item.LOADING_POINT_1_DATE
        )
      }}</span>
                      </v-tooltip>
                    </div>
                    <div v-else-if="header.value == 'LOADING_POINT_1_TIME' && item.booking.movementType == 'EXPORT'">
                      <v-tooltip top>
                        <template v-slot:activator="{ on }">
                          <v-chip small class="primaryText--text" outlined v-on="on" @click="
      addToFilter(
       item.LOADING_POINT_1_DATE,
      'LOADING_POINT_1_DATE'
      )
      " style="border: none">
                            {{
     item.LOADING_POINT_1.time
    }}
                          </v-chip>
                        </template>
                        <span style="font-size: 12px">{{
        
        item.LOADING_POINT_1.time
        
      }}</span>
                      </v-tooltip>
                    </div>
                    <div v-else-if="header.value == 'DELIVERY_POINT_1_DATE' && item.booking.movementType == 'IMPORT'">
                      <v-tooltip top>
                        <template v-slot:activator="{ on }">
                          <v-chip small class="primaryText--text" outlined v-on="on" @click="
      addToFilter(
        item.DELIVERY_POINT_1_DATE,
        'DELIVERY_POINT_1_DATE'
      )
      " style="border: none">
                            {{
      formatDate(
        item.DELIVERY_POINT_1_DATE,
      )
    }}
                          </v-chip>
                        </template>
                        <span style="font-size: 12px">{{
        formatDate(
          item.DELIVERY_POINT_1_DATE
        )
      }}</span>
                      </v-tooltip>
                    </div>
                    <div v-else-if="header.value == 'DELIVERY_POINT_1_TIME' && item.booking.movementType == 'IMPORT'">
                      <v-tooltip top>
                        <template v-slot:activator="{ on }">
                          <v-chip small class="primaryText--text" outlined v-on="on" @click="
      addToFilter(
        item.DELIVERY_POINT_1_DATE,
        'DELIVERY_POINT_1_DATE'
      )
      " style="border: none">
                            {{item.DELIVERY_POINT_1.time }}
                          </v-chip>
                        </template>
                        <span style="font-size: 12px">{{item.DELIVERY_POINT_1_DATE }}</span>
                      </v-tooltip>
                    </div>
                    <div v-else-if="header.value == 'DELIVERY_POINT_2_TIME' && item.booking.movementType == 'IMPORT'">
                      <v-tooltip top>
                        <template v-slot:activator="{ on }">
                          <v-chip small class="primaryText--text" outlined v-on="on" @click="
      addToFilter(
        item.DELIVERY_POINT_2_DATE,
        'DELIVERY_POINT_2_DATE'
      )
      " style="border: none">
                            {{item.DELIVERY_POINT_2.time }}
                          </v-chip>
                        </template>
                        <span style="font-size: 12px">{{item.DELIVERY_POINT_2_DATE }}</span>
                      </v-tooltip>
                    </div>
                    <div v-else-if="header.value == 'DELIVERY_POINT_3_TIME' && item.booking.movementType == 'IMPORT'">
                      <v-tooltip top>
                        <template v-slot:activator="{ on }">
                          <v-chip small class="primaryText--text" outlined v-on="on" @click="
      addToFilter(
        item.DELIVERY_POINT_3_DATE,
        'DELIVERY_POINT_3_DATE'
      )
      " style="border: none">
                            {{item.DELIVERY_POINT_3.time }}
                          </v-chip>
                        </template>
                        <span style="font-size: 12px">{{item.DELIVERY_POINT_3_DATE }}</span>
                      </v-tooltip>
                    </div>
                    <div v-else-if="header.value == 'FULL_PICKUP_TIME' && item.booking.movementType == 'IMPORT'">
                      <v-tooltip top>
                        <template v-slot:activator="{ on }">
                          <v-chip small class="primaryText--text" outlined v-on="on" @click="
      addToFilter(
        item.FULL_PICKUP_DATE,
        'FULL_PICKUP_DATE'
      )
      " style="border: none">
                            {{item.FULL_PICKUP.time }}
                          </v-chip>
                        </template>
                        <span style="font-size: 12px">{{item.FULL_PICKUP_DATE }}</span>
                      </v-tooltip>
                    </div>
                    <div v-else-if="header.value == 'DELIVERY_POINT_2_DATE' && item.booking.movementType == 'IMPORT'">
                      <v-tooltip top>
                        <template v-slot:activator="{ on }">
                          <v-chip small class="primaryText--text" outlined v-on="on" @click="
      addToFilter(
        item.DELIVERY_POINT_2_DATE,
        'DELIVERY_POINT_2_DATE'
      )
      " style="border: none">
                            {{
      formatDate(
        item.DELIVERY_POINT_2_DATE,

      )
    }}
                          </v-chip>
                        </template>
                        <span style="font-size: 12px">{{
        formatDate(
          item.DELIVERY_POINT_2_DATE
        )
      }}</span>
                      </v-tooltip>
                    </div>


                    <div v-else-if="header.value == 'LOADING_POINT_2_DATE' && item.booking.movementType == 'EXPORT'">
                      <v-tooltip top>
                        <template v-slot:activator="{ on }">
                          <v-chip small class="primaryText--text" outlined v-on="on" @click="
      addToFilter(
        item.LOADING_POINT_2_DATE,
        'LOADING_POINT_2_DATE'
      )
      " style="border: none">
                            {{
      formatDate(
        item.LOADING_POINT_2_DATE,

      )
    }}
                          </v-chip>
                        </template>
                        <span style="font-size: 12px">{{
        formatDate(
        item.LOADING_POINT_2_DATE
        )
        
      }}</span>
                      </v-tooltip>
                    </div>

                    <div v-else-if="header.value == 'LOADING_POINT_2_TIME' && item.booking.movementType == 'EXPORT'">
                      <v-tooltip top>
                        <template v-slot:activator="{ on }">
                          <v-chip small class="primaryText--text" outlined v-on="on" @click="
      addToFilter(
       item.LOADING_POINT_2_DATE,
      'LOADING_POINT_2_DATE'
      )
      " style="border: none">
                            {{
     item.LOADING_POINT_2.time
    }}
                          </v-chip>
                        </template>
                        <span style="font-size: 12px">{{
        
        item.LOADING_POINT_2.time
        
      }}</span>
                      </v-tooltip>
                    </div>
                    <div v-else-if="header.value == 'DELIVERY_POINT_3_DATE' && item.booking.movementType == 'IMPORT'">
                      <v-tooltip top>
                        <template v-slot:activator="{ on }">
                          <v-chip small class="primaryText--text" outlined v-on="on" @click="
      addToFilter(
        item.DELIVERY_POINT_3_DATE,
        'DELIVERY_POINT_3_DATE'
      )
      " style="border: none">
                            {{
      formatDate(
        item.DELIVERY_POINT_3_DATE,

      )
    }}
                          </v-chip>
                        </template>
                        <span style="font-size: 12px">{{
        formatDate(
          item.DELIVERY_POINT_3_DATE
        )
      }}</span>
                      </v-tooltip>
                    </div>


                    <div v-else-if="header.value == 'LOADING_POINT_3_DATE' && item.booking.movementType == 'EXPORT'">
                      <v-tooltip top>
                        <template v-slot:activator="{ on }">
                          <v-chip small class="primaryText--text" outlined v-on="on" @click="
      addToFilter(
        item.LOADING_POINT_3_DATE,
        'LOADING_POINT_3_DATE'
      )
      " style="border: none">
                            {{
      formatDate(
        item.LOADING_POINT_3_DATE,

      )
    }}
                          </v-chip>
                        </template>
                        <span style="font-size: 12px">{{
        formatDate(
        item.LOADING_POINT_3_DATE
        )
        
      }}</span>
                      </v-tooltip>
                    </div>
                    <div v-else-if="header.value == 'LOADING_POINT_2_DESCRIPTION' && item.booking.movementType == 'EXPORT'">
                      <v-tooltip top>
                        <template v-slot:activator="{ on }">
                          <v-chip small class="primaryText--text" outlined v-on="on" @click="
      addToFilter(
        item.LOADING_POINT_2_DESCRIPTION,
        'LOADING_POINT_2_DESCRIPTION'
      )
      " style="border: none">
                            {{ item.LOADING_POINT_2_DESCRIPTION}}
                          </v-chip>
                        </template>
                        <span style="font-size: 12px">{{
      item.LOADING_POINT_2_DESCRIPTION
    }}</span>
                      </v-tooltip>
                    </div>

                    <div v-else-if="header.value == 'DELIVERY_POINT_2_DESCRIPTION' && item.booking.movementType == 'IMPORT'">
                      <v-tooltip top>
                        <template v-slot:activator="{ on }">
                          <v-chip small class="primaryText--text" outlined v-on="on" @click="
      addToFilter(
        item.DELIVERY_POINT_2_DESCRIPTION,
        'DELIVERY_POINT_2_DESCRIPTION'
      )
      " style="border: none">
                            {{item.DELIVERY_POINT_2_DESCRIPTION}}
                          </v-chip>
                        </template>
                        <span style="font-size: 12px">{{
     item.DELIVERY_POINT_2_DESCRIPTION
    }}</span>
                      </v-tooltip>
                    </div>

                    <div v-else-if="header.value == 'LOADING_POINT_3_DESCRIPTION' && item.booking.movementType == 'EXPORT'">
                      <v-tooltip top>
                        <template v-slot:activator="{ on }">
                          <v-chip small class="primaryText--text" outlined v-on="on" @click="
      addToFilter(
        item.LOADING_POINT_3_DESCRIPTION,
        'LOADING_POINT_3_DESCRIPTION'
      )
      " style="border: none">
                            {{ item.LOADING_POINT_3_DESCRIPTION}}
                          </v-chip>
                        </template>
                        <span style="font-size: 12px">{{
      item.LOADING_POINT_3_DESCRIPTION
    }}</span>
                      </v-tooltip>
                    </div>

                    <div v-else-if="header.value == 'DELIVERY_POINT_3_DESCRIPTION' && item.booking.movementType == 'IMPORT'">
                      <v-tooltip top>
                        <template v-slot:activator="{ on }">
                          <v-chip small class="primaryText--text" outlined v-on="on" @click="
      addToFilter(
        item.DELIVERY_POINT_3_DESCRIPTION,
        'DELIVERY_POINT_3_DESCRIPTION'
      )
      " style="border: none">
                            {{item.DELIVERY_POINT_3_DESCRIPTION}}
                          </v-chip>
                        </template>
                        <span style="font-size: 12px">{{
     item.DELIVERY_POINT_3_DESCRIPTION
    }}</span>
                      </v-tooltip>
                    </div>


                    <div v-else-if="header.value == 'WEIGH_BRIDGE_DESCRIPTION'">
                      <div v-if="item.WEIGH_BRIDGE_DESCRIPTION">
                        <v-chip small rounded outlined style="border: none">
                          <v-tooltip top>
                            <template v-slot:activator="{ on }">
                              <v-chip small class="primaryText--text" outlined v-on="on" @click="
      addToFilter(
        item.WEIGH_BRIDGE_DESCRIPTION,
        'WEIGH_BRIDGE_DESCRIPTION'
      )
      " style="border: none">
                                {{ item.WEIGH_BRIDGE_DESCRIPTION }}
                              </v-chip>
                            </template>
                            <span style="font-size: 12px">{{
      item.WEIGH_BRIDGE_DESCRIPTION
    }}</span>
                          </v-tooltip>
                          <v-btn @click="addMilestone(item, 'WEIGH_BRIDGE')" icon color="grey"><v-icon
                              small>edit</v-icon></v-btn>
                        </v-chip>
                      </div>
                      <v-btn color="grey" small icon v-else @click="addMilestone(item, 'WEIGH_BRIDGE')"><v-icon
                          small>add_circle_outline</v-icon></v-btn>
                    </div>
                    <div v-else-if="header.value == 'dummyWeight'">

                      <v-btn icon small @click="addToFilter(item.dummyWeight, 'dummyWeight')">
                        <v-icon small v-if="item.dummyWeight" color="green">check</v-icon>
                        <v-icon small v-else color="red">clear</v-icon>
                      </v-btn>
                    </div>
                    <div v-else-if="header.value == 'WEIGH_BRIDGE_DATE'">
                      <v-tooltip top v-if="item.WEIGH_BRIDGE_DATE">
                        <template v-slot:activator="{ on }">
                          <v-chip small class="primaryText--text" outlined v-on="on" @click="
      addToFilter(
        item.WEIGH_BRIDGE_DATE,
        'WEIGH_BRIDGE_DATE'
      )
      " style="border: none">
                            {{
      formatDate(
        item.WEIGH_BRIDGE_DATE,
      )
    }}
                          </v-chip>
                        </template>
                        <span style="font-size: 12px">{{
        formatDate(
          item.WEIGH_BRIDGE_DATE,
        )
      }}</span>
                      </v-tooltip>
                    </div>
                    <div v-else-if="header.value == 'WEIGH_BRIDGE_TIME' && item.booking.movementType == 'EXPORT'">
                      <v-tooltip top>
                        <template v-slot:activator="{ on }">
                          <v-chip small class="primaryText--text" outlined v-on="on" @click="
      addToFilter(
       item.WEIGH_BRIDGE_DATE,
      'WEIGH_BRIDGE_DATE'
      )
      " style="border: none">
                            {{
     item.WEIGH_BRIDGE.time
    }}
                          </v-chip>
                        </template>
                        <span style="font-size: 12px">{{
        
    item.WEIGH_BRIDGE.time
        
      }}</span>
                      </v-tooltip>
                    </div>
                    <div v-else-if="header.value == 'PLUG_IN_DESCRIPTION'">
                      <div v-if="item.PLUG_IN_DESCRIPTION">
                        <v-chip small rounded outlined style="border: none">
                          <v-tooltip top>
                            <template v-slot:activator="{ on }">
                              <v-chip small class="primaryText--text" outlined v-on="on"
                                @click="addMilestone(item, 'PLUG_IN')" style="border: none">
                                {{ item.PLUG_IN_DESCRIPTION }}
                              </v-chip>
                            </template>
                            <span style="font-size: 12px">{{
      item.PLUG_IN_DESCRIPTION
    }}</span>
                          </v-tooltip>
                          <v-btn @click="addMilestone(item, 'PLUG_IN')" icon color="grey"><v-icon
                              small>edit</v-icon></v-btn>
                        </v-chip>
                      </div>
                      <v-btn color="grey" small icon v-else @click="addMilestone(item, 'PLUG_IN')"><v-icon
                          small>add_circle_outline</v-icon></v-btn>
                    </div>
                    <div v-else-if="header.value == 'PLUG_IN_DATE'">
                      <!-- <v-tooltip top v-if="item.PLUG_IN_DATE">
                        <template v-slot:activator="{ on }">
                          <v-chip
                            small
                            class="primaryText--text"
                            outlined
                            v-on="on"
                            @click="
                              addToFilter(item.PLUG_IN_DATE, 'PLUG_IN_DATE')
                            "
                            style="border: none"
                          >
                            {{
                              formatDateTime(item.PLUG_IN_DATE, item.PLUG_IN)
                            }}
                          </v-chip>
                        </template>
                        <span style="font-size: 12px">{{
                          formatDateTime(item.PLUG_IN_DATE, item.PLUG_IN)
                        }}</span>
                      </v-tooltip> -->

                      <v-tooltip top v-if="item.PLUG_IN_DATE">
                        <template v-slot:activator="{ on }">
                          <v-chip small v-on="on" outlined style="border: none">
                            <v-avatar style="color:white">
                            </v-avatar>
                            {{
      formatDateTime(item.PLUG_IN_DATE, item.PLUG_IN)
    }}
                            <v-btn @click="
    addToFilter(item.PLUG_IN_DATE, 'PLUG_IN_DATE')
      " icon><v-icon small>filter_alt</v-icon></v-btn>
                            <v-btn @click="updateMilestoneDate(item.PLUG_IN)" icon color="red" small><v-icon
                                small>cancel</v-icon></v-btn>
                          </v-chip>
                        </template>
                        <span>{{ item.PLUG_IN_DESCRIPTION }}</span>
                      </v-tooltip>
                      <div v-else>
                        <v-btn color="grey" small icon @click="addMilestone(item, 'PLUG_IN')"><v-icon
                            small>add_circle_outline</v-icon></v-btn>
                      </div>

                    </div>

                    <div v-else-if="header.value == 'PLUG_OUT_DESCRIPTION'">
                      <v-tooltip top v-if="item.PLUG_OUT_DESCRIPTION">
                        <template v-slot:activator="{ on }">
                          <v-chip small class="primaryText--text" outlined v-on="on"
                            @click="addMilestone(item, 'PLUG_OUT')" style="border: none">
                            {{ item.PLUG_OUT_DESCRIPTION }}
                          </v-chip>
                        </template>
                        <span style="font-size: 12px">{{
      item.PLUG_OUT_DESCRIPTION
    }}</span>
                      </v-tooltip>
                      <v-btn color="grey" small icon v-else @click="addMilestone(item, 'PLUG_OUT')"><v-icon
                          small>add_circle_outline</v-icon></v-btn>
                    </div>
                    <div v-else-if="header.value == 'PLUG_OUT_DATE'">
                      <div v-if="item.PLUG_OUT_DATE">
                        <v-tooltip top>
                          <template v-slot:activator="{ on }">
                            <v-chip small class="primaryText--text" outlined v-on="on"
                              @click="addMilestone(item, 'PLUG_OUT')" style="border: none">
                              {{
      formatDateTime(
        item.PLUG_OUT_DATE,
        item.PLUG_OUT
      )
    }}
                            </v-chip>
                          </template>
                          <span style="font-size: 12px">{{
        formatDateTime(item.PLUG_OUT_DATE, item.PLUG_OUT)
      }}</span>
                        </v-tooltip>
                      </div>
                      <v-btn color="grey" small icon v-else @click="addMilestone(item, 'PLUG_OUT')"><v-icon
                          small>add_circle_outline</v-icon></v-btn>
                    </div>
                    <div v-else-if="header.value == 'GATE_IN_FULL_DESCRIPTION'">
                      <v-tooltip top v-if="item.GATE_IN_FULL_DESCRIPTION">
                        <template v-slot:activator="{ on }">
                          <v-chip small class="primaryText--text" outlined v-on="on" @click="
      addToFilter(
        item.GATE_IN_FULL_DESCRIPTION,
        'GATE_IN_FULL_DESCRIPTION'
      )
      " style="border: none">
                            {{ item.GATE_IN_FULL_DESCRIPTION }}
                          </v-chip>
                        </template>
                        <span style="font-size: 12px">{{
      item.GATE_IN_FULL_DESCRIPTION
    }}</span>
                      </v-tooltip>
                    </div>
                    <div v-else-if="header.value == 'FULL_DROP_OFF_DATE'">
                      <v-tooltip top v-if="item.FULL_DROP_OFF_DATE">
                        <template v-slot:activator="{ on }">
                          <v-chip small class="primaryText--text" outlined v-on="on" @click="
      addToFilter(
        item.FULL_DROP_OFF_DATE,
        'FULL_DROP_OFF_DATE'
      )
      " style="border: none">
                            {{
      formatDateTime(
        item.GATE_IN_FULL_DATE,
        item.FULL_DROP_OFF
      )
    }}
                          </v-chip>
                        </template>
                        <span style="font-size: 12px">{{
        formatDateTime(
          item.GATE_IN_FULL_DATE,
          item.FULL_DROP_OFF
        )
      }}</span>
                      </v-tooltip>
                    </div>

                    <div v-else-if="header.value == 'containerNo'">
                      <v-chip v-if="item[header.value]" small class="primaryText--text" outlined
                        @click="addToFilter(item[header.value], header.value)" style="border: none">
                        {{ item[header.value] }}
                      </v-chip>
                      <v-btn color="grey" small icon v-else @click="editField('text', item, header.value)"><v-icon
                          small>add_circle_outline</v-icon></v-btn>
                    </div>
                    <div v-else-if="header.value == 'sealNo'">
                      <!-- <template -->
                      <v-chip class="mx-1" @click="addToFilter(item[header.value], header.value)" small
                        v-if="item.booking.caProduct && !avoBox(item.containerProducts)" outlined :color="item.containerMilestones.some(milestone => {
      if (milestone.type === 'GASSING') {
        const loadingPoint1 = item.containerMilestones.find(m => m.type === 'LOADING_POINT_1');
        const loadingPoint2 = item.containerMilestones.find(m => m.type === 'LOADING_POINT_2');
        return milestone.description === loadingPoint1?.description || milestone.description === loadingPoint2?.description;
      }
      return false;
    }) ? 'blue' : 'orange'">
                        {{ item[header.value] }}
                      </v-chip>
                      <v-chip small class="primaryText--text" outlined v-else-if="item[header.value]"
                        @click="addToFilter(item[header.value], header.value)" style="border: none">
                        {{ item[header.value] }}
                      </v-chip>
                      <v-btn color="grey" small icon v-else @click="editField('text', item, header.value)"><v-icon
                          small>add_circle_outline</v-icon></v-btn>
                      <!-- </template> -->
                    </div>
                    <div v-else-if="header.value == 'tempSerialNo'">
                      <v-chip v-if="item[header.value]" small class="primaryText--text" outlined
                        @click="editField('text', item, header.value)" style="border: none">
                        {{ item[header.value] }}
                        <v-icon small color="grey" class="ml-2">edit</v-icon>
                      </v-chip>
                      <v-btn color="grey" small icon v-else @click="editField('text', item, header.value)"><v-icon
                          small>add_circle_outline</v-icon></v-btn>
                    </div>
                    <div v-else-if="header.value == 'sensorDeviceType'">
                      <v-chip v-if="item[header.value] &&
      item[header.value].split(' ').length > 0
      " small class="primaryText--text" outlined @click="addToFilter(item[header.value], header.value)"
                        style="border: none">
                        {{ item[header.value].split(" ")[0] }}
                      </v-chip>
                    </div>

                    <div v-else-if="header.value == 'tareWeight'">
                      <v-chip v-if="item[header.value]" small class="primaryText--text" outlined style="border: none"
                        @click="openContainer(item)">
                        {{ parseFloat(item[header.value]).toFixed(2) }} KG
                      </v-chip>
                      <v-btn v-else small icon @click="openContainer(item)"><v-icon color="grey"
                          small>add_circle_outline</v-icon></v-btn>
                    </div>

                    <div v-else-if="header.value == 'vgmWeight'">
                      <v-chip v-if="item[header.value]" small class="primaryText--text" outlined style="border: none"
                        @click="openContainer(item)">
                        {{ parseFloat(item[header.value]).toFixed(2) }} KG
                      </v-chip>
                      <v-btn v-else small icon @click="openContainer(item)"><v-icon color="grey"
                          small>add_circle_outline</v-icon></v-btn>
                    </div>

                    <div v-else-if="header.value == 'transportStatus'">
                      <v-menu offset-y v-if="item[header.value]">
                        <template v-slot:activator="{ on }">
                          <v-chip small class="primaryText--text" :color="getStatusColor(item.transportStatus)"
                            v-on="on" style="border: none">
                            {{ item[header.value] }}
                            <v-icon small right>expand_more</v-icon>
                          </v-chip>
                        </template>
                        <v-list style="overflow: hidden" dense>
                          <v-list-item v-for="(
                              status, index
                            ) in getFilteredTransportStatuses(item)" :key="index" style="height: 35px"
                            @click="setStatus(item, status)">
                            <v-list-item-action>
                              <v-icon :color="status.color">fiber_manual_record</v-icon>
                            </v-list-item-action>
                            <v-list-item-content>
                              <v-list-item-title>
                                {{ status.text }}
                              </v-list-item-title>
                            </v-list-item-content>
                          </v-list-item>
                        </v-list>
                      </v-menu>
                    </div>
                    <div v-else-if="header.value == 'etd'">
                      <v-chip v-if="item[header.value]" small class="primaryText--text" outlined
                        @click="addToFilter(item[header.value], header.value)" style="border: none">
                        {{ formatDate(item[header.value]) }}
                      </v-chip>
                    </div>

                    <div v-else>
                      <v-chip v-if="item[header.value]" small class="primaryText--text" outlined
                        @click="addToFilter(item[header.value], header.value)" style="border: none">
                        {{ item[header.value] }}
                      </v-chip>
                    </div>
                  </td>
                </div>

                <v-divider></v-divider>
              </template>
            </v-virtual-scroll>
          </div>
        </div>
        <div class="container" v-if="!loading && displayData.length == 0">
          <h3 style="color: grey">No Results Found</h3>
        </div>
        <div class="container" v-else-if="loading">
          <valhalla-loading height="500px" />
        </div>
      </v-card-text>
    </v-card>
    <v-dialog v-model="openPOHDialog" width="90vw" :fullscreen="$vuetify.breakpoint.mobile">
      <v-card>
        <v-toolbar flat>
          <v-toolbar-title>Loads without any Port of Holding (3 days)</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon @click="openPOHDialog = false" color="red">
            <v-icon>close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text>
          <!-- {{ noPOHData }}
           -->
          <v-data-table :items="noPOHData" :headers="noPOHHeaders" :items-per-page="10" class="elevation-1">
            <template v-slot:item.loadingDate="{ item }">
              {{ formatDate(item.loadingDate) }}
            </template>
          </v-data-table>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model='transportDashBoard' :fullscreen="$vuetify.breakpoint.mobile" height="90vh" width="93vw">
      <TransportDashboard @close-dialog="transportDashBoard = false" />
    </v-dialog>
    <v-dialog v-model="previewShipmentFile" width="90vw" :fullscreen="$vuetify.breakpoint.mobile">
      <ViewFile :key="previewKey" v-if="previewShipmentFile && selectedFile && selectedFile.id"
        :fileId="selectedFile.id" @close="previewShipmentFile = false" />
    </v-dialog>
    <v-dialog v-model="viewContainer" width="94vw" persistent>
      <ViewContainer v-if="chosenContainer && chosenContainer.id" :booking="chosenContainer.booking"
        :container="chosenContainer" :modalOpen="viewContainer" @close="viewContainer = !viewContainer , chosenContainer = {}" />
    </v-dialog>
    <!-- 
        <v-dialog v-model="viewCTO" width="45vw">
            <v-card height="90vh">
                <v-toolbar width="100%" flat dense>
                    <v-toolbar-title>{{ previewItem.name ? previewItem.name : 'Loading...' }}</v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-btn icon @click="downloadCTO(previewItem.url, previewItem.name)"><v-icon>download</v-icon></v-btn>
                    <v-btn icon @click="viewCTO = false, previewItem = {}, chosenContainer = {}">
                        <v-icon>close</v-icon>
                    </v-btn>
                </v-toolbar>
                <div style="height: 100%; width: 100%; display: grid; place-content: center;">
                    <v-card width="40vw" height="80vh" style="overflow-y: scroll; contain: content;" :loading="loadingCto">
                        <pdf style="height: 100%; width: 100%;" v-if="previewItem != {}" :src="previewItem.url" />
                        <div v-if="loadingCto"
                            style="height: 100%; width: inherit; display: flex; justify-content: center; align-items: center;">
                            <i>Loading Content...</i>
                        </div>
                    </v-card>
                </div>
            </v-card>
        </v-dialog> -->

    <v-dialog v-model="previewModal" width="1200px" :fullscreen="fullscreen || $vuetify.breakpoint.mobile">
      <v-card v-if="previewItem && previewItem.id">
        <v-toolbar flat color="transparent">
          <v-toolbar-title>
            {{ previewItem.name }}
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn @click="downloadDocument(previewItem)" icon><v-icon>download</v-icon></v-btn>
          <v-btn @click="fullscreen = !fullscreen" icon><v-icon v-if="!fullscreen">fullscreen</v-icon><v-icon
              v-else>fullscreen_exit</v-icon></v-btn>
          <v-btn icon text @click="(previewModal = false), (previewItem = {})">X</v-btn>
        </v-toolbar>
        <v-card-text :style="{
      'max-height': fullscreen ? '90vh' : '75vh',
      'overflow-y': 'auto',
    }">
          <v-row>
            <v-col cols="12" :loading="imageLoading">
              <div v-if="previewItem.type && previewItem.type.includes('image/')">
                <v-img :src="previewItem.url" contain style="max-height: 70vh" @load="imageLoading = false">
                </v-img>
              </div>
              <div v-else-if="previewItem.type == 'application/pdf'">
                <v-row justify="center">
                  <v-col cols="12" sm="8" class="text-center" v-if="previewItem.url">
                    <div ref="pdfBox">
                      <VuePdfEmbed :width="fullscreen ? 1200 : 700" :source="previewItem.url"
                        @loaded="imageLoading = false" />
                    </div>
                  </v-col>
                </v-row>
              </div>
              <v-container v-else fill-height>
                <v-row justify="center" height="100%">
                  <div class="text-center">
                    <span>This file is in a format that cannot be previewed.</span>
                    <br /><br />
                    <v-btn color="primary" style="text-transform: none" @click="downloadDocument(item)">
                      <v-icon class="mr-1">download</v-icon> Download</v-btn>
                  </div>
                </v-row>
              </v-container>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-model="editFieldModal" width="400px">
      <v-card v-if="fieldItem">
        <v-toolbar flat color="transparent">
          <v-toolbar-title> Edit {{ fieldItem.name }} </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon color="secondary" :loading="savingField" @click="saveField"><v-icon>save</v-icon>
          </v-btn>
          <v-btn text @click="editFieldModal = false">X</v-btn>
        </v-toolbar>
        <v-card-text>
          <v-text-field v-if="fieldItem.type == 'text' && fieldItem.name == 'Container No.'" v-model="fieldItem.value"
            :rules="[rules.containerNo]" outlined dense clearable :label="fieldItem.name"></v-text-field>
          <v-text-field v-else-if="fieldItem.type == 'text'" v-model="fieldItem.value" outlined dense clearable
            :label="fieldItem.name"></v-text-field>
        </v-card-text>
      </v-card>
    </v-dialog>

    <!-- <v-dialog v-model="editETAModal" width="400px">
      <v-card>
        <v-toolbar flat color="transparent">
          <v-toolbar-title> Edit ETA </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon color="secondary" :loading="savingField" @click="updateETA"><v-icon>save</v-icon>
          </v-btn>
          <v-btn text @click="editETAModal = false">X</v-btn>
        </v-toolbar>
        <v-card-text class="time-input-container">
          <input type="time" v-model="etaTime" class="time-input">
    </v-card-text>
      </v-card>
    </v-dialog> -->


    <v-dialog v-model="assignToView" width="90vw">
      <AssignContainers :chosenContainers="selectedContainers" @close="assignToView = false"
        @update="updateAssignedContainers" />
    </v-dialog>
    <v-dialog v-model="bulkUpdateStatusModal" width="400px">
      <v-card :loading="savingBulkStatus">
        <v-toolbar flat color="transparent">
          <v-toolbar-title> Set Status </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn text @click="bulkUpdateStatusModal = false">X</v-btn>
        </v-toolbar>
        <v-card-text>
          <v-list dense>
            <v-list-item v-for="(status, index) in transportStatuses" :key="index" style="height: 35px"
              @click="setBulkStatus(status.text, status.value)">
              <v-list-item-action>
                <v-icon :color="status.color">fiber_manual_record</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>
                  {{ status.text }}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-model="addMilestoneModal" width="500px" :fullscreen="$vuetify.breakpoint.mobile">
      <ManageContainerMilestone :item="milestoneItem" :key="milestoneKey" @close="addMilestoneModal = false"
        @save="milestoneUpdate" :bookingType="this.bookingType" />
    </v-dialog>

    <v-dialog v-model="bulkCTOModal" width="500px">
      <v-card>
        <v-toolbar flat color="transparent">
          <v-toolbar-title> Bulk Create CTO </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn color="blue" text @click="startProcessingCTO()" :loading="processingCTO">Start</v-btn>
          <v-btn text @click="bulkCTOModal = false">X</v-btn>
        </v-toolbar>
        <v-card-text>
          <v-list style="max-height: 50vh; overflow-y: auto" :key="ctoUpdateKey" dense>
            <v-list-item v-for="item in selectedContainers" :key="item.id">
              <v-list-item-content>
                <v-list-item-title>
                  {{ item.ctoNo }}
                </v-list-item-title>
                <v-list-item-subtitle v-if="item.ctoDocument">
                  Version: {{ item.ctoDocument.version }}
                </v-list-item-subtitle>
                <v-list-item-subtitle v-else-if="item.errorMessage">
                  Error: {{ item.errorMessage }}
                </v-list-item-subtitle>
              </v-list-item-content>
              <v-list-item-action>
                <v-row justify="center">
                  <v-tooltip top v-if="item.ctoDocument">
                    <template v-slot:activator="{ on }">
                      <v-btn icon @click="getCTO(item)" small v-on="on">
                        <v-icon color="blue" small>launch</v-icon>
                      </v-btn>
                    </template>
                    <span style="font-size: 12px">View Document</span>
                  </v-tooltip>
                  <v-tooltip top v-if="item.ctoDocument">
                    <template v-slot:activator="{ on }">
                      <v-btn icon @click="downloadDocument(item.ctoDocument)" small v-on="on">
                        <v-icon small>download</v-icon>
                      </v-btn>
                    </template>
                    <span style="font-size: 12px">Download CTO</span>
                  </v-tooltip>
                  <v-icon v-if="item.errorMessage" color="red">error</v-icon>
                </v-row>
              </v-list-item-action>
            </v-list-item>
          </v-list>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-model="bulkDownload" width="500px">
      <v-card>
        <v-toolbar flat color="transparent">
          <v-toolbar-title> Bulk Download </v-toolbar-title>
          <v-spacer></v-spacer>
          <!-- <v-tooltip top>
            <template v-slot:activator="{ on }">
              <v-btn
                color="green"
                icon
                @click="processMissingCTO()"
                :loading="processingCTO"
                :disabled="selectedCtoGenerated"
                v-on="on"
              >
                <v-icon>article</v-icon>
              </v-btn>
            </template>
            <span>Process any missing CTOs</span>
          </v-tooltip> -->
          <v-tooltip top>
            <template v-slot:activator="{ on }">
              <v-btn color="blue" icon @click="bulkDownloadCTO()" :loading="bulkDownloadLoader" v-on="on">
                <v-icon>cloud_download</v-icon>
              </v-btn>
            </template>
            <span>Bulk download CTOs</span>
          </v-tooltip>
          <v-btn text @click="bulkDownload = false">X</v-btn>
        </v-toolbar>
        <v-card-text>
          <v-list style="max-height: 50vh; overflow-y: auto" :key="ctoUpdateKey" dense>
            <v-list-item v-for="item in selectedContainers" :key="item.id">
              <v-list-item-content>
                <v-list-item-title>
                  {{ item.ctoNo }}
                </v-list-item-title>
              </v-list-item-content>
              <v-list-item-action>
                <v-row justify="center">
                  <v-tooltip top v-if="item.ctoDocument">
                    <template v-slot:activator="{ on }">
                      <v-icon color="green" v-on="on"> check </v-icon>
                    </template>
                    <span style="font-size: 12px">CTO Availible</span>
                  </v-tooltip>
                  <v-tooltip v-else>
                    <template v-slot:activator="{ on }">
                      <v-icon color="red" v-on="on"> close </v-icon>
                    </template>
                    <span style="font-size: 12px">CTO Not Availible</span>
                  </v-tooltip>
                </v-row>
              </v-list-item-action>
            </v-list-item>
          </v-list>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-model="bulkUpdateModal" width="800px" :fullscreen="$vuetify.breakpoint.mobile">
      <v-card>
        <v-toolbar flat>
          <v-toolbar-title>Upload Results</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn text @click="bulkUpdateModal = false">X</v-btn>
        </v-toolbar>
        <v-card-text>
          <v-data-table :headers="ctoHeaders" :items="bulkUpdateData" dense>
            <template v-slot:[`item.created`]="{ item }">
              <v-icon v-if="item.created" color="success">check_circle_outline</v-icon>
              <v-icon v-else color="red">cancel</v-icon>
            </template>
          </v-data-table>
          <!-- <v-list dense style="">
            <v-list-item v-for="(cto, index) in bulkUpdateData" :key="index"> 
           
              <v-list-item-content>
                <v-list-item-title>{{ cto.ctoNo }}</v-list-item-title>
                <v-list-item-subtitle v-if="cto.containerNo">
                <v-icon small color="grey" class="mr-2">tag</v-icon> {{ cto.containerNo }}
                </v-list-item-subtitle>
                <v-list-item-subtitle v-if="cto.sealNo">
                <v-icon small color="grey" class="mr-2">tag</v-icon> {{ cto.sealNo }}
                </v-list-item-subtitle>
                <v-list-item-subtitle v-if="cto.tareWeight">
                <v-icon small color="grey" class="mr-2">scale</v-icon> {{ cto.tareWeight }}
                </v-list-item-subtitle>
                <v-list-item-subtitle v-if="cto.tareWeight">
                <v-icon small color="grey" class="mr-2">thermostat</v-icon> {{ cto.tempSerialNo }}
                </v-list-item-subtitle>
              </v-list-item-content>
              <v-list-item-action>
                <v-icon v-if="cto.created" color="success">check_circle_outline</v-icon>
                <v-icon v-else color="red">cancel</v-icon>
              </v-list-item-action>
            </v-list-item>
          </v-list> -->
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-model="confirmRemoveTransporter" width="500px">
      <v-card v-if="selectedContainers">
        <v-toolbar dense flat color="transparent">
          <v-spacer></v-spacer>
          <v-btn text @click="confirmRemoveTransporter = false">X</v-btn>
        </v-toolbar>
        <v-card-text>
          <p style="font-size: 16px">
            Are you sure you would like to remove the transporter from these
            {{ selectedContainers.length }} items?
          </p>
          <v-row justify="center" class="mt-5 mb-3">
            <v-btn color="red" class="mx-2" text small @click="confirmRemoveTransporter = false">No</v-btn>
            <v-btn color="success" class="mx-2" text small @click="removeTransporter()"
              :loading="savingRemoveTransporter">Yes</v-btn>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
    <!--emailPlanningModal-->

    <v-dialog v-model="emailPlanningModal" width="50%">
      <v-card>
        <v-toolbar flat color="transparent">
          <v-toolbar-title> Email Planning </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon @click="submitEmail" color="secondary" :loading="emailLoading" small><v-icon>send</v-icon></v-btn>
          <v-btn text @click="exitEmailPlanning" color="primary">X</v-btn>

        </v-toolbar>
        <v-card-text>
          <v-card-subtitle>
            Transporter : {{ transporterLoads.transporter }}
          </v-card-subtitle>
          <v-card-subtitle>
            Date : {{ transporterLoads.date }}
          </v-card-subtitle>
          <v-text-field label="Email Adresses" class="ml-4" outlined
            v-model="transporterLoads.transportEmailAdress"></v-text-field>
            <v-text-field label="CC Adresses" class="ml-4" outlined
            v-model="transporterLoads.ccEmailAdress"></v-text-field>
            <v-text-field label="Reply Adresses" class="ml-4" outlined
            v-model="transporterLoads.replyToEmailAdress"></v-text-field>
          <template>
            <v-card v-for="(shipper, index) in transporterLoads.shippers" :key="index" class='my-1'>
              <v-card-title>
                <v-btn icon @click="toggleShipper(index)">
                  <v-icon>{{ expandedShippers[index] ? 'expand_less' : 'expand_more' }}</v-icon>
                </v-btn>
                <div>
                  <v-tooltip top>
                    <template v-slot:activator="{ on }">


                      <v-chip v-on="on" small class="primaryText--text" outlined style="border: none">
                        <v-avatar color="green">
                          <h4 style="color: white">
                            <!--Check if shipper.name is in the the uniqueShipperLoadCount and return the value against it-->
                            {{ uniqueShipperLoadCount[shipper.name] }}

                          </h4>
                        </v-avatar>

                      </v-chip>
                    </template>
                    <span>Client Load count</span>
                  </v-tooltip>
                </div>
                {{ shipper.name }}
              </v-card-title>
              <v-expand-transition>
                <div v-show="expandedShippers[index]">
                  <v-card-subtitle v-for="load in shipper.loads" :key="load.loadingPoint1">
                    <div style='color:orange'>
                      {{ load.quantity }} X {{ load.loadingPoint1 }}{{ (load.loadingPoint2) ? '/ ' + load.loadingPoint2
      :
      ''
                      }}
                    </div>
                    <!-- <v-spacer></v-spacer> <v-btn icon color='primary'><v-icon>add_circle_outline</v-icon></v-btn> -->

                    <v-list>
                      <v-list-item>
                        <v-icon small>subdirectory_arrow_right</v-icon>
                        Empty : {{ load.depot }}
                        <v-spacer></v-spacer>
                      </v-list-item>
                      <v-list-item>
                        <v-icon small>subdirectory_arrow_right</v-icon>
                        Vessel : {{ load.vessel }}
                        <v-spacer></v-spacer>
                      </v-list-item>
                      <v-list-item>
                        <v-icon small>subdirectory_arrow_right</v-icon>
                        VGM Method : {{ load.method }}
                        <v-spacer></v-spacer>
                      </v-list-item>
                      <v-list-item v-if="load.gensetRequired">
                        <v-icon small>subdirectory_arrow_right</v-icon>
                        GENSET REQUIRED
                        <v-spacer></v-spacer>
                      </v-list-item>
                    </v-list>
                    <v-list v-if="load.temptales.length > 0" dense>
                      <v-list-item v-for="temptale in load.temptales" :key="temptale.temptale" dense>
                        <v-icon small>subdirectory_arrow_right</v-icon>
                        {{ temptale.count + 'X' + temptale.temptale }}
                        <v-spacer></v-spacer>
                        <v-checkbox small v-model="temptale.transporterSupplied"></v-checkbox>
                      </v-list-item>
                    </v-list>
                    <v-list v-else>
                      <v-list-item>
                        <v-icon small>subdirectory_arrow_right</v-icon>
                        No Temptales
                      </v-list-item>
                    </v-list>
                  </v-card-subtitle>
                </div>
              </v-expand-transition>
            </v-card>
          </template>


        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-model="transporterReportModal" width="600px">
      <v-card>
        <v-toolbar flat color="transparent">
          <v-toolbar-title>Transporter Loads</v-toolbar-title>

          <v-spacer></v-spacer>
          <v-btn icon color="secondary" @click="getTransporterLoad" :loading="loadingTransporterLoads"><v-icon
              small>arrow_forward</v-icon></v-btn>
          <v-btn icon @click="cancelTransportModal" color="primary">X</v-btn>
        </v-toolbar>
        <v-card-text>
          <v-row>
            <v-layout>
              <v-radio-group v-model="transporterDay" class='ml-4'>
                <v-radio label="Yesterday" value="Yesterday"></v-radio>
                <v-radio label="Today" value="Today"></v-radio>
                <v-radio label="Tomorrow" value="Tomorrow"></v-radio>
                <v-radio label="2-Days" value="2-Days"></v-radio>
                <v-radio label="3-Days" value="3-Days"></v-radio>
              </v-radio-group>
            </v-layout>

            <v-autocomplete v-model="searchTransporter" :items="transporters" :loading="loadingTransporters"
              :item-text="item => item.alias ? item.alias : item.name" item-value="id" label="Search for a Transporter"
              return-object :disabled="!transporters" class="mt-11 mr-6">
            </v-autocomplete>

          </v-row>
          <v-row>
            <v-layout justify-center>
              <v-radio-group v-model="transportStatusSelected" row>
                <v-flex xs6>
                  <v-radio label="Allocaed" value="Allocated"></v-radio>
                </v-flex>
                <v-flex xs6>
                  <v-radio label="Select" value="selection"></v-radio>
                </v-flex>
                <!-- <v-flex xs4>
                  <v-radio label="Today" value="Today"></v-radio>
                </v-flex> -->
              </v-radio-group>
            </v-layout>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-model="pluggedInReport" width="500px" persistent>
      <v-card>
        <v-toolbar flat color="transparent">
          <v-toolbar-title> Plugged In Report </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon @click="getPluggedInReport" color="secondary" :loading="downloadReport"
            :disabled="!selectedPluggInDepot && !searchCustomer && !dateSelection"><v-icon
              small>download</v-icon></v-btn>
          <v-btn text @click="pluggedInReport = false" color="primary">X</v-btn>
        </v-toolbar>

        <v-card-text>
          <!-- <v-radio-group v-model="dateSelection" row class="ml-15">
              <v-flex xs4>
                <v-radio label="Default" value="default"></v-radio>
              </v-flex>
              <v-flex xs6>
                <v-radio label="Manaul selection" value="manual"></v-radio>
              </v-flex>
            </v-radio-group>

            <v-date-picker v-model="pluggedInDates" range color="primary" v-if="dateSelection === 'manual'" class="ml-15">
            </v-date-picker>
-->
          <v-card outlined>
            <v-card-title>Date Picker</v-card-title>
            <v-card-text>
              <v-radio-group v-model="dateSelection" row class="ml-15">
                <v-flex xs4>
                  <v-radio label="Default" value="default"></v-radio>
                </v-flex>
                <v-flex xs6>
                  <v-radio label="Manual selection" value="manual"></v-radio>
                </v-flex>
              </v-radio-group>

              <v-date-picker v-model="pluggedInDates" range color="primary" v-if="dateSelection === 'manual'"
                class="ml-15">
              </v-date-picker>
            </v-card-text>
          </v-card>
          <v-autocomplete v-model="selectedPluggInDepot" :items="pluggedInDepots" :item-text="item => item.text"
            item-value="poiId" label="Search for a Plug In Depot" return-object :disabled="!pluggedInDepots">
          </v-autocomplete>
          <v-autocomplete v-model="searchCustomer" :items="customers" :loading="loadingCustomers"
            :item-text="item => item.alias ? item.alias : item.name" item-value="id" label="Search for a Shipper"
            return-object :disabled="!customers">
          </v-autocomplete>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-model="plugInDialog" width="600px" persistent>
      <v-card>
        <v-toolbar flat color="transparent">
          <v-toolbar-title> Plug In </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon @click="plugInLayout = true, plugInDialog = false" color="secondary"
            :disabled="!selectedPlugInDepot && !selectedPlugInDate">
            <v-icon>arrow_forward</v-icon>
          </v-btn>
          <v-btn icon @click="closePlugInDialog" color="red">
            <v-icon>close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text>
          <v-row>
            <!-- <v-col cols="4">
              <v-date-picker v-model="selectedPlugInDate"></v-date-picker>
            </v-col> -->
            <v-col cols="6">
              <v-menu :close-on-content-click="true" transition="scale-transition" offset-y min-width="auto">
                <template v-slot:activator="{ on }">
                  <v-list-item v-on="on">
                    <v-list-item-action>
                      <v-icon color="grey">today</v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                      <v-list-item-title v-if="selectedPlugInDate">
                        {{ formatDate(selectedPlugInDate) }}
                      </v-list-item-title>
                      <v-list-item-title v-else>
                        -
                      </v-list-item-title>
                      <v-list-item-subtitle>
                        Date
                      </v-list-item-subtitle>
                    </v-list-item-content>
                    <!-- <v-list-item-action v-if="item.selectedDate">
                                        <v-btn icon @click.stop="removeDate"><v-icon color="red">cancel</v-icon></v-btn>
                                    </v-list-item-action> -->
                  </v-list-item>
                </template>

                <v-date-picker v-model="selectedPlugInDate" no-title scrollable>
                </v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="6">
              <v-autocomplete v-model="selectedPlugInDepot" :items="pluggedInOptions" :item-text="item => item.name"
                item-value="id" label="Search for a Depot" return-object :disabled="!pluggedInOptions" />
            </v-col>

          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-model="plugInLayout" width="800px" persistent>
      <v-card>
        <v-toolbar flat color="transparent">
          <!-- <v-toolbar-title> PLUG IN REQUEST - {{ new Date() }}</v-toolbar-title>  -->

          <v-toolbar-title> Email Context </v-toolbar-title>
          <v-spacer></v-spacer>

          <v-btn icon color="secondary" @click="createPlugMail" :loading='emailLoading'
            :disabled='!isSendButtonEnabled'>
            <v-icon>send</v-icon>
          </v-btn>
          <v-btn icon @click="closePlugInLayout" color="red">
            <v-icon>close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text>
          <v-row>
            <v-col>
              <v-card-subtitle>Depot: {{ selectedPlugInDepot.friendlyName ? selectedPlugInDepot.friendlyName :
      selectedPlugInDepot.name }}</v-card-subtitle>
              <v-card-subtitle>Title : PLUG IN REQUEST - {{ formatDate(selectedPlugInDate) }}</v-card-subtitle>
              <v-text-field label="Email Adresses" class="ml-4" outlined
                v-model="pluggedInEmailAdresses"></v-text-field>
                <v-text-field label="CC Adresses" class="ml-4" outlined
                v-model="ccPluggedInAddr"></v-text-field>
                <v-text-field label="Reply Adresses" class="ml-4" outlined
                v-model="replyPluggedInAddr"></v-text-field>
              <!-- <v-card-subtitle> Email Adress : <v-text-field></v-text-field></v-card-subtitle>   -->
              <!-- <v-card-subtitle>Email Adress: {{ selectedPlugInDepot.organisation ? selectedPlugInDepot.organisation.emailAddress : '' }}</v-card-subtitle> -->
            </v-col>

          </v-row>
          <v-divider color="orange" class="mb-3"></v-divider>

          <v-row>
            <v-col cols="12">
              <div v-for="container in selectedContainers" :key="container.id">
                <p :style="{ color: container.containerNo ? '' : 'red' }"><strong>CONTAINER NUMBER:</strong> {{
      container.containerNo || '???' }}</p>
                <p :style="{ color: container.booking && container.booking.regime ? '' : 'red' }"><strong>TEMP:</strong> {{ container.booking && container.booking.regime
      ?
      container.booking.regime.setPointTemp + ' ' + '°C' : '??' }}</p>
                <p :style="{ color: container.booking && container.booking.vents ? '' : 'red' }"><strong>VENTS:</strong> {{ container.booking && container.booking.vents ?
      container.booking.vents : '???' }}</p>
                <p :style="{ color: container.contents.length > 0 ? '' : 'red' }"><strong>COMMODITY:</strong> {{
      container.contents.join(', ') }}</p>
                <p :style="{ color: container.carrierReferenceNumber ? '' : 'red' }"><strong>BOOKING REF:</strong> {{
      container.carrierReferenceNumber ?
        container.carrierReferenceNumber.toUpperCase() : '???' }}</p>
                <p :style="{ color: container.booking && container.booking.vessel ? '' : 'red' }"><strong>VESSEL:
                  </strong>
                  {{ container.booking && container.booking.vessel ?
                  container.booking.vessel.name.toUpperCase() : '???' }}</p>
                <p v-if="container.booking.regime && container.booking.regime.steri" style="color: red;"><strong>STERI
                    LOAD
                  </strong></p>
                <v-divider class="mb-3"></v-divider>

              </div>
            </v-col>
          </v-row>

        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-model="confirmContainerDescrModal" width="800px">
      <v-card>
        <v-toolbar flat color="transparent">
          <v-toolbar-title> Container Swap</v-toolbar-title>
          <v-spacer></v-spacer>
          <!-- <template>
            <v-btn icon @click="updateContainerDescr"><v-icon color="blue" >swap_horiz</v-icon></v-btn>
          </template> -->
          <template>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn icon v-bind="attrs" v-on="on" @click="updateContainerDescr">
                  <v-icon :color="isSwap ? 'blue' : 'green'">{{ isSwap ? 'swap_horiz' : 'arrow_forward' }}</v-icon>
                </v-btn>
              </template>
              <span>{{ isSwap ? 'Swap containers' : 'Proceed wihtout swapping container' }}</span>
            </v-tooltip>
          </template>

        </v-toolbar>
        <v-card-text>
          <v-data-table :headers="containerDescrHeaders" :items="containerDescr" :items-per-page="10"
            class="elevation-1" :search="searchLoads">

            <template v-slot:[`item.action`]="{ item }">
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn icon v-bind="attrs" v-on="on" @click="toggleContainerDescrSelection(item)">
                    <v-icon v-if="item.selectedLoad == true" color="green">check</v-icon>
                    <v-icon v-else color="red">dangerous</v-icon>
                  </v-btn>
                </template>
                <span>{{ item.selectedLoad ? 'Container to be swapped' : 'No container to be swapped' }}</span>
              </v-tooltip>
            </template>
            <template v-slot:[`item.ctoNo`]="{ item }">
              <v-chip small class="primaryText--text" outlined style="border: none">
                {{ item.existingContainer.ctoNo }}
              </v-chip>
            </template>
            <template v-slot:[`item.existingContainerNo`]="{ item }">
              <v-chip small class="primaryText--text" outlined style="border: none">
                {{ item.existingContainer.containerNo }}
              </v-chip>
            </template>
            <template v-slot:[`item.icon`]>
              <v-icon small color="blue" outlined style="border: none">
                swap_horiz
              </v-icon>
            </template>

            <template v-slot:[`item.newContainerNo`]="{ item }">
              <v-chip small class="primaryText--text" outlined style="border: none">
                {{ item.importContainer.containerNo }}
              </v-chip>
            </template>
          </v-data-table>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-model="clientReportModal" width="500px">
      <v-card>
        <v-toolbar flat color="transparent">
          <v-toolbar-title> Client Report </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon @click="getShipperReport" color="secondary" :loading="downloadReport"
            :disabled="!selectedDay && !searchCustomer && !movementType && !selectedPort"><v-icon
              small>download</v-icon></v-btn>
          <v-btn text @click="clientReportModal = false" color="primary">X</v-btn>
        </v-toolbar>
        <v-card-text>
          <v-layout justify-center>
            <v-radio-group v-model="selectedDay" row>
              <v-flex xs4>
                <v-radio label="Yesterday" value="Yesterday"></v-radio>
              </v-flex>
              <v-flex xs4>
                <v-radio label="Today" value="Today"></v-radio>
              </v-flex>
              <v-flex xs4>
                <v-radio label="Tomorrow" value="Tomorrow"></v-radio>
              </v-flex>
            </v-radio-group>
          </v-layout>
          <v-layout justify-center>
            <v-radio-group v-model="selectedPort" row>
              <v-flex xs3>
                <v-radio label="CPT" value="cpt"></v-radio>
              </v-flex>
              <v-flex xs3>
                <v-radio label="DBN" value="dbn"></v-radio>
              </v-flex>
              <v-flex xs3>
                <v-radio label="PLZ" value="plz"></v-radio>
              </v-flex>
              <v-flex xs3>
                <v-radio label="NAM" value="name"></v-radio>
              </v-flex>
            </v-radio-group>
          </v-layout>
          <v-layout justify-center>
            <v-radio-group v-model="movementType" row>
              <v-flex xs6>
                <v-radio label="Export" value="EXPORT"></v-radio>
              </v-flex>
              <v-flex xs6>
                <v-radio label="Import" value="IMPORT"></v-radio>
              </v-flex>
            </v-radio-group>
          </v-layout>

          <!-- <v-layout justify-center>
            <v-radio-group v-model="fileType" row>
              <v-flex xs6>
                <v-radio label="Excel" value="excel"></v-radio>
              </v-flex>
              <v-flex xs6>
                <v-radio label="PDF" value="pdf"></v-radio>
              </v-flex>
            </v-radio-group>
          </v-layout> -->

          <v-layout justify-center>
            <v-switch v-model="longDistance" label="Long Distance Loads"></v-switch>
          </v-layout>

          <v-autocomplete v-model="searchCustomer" :items="customers" :loading="loadingCustomers"
            :item-text="item => item.alias ? item.alias : item.name" item-value="id" label="Search for a Shipper"
            return-object :disabled="!customers">
            <!-- <template v-slot:item="{ item }">
            <v-list-item>
              <v-list-item-avatar>
                <v-badge>
                  <template v-slot:badge>
                    <v-img :src="item.logo"></v-img>
                  </template>
                </v-badge>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title >{{ item.alias ? item.alias : item.name }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </template> -->
          </v-autocomplete>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model='loadSelection' width="1400px">
      <v-card>
        <v-card-toolbar>
          <v-card-title>{{ searchTransporter ? searchTransporter.name : null }}
            <v-spacer></v-spacer>
            <v-btn icon @click="submitLoadSelection" :loading="loadingTransporterLoads"
              color="secondary"><v-icon>arrow_forward</v-icon></v-btn>
            <v-btn icon @click="loadSelection = false" color="primary"><v-icon>close</v-icon></v-btn>

          </v-card-title>

        </v-card-toolbar>
        <v-card-text>
          <!-- <v-text-field
          v-model="searchLoads"
          append-icon="search"
          label="Search"
          single-line
          hide-details
        ></v-text-field> -->
          <v-row>
            <v-spacer></v-spacer>
            <v-col cols="3">
              <v-text-field v-model="searchLoads" append-icon="search" label="Search" single-line
                hide-details></v-text-field>
            </v-col>
          </v-row>
          <v-data-table :items="transporterSelectionLoads" :headers="transporterSelectionHeaders" :items-per-page="10"
            class="elevation-1" :search="searchLoads">
            <template v-slot:[`item.action`]="{ item }">
              <v-btn icon @click="toggleLoadSelection(item)">
                <v-icon v-if="item.selectedLoad == true" color="green">check</v-icon>
                <v-icon v-else color="primary">clear</v-icon>
              </v-btn>
            </template>

            <template v-slot:[`item.loadingPoint1`]="{ item }">
              <div>
                <span>
                  {{
                  (milestone = item.containerMilestones.find(
                  milestone => milestone.type == 'LOADING_POINT_1'
                  ))
                  ? milestone.description
                  : ''
                  }}
                </span>
              </div>
            </template>


            <template v-slot:[`item.loadingPoint2`]="{ item }">
              <div>
                <span>
                  {{
                  (milestone = item.containerMilestones.find(
                  milestone => milestone.type == 'LOADING_POINT_2'
                  ))
                  ? milestone.description
                  : ''
                  }}
                </span>
              </div>
            </template>


            <template v-slot:[`item.depot`]="{ item }">
              <div>
                <span>
                  {{
                  (milestone = item.containerMilestones.find(
                  milestone => milestone.type == 'EMPTY_PICKUP'
                  ))
                  ? milestone.description
                  : ''
                  }}
                </span>
              </div>
            </template>
            <template v-slot:[`item.features`]="{ item }">
              <div>
                <v-btn v-if="item.temporaryProducts.some(product => product.product === 'Genset')" icon color="#c4c166">
                  <v-icon>
                    bolt
                  </v-icon>
                </v-btn>
                <v-btn v-if="item.temporaryProducts.some(product => product.product === 'TelemPlus')" icon
                  color="#18e00d">
                  <v-icon>
                    router
                  </v-icon>
                </v-btn>
                <!-- <v-btn v-if="item.booking.caProduct" icon color="#c4c166">
                  <v-icon>
                    ac_unit
                  </v-icon>
                </v-btn> -->
              </div>
            </template>

          </v-data-table>
        </v-card-text>
      </v-card>

    </v-dialog>

    <file-upload class="my-0 py-0" post-action="" :multiple="false" :drop="false"
      accept="text/csv,application/vnd.ms-excel,application/x-vnd.oasis.opendocument.spreadsheet,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
      :drop-directory="true" v-model="fileList" ref="uploader">
    </file-upload>
  </div>
</template>
<script>
import AssignContainers from "./Dialogs/AssignContainers.vue";
import dateFormat from "dateformat";
import draggable from "vuedraggable";
import FileUpload from "vue-upload-component";
import * as moment from "moment";

// import pdf from "vue-pdf";
import ManageContainerMilestone from "./Dialogs/ManageContainerMilestone.vue";
import TransportDashboard from "./TransportDashboard.vue";
import ViewContainer from "./Dialogs/ViewContainerIntermodal.vue";
import ViewFile from "../SystemComponents/PreviewFileModal.vue";
import VuePdfEmbed from "vue-pdf-embed/dist/vue2-pdf-embed";
import * as XLSX from "xlsx";
import { PDFDocument } from "pdf-lib";

// import VueResizable from 'vue-resizable'
export default {
  components: {
    AssignContainers,
    draggable,
    FileUpload,
    ManageContainerMilestone,
    // pdf,
    ViewContainer,
    ViewFile,
    VuePdfEmbed,
    // VueResizable,
    TransportDashboard
  },
  data: () => ({
    selectedPlugInDate: null,
    plugInDialog: false,
    containerDescr: [],
    dateSelection: 'default',
    pluggedInDates: [],
    containerDescrHeaders: [
      {
        text: 'Action',
        value: 'action',
      },
      {
        text: 'Cto No.',
        value: 'ctoNo',
      },
      {
        text: 'Existing Container #',
        value: 'existingContainerNo',
      },
      {
        text: '',
        value: 'icon',
        sortable: false,
      },
      {
        text: 'New Container #',
        value: 'newContainerNo'
      }
    ],
    serachDepot: null,
    pluggedInReport: false,
    transportDashBoard: false,
    searchLoads: "",
    expandedShippers: {},
    loadingTransporterLoads: false,
    transporterDay: null,
    transportStatusSelected: "Allocated",
    selectedPluggInDepot: null,
    selectedDay: null,
    movementType: 'EXPORT',
    fileType: 'excel',
    selectedPort: 'cpt',
    searchCustomer: null,
    searchTransporter: null,
    customers: [
      // Your list of customers here
    ],
    addMilestoneModal: false,
    assignToView: false,
    availableTransportStatuses: [],
    bookingStatus: [
      { name: "Draft", icon: "edit_note", color: "#F57C00" },
      { name: "Pending", icon: "pending", color: "#009688" },
      { name: "Requested", icon: "verified", color: "#2196F3" },
      { name: "Confirmed", icon: "check_circle_outline", color: "#4CAF50" },
      { name: "Ghost", icon: "help", color: "#607D8B" },
      { name: "Cancelled", icon: "cancel", color: "#F44336" },
    ],
    bulkUpdateStatusModal: false,
    bulkUpdateModal: false,
    bulkUpdateData: [],
    columnDrag: false,
    chosenContainer: {},
    chosenContainers: [],
    confirmRemoveTransporter: false,
    containers: [],
    transporterLoads: [],
    transporterSelectionLoads: [],

    transporterSelectionHeaders: [
      {
        text: 'Action',
        value: 'action',
      },
      {
        text: 'Cto No.',
        value: 'ctoNo',
      },
      {
        text: 'Method',
        value: 'verificationMethod'
      },
      {
        text: 'Vessel',
        value: 'booking.vessel.name'
      },
      {
        text: 'Shipper',
        value: 'booking.shipper.name'
      },
      {
        text: 'Booking Ref',
        value: 'booking.carrierReferenceNumber'
      },
      {
        text: 'Customer Ref',
        value: 'customerContainerRef'
      },
      {
        text: 'Features',
        value: 'features',
        width: '150px'
      },
      {
        text: 'LP-1',
        value: 'loadingPoint1'
      },
      {
        text: 'LP-2',
        value: 'loadingPoint2'
      },
      {
        text: 'Depot',
        value: 'depot'
      }

    ],
    containerChatArea: null,
    containerQuery: null,
    plugInLayout: false,
    containerStatus: [
      { name: "Unallocated", icon: "warning", color: "orange darken-2" },
      { name: "Ghost", icon: "category", color: "blue-grey" },
      { name: "Moved", icon: "exit_to_app", color: "blue" },
      { name: "Allocated", icon: "task_alt", color: "success" },
      { name: "Cancelled", icon: "cancel", color: "red" },
      { name: "Claim", icon: "attach_money", color: "indigo" },
    ],
    containerTimeout: null,
    ctoHeaders: [
      { text: "Success", value: "created", align: "center" },
      { text: "CTO No.", value: "ctoNo", align: "center" },
      { text: "Container No.", value: "containerNo", align: "center" },
      { text: "Seal No.", value: "sealNo", align: "center" },
      { text: "Tare Weight", value: "tareWeight", align: "center" },
      { text: "Device Serial No.", value: "tempSerialNo", align: "center" },
    ],
    ctoUpdateKey: 650,
    bulkCTOModal: false,
    bulkDownload: false,
    dateMenu: false,
    dateType: "Loading Date",
    longDistance: false,
    editFieldModal: false,
    fieldItem: null,
    filters: {},
    filteredValues: [],
    filteredResults: [],
    fileList: [],
    fullscreen: false,
    clientReportModal: false,
    selectedBookingContainerIds: [],
    loadsAdded: 0,
    checkboxModels: {},
    transportEmailAdress: "",
    milestoneTypes: [
      // {
      //   name: "ETD",
      //   value: "ETD",
      // },
      {
        name: "Empty Pickup Date",
        eventType: "TRANSPORT",
        modeOfTransport: "TRUCK",
        value: "EMPTY_PICKUP",
      },
      // {
      //     name: 'Full Pickup Date',
      //     eventType: 'TRANSPORT',
      //     modeOfTransport: 'TRUCK',
      //     value: 'FULL_PICKUP'
      // },
      // {
      //     name: 'Loading Date 1',
      //     eventType: 'TRANSPORT',
      //     modeOfTransport: 'TRUCK',
      //     value: 'LOADING_POINT_1'
      // },
      // {
      //     name: 'Loading Date 2',
      //     eventType: 'TRANSPORT',
      //     modeOfTransport: 'TRUCK',
      //     value: 'LOADING_POINT_2'
      // },
      {
        name: "Plugged In Date",
        eventType: "TRANSPORT",
        modeOfTransport: "TRUCK",
        value: "PLUG_IN",
      },
      {
        name: "Unplugged Date",
        eventType: "TRANSPORT",
        modeOfTransport: "TRUCK",
        value: "PLUG_OUT",
      },
      {
        name: "Port Holding Date",
        eventType: "TRANSPORT",
        modeOfTransport: "TRUCK",
        value: "FULL_DROP_OFF",
      },
      {
        name: "Loading Date",
        eventType: "TRANSPORT",
        modeOfTransport: "TRUCK",
        value: ["LOADING_POINT_1", "LOADING_POINT_2", "FULL_PICKUP"],
      },
    ],
    comingFeature: true,
    pluggedInEmailAdresses: "",
    ccPluggedInAddr: "",
    replyPluggedInAddr: "",
    transporterReportModal: false,
    headers: [
      {
        text: "Actions",
        disableSort: true,
        disableFilter: true,
        value: "actions",
        show: true,
      },

      {
        text: "Ready",
        value: "isConfirmedBooking",
        show: false,
        width: "90px",
      },
      {
        text: "Status",
        value: "transportStatus",
        show: true,
      },
      {
        text: "CTO No.",
        value: "ctoNo",
        align: "center",
        sortable: true,
        width: "250px",
        show: true,
      },
      {
        text: "Method",
        value: "verificationMethod",
        show: true,
      },
      {
        text: "Preadvise",
        value: "preadviseStatus",
        show: true,
      },
      {
        text: "Gross Weight",
        value: "grossWeight",
        show: true,
      },

      {
        text: "Tare Weight",
        value: "tareWeight",
        show: true,
      },
      {
        text: "VGM",
        value: "vgmWeight",
        show: true,
      },
      {
        text: "Shipper",
        value: "shipperName",
        align: "center",
        width: "220px",
        show: true,
      },
      {
        text: "Empty Pickup Date",
        value: "EMPTY_PICKUP_DATE",
        show: true,
      },
      {
        text: "Empty Pickup Time",
        value: "EMPTY_PICKUP_TIME",
        show: true,
      },
      {
        text: "Full Pickup",
        value: "FULL_PICKUP_DESCRIPTION",
        show: true,
      },
      {
        text: "Empty Pickup",
        value: "EMPTY_PICKUP_DESCRIPTION",
        show: true,
      },
      {
        text: "Full Pickup Date",
        value: "FULL_PICKUP_DATE",
        show: true,
      },
      {
        text: "Full Pickup Time",
        value: "FULL_PICKUP_TIME",
        show: true,
      },
      {
        text: "Drop Off Empty",
        value: "EMPTY_DROP_OFF_DESCRIPTION",
        show: true,
      },
      {
        text: "Drop Off Empty Date",
        value: "EMPTY_DROP_OFF_DATE",
        show: true,
      },
      {
        text: "Drop Off Empty Time",
        value: "EMPTY_DROP_OFF_TIME",
        show: true,
      },
      {
        text: "Port Holding",
        value: "FULL_DROP_OFF_DESCRIPTION",
        show: true,
      },
      {
        text: "LP-1",
        value: "LOADING_POINT_1_DESCRIPTION",
        show: true,
      },
      {
        text: "LP-1 Date",
        value: "LOADING_POINT_1_DATE",
        show: true,
      },
      {
        text: "LP-1 Time",
        value: "LOADING_POINT_1_TIME",
        show: true,
      }, 
      {
        text: "LP-2",
        value: "LOADING_POINT_2_DESCRIPTION",
        show: true,
      },
      {
        text: "LP-2 Date",
        value: "LOADING_POINT_2_DATE",
        show: true,
      },
      {
        text: "LP-2 Time",
        value: "LOADING_POINT_2_TIME",
        show: true,
      },
      // {
      //   text: "LP-3 Date",
      //   value: "LOADING_POINT_2_DATE",
      //   show: true,
      // },
      {
        text: "Weigh Bridge",
        value: "WEIGH_BRIDGE_DESCRIPTION",
        show: false,
      },
      {
        text: "Weigh Bridge Date",
        value: "WEIGH_BRIDGE_DATE",
        show: false,
      },
      {
        text: "Weigh Bridge Time",
        value: "WEIGH_BRIDGE_TIME",
        show: false,
      },
      {
        text: "DP-1",
        value: "DELIVERY_POINT_1_DESCRIPTION",
        show: true,
      },
      {
        text: "DP-1 Date",
        value: "DELIVERY_POINT_1_DATE",
        show: true,
      },
      {
        text: "DP-1 Time",
        value: "DELIVERY_POINT_1_TIME",
        show: true,
      },
      {
        text: "DP-2 Time",
        value: "DELIVERY_POINT_2_TIME",
        show: true,
      },
      {
        text: "DP-3 Time",
        value: "DELIVERY_POINT_3_TIME",
        show: true,
      },
      {
        text: "DP-2",
        value: "DELIVERY_POINT_2_DESCRIPTION",
        show: true,
      },
      {
        text: "DP-2 Date",
        value: "DELIVERY_POINT_2_DATE",
        show: true,
      },
      {
        text: "DP-3",
        value: "DELIVERY_POINT_3_DESCRIPTION",
        show: true,
      },
      {
        text: "DP-3 Date",
        value: "DELIVERY_POINT_3_DATE",
        show: true,
      },
      {
        text: "Container No.",
        value: "containerNo",
        show: true,
      },
      {
        text: "Seal No.",
        value: "sealNo",
        show: true,
      },
      {
        text: "Carrier Ref.",
        value: "carrierReferenceNumber",
        show: true,
      },
      {
        text: "Vessel",
        value: "vesselVoyage",
        width: "220px",
        show: true,
      },
      {
        text: "Transhipment Port",
        value: "portOfDischargeValue",
        show: true,
      },
      {
        text: "Type",
        value: "containerTypeCode",
        show: true,
      },
      {
        text: "Transporter",
        value: "transporterName",
        width: "220px",
        show: true,
      },
      {
        text: "Nett Weight",
        value: "nettWeight",
        show: false,
      },

      {
        text: "Customer Ref.",
        value: "customerContainerRef",
        width: "180px",
        show: false,
      },

      {
        text: "ETD",
        value: "etd",
        show: false,
      },

      {
        text: "Empty Time",
        value: "GATE_OUT_EMPTY_DATE",
        show: false,
      },
      {
        text: "Plug-in Depot",
        value: "PLUG_IN_DESCRIPTION",
        show: false,
      },
      {
        text: "Depot In",
        value: "PLUG_IN_DATE",
        show: true,
        width: "220px",

      },
      //   {
      //     text: "Depot-Out Date",
      //     value: "PLUG_OUT_DATE",
      //     show: true,
      //   },
      {
        text: "Depot Out",
        value: "PLUG_OUT_DATE",
        show: false,
      },

      {
        text: "Drop Off Time",
        value: "GATE_IN_FULL_DATE",
        show: false,
      },
      {
        text: "Documents",
        value: "documents",
        disableFilter: true,
        disableSort: true,
        show: false,
      },
      {
        text: "OrderNo",
        value: "orderNo",
        align: "center",
        sortable: false,
        show: false,
      },

      {
        text: "Features",
        value: "features",
        alias: "features",
        align: "center",
        sortable: false,
        show: true,
      },
      {
        text: "Regime",
        value: "regimeCode",
        show: false,
      },

      {
        text: "Consignee",
        value: "consigneeName",
        width: "220px",
        show: false,
      },
      {
        text: "Carrier",
        value: "shippingLineName",
        width: "120px",
        show: false,
      },
      {
        text: "POL",
        value: "portOfLoadValue",
        show: false,
      },

      // {
      //   text: "Genset",
      //   value: "gensetRequired",
      //   width: "120px",
      //   show: false,
      // },
      {
        text: "Sensor Device",
        value: "sensorDeviceType",
        show: false,
      },
      {
        text: "Service",
        value: "sensorService",
        alias: "sensorService",
        show: false,
      },
      {
        text: "Sensor Serial",
        value: "tempSerialNo",
        show: false,
      },
      {
        text: "Customer",
        value: "customerName",
        show: false,
        align: "center",
      },
      {
        text: "Final Destination",
        value: "finalDestinationValue",
        show: false
      },
      {
        text: 'Product Description',
        value: "containerProduct",
        show: false
      },
      {
        text: 'Stacks Open',
        value: "stackOpen",
        show: false
      },
      {
        text: 'Stacks Closed',
        value: "stackClosed",
        show: false
      },

      {
        text: 'ETA',
        value: "eta",
        show: false
      },
      {
        text: 'Dummy Weight',
        value: 'dummyWeight',
        show: false
      }
    ],
    params: {
      dates: [],
    },
    pluggedInDepots: [],
    imageLoading: false,
    loading: false,
    loadingComments: false,
    loadingCto: false,
    loadingSaveCustom: false,
    milestoneItem: {},
    milestoneContainer: {},
    milestoneKey: 250,
    previewModal: false,
    previewShipmentFile: false,
    previewItem: {},
    previewKey: 1000,
    processingCTO: false,
    quickSelectDates: [
      {
        //yesterday
        text: "Yesterday",
        type: "past",
        value: "yesterday",
        difference: -1,
      },
      {
        text: "Today",
        type: "current",
        value: "today",
        difference: 0,
      },
      {
        text: "Tomorrow",
        type: "future",
        value: "tomorrow",
        difference: 1,
      },
      {
        text: 'Past 7 Days',
        value: 'past_week',
        type: 'past',
        difference: -7
      },
      {
        text: "This week",
        type: "current",
        value: "current_week",
        difference: 0,
      },
    ],

    rules: {
      containerNo(value) {
        // console.log("Testing", value);
        if (/\b[A-Z]{4}\d{7}\b/.test(value)) {
          return true;
        }
        return "Container number needs to be in the format: ABCD1234567";
      },
    },
    milestoneType: null,
    savingBulkStatus: false,
    savingField: false,
    savingRemoveTransporter: false,
    search: null,
    searchKey: 150,
    searchHeaders: {},
    selectAllContainers: false,
    selectedContainers: [],
    selectedDate: null,
    selectedFile: null,
    selectedHeaders: [],
    sendingMessage: false,
    settingsChange: false,
    sortDesc: false,
    sortBy: null,
    bookingType: null,
    receivedDate: '',
    ws: null,
    editETAModal: false,
    selectedPlugInDepot: {},
    etaItem: {},
    confirmContainerDescrModal: false,
    transportStatuses: [
      {
        text: 'On Hold',
        value: 'onHold',
        color: 'brown lighten-1'
      },
      {
        text: 'Breakdown',
        value: 'BREAKDOWN',
        color: 'red'
      },
      {
        text: "Unallocated",
        value: "PENDING",
        color: "orange",
      },
      {
        text: 'Turn Load',
        value: 'turnLoad',
        color: 'yellow'
      },
      // {
      //     text: 'Booking Confirmed',
      //     value: 'BOOKING_CONFIRMED',
      //     color: 'green lighten-1'
      // },
      // {
      //   text: "Pre-Allocated",
      //   value: "PRE_ALLOCATED",
      //   color: "green darken-2",
      // },
      {
        text: "Allocated",
        value: "ALLOCATED",
        color: "lime darken-3",
      },
      {
        text: 'Pre-Loaded',
        value: 'PRE_LOADED',
        color: 'blue-grey darken-2'
      },
      {
        text: 'Collect Tomorrow',
        value: 'COLLECT_TOMORROW',
        color: 'orange darken-2'
      },
      {
        text: 'Collect Later Today',
        value: 'COLLECT_LATER_TODAY',
        color: 'orange darken-2'
      },
      {
        text: "Instr. Sent",
        value: "INSTRUCTION_SENT",
        color: "blue-grey",
      },
      {
        text: "Empty Picked Up",
        value: "GATE_OUT_EMPTY_PICKUP",
        color: "teal",
      },
      {
        text: "En route LP-1",
        value: "EN_ROUTE_LP1",
        color: "cyan darken-2",
      },
      {
        text: "At LP-1",
        value: "AT_LP1",
        color: "cyan darken-4",
      },
      {
        text: "En route LP-2",
        value: "EN_ROUTE_LP2",
        color: "purple darken-2",
      },
      {
        text: "At LP-2",
        value: "AT_LP2",
        color: "purple darken-4",
      },
      {
        text: "En route Gas",
        value: "EN_ROUTE_GAS",
        color: "green lighten-2",
      },
      {
        text: "At Gassing",
        value: "AT_GASSING",
        color: "green lighten-3",
      },
      {
        text: "En route WB",
        value: "EN_ROUTE_WB",
        color: "blue",
      },
      {
        text: "AT WB",
        value: "AT_WB",
        color: "blue lighten-1",
      },
      {
        text: "En route Depot",
        value: "EN_ROUTE_DEPOT",
        color: "blue darken-4",
      },
      {
        text: "At Depot",
        value: "AT_DEPOT",
        color: "blue darken-5",
      },
      {
        text: 'Overnight Genset',
        value: 'OVERNIGHT_GENSET',
        color: '#363062'
      },
      {
        text: 'En route Plug-in',
        value: 'EN_ROUTE_PLUG_IN',
        color: 'blue lighten-2'
      },
      {
        text: 'Plugged-in',
        value: 'PLUG_IN',
        color: 'blue lighten-3'
      },
      {
        text: "En route Port",
        value: "EN_ROUTE_PORT",
        color: "blue darken-1",
      },
      {
        text: "At Port",
        value: "AT_PORT",
        color: "blue darken-2",
      },
      {
        text: "Gate In",
        value: "FULL_DROP_OFF",
        color: "#183A1D",
      },
      {
        text: 'Stacked',
        value: 'STACKED',
        color: 'success'
      },
      {
        text: 'Preadvise Only',
        value: 'preadviseOnly',
        color: 'pink darken-2'
      },
      {
        text: 'Arrange Plugged-In',
        value: 'preadviseOnly',
        color: '#F4CE14'
      },
      {
        text: 'Cancelled',
        value: 'cancelled',
        color: 'red darken-2'
      }
    ],
    pluggedInOptions: [],
    etaTime: null,
    updateFilterValue: 350,
    userLayout: null,
    viewContainer: false,
    viewCTO: false,
    bulkDownloadLoader: false,
    loadingCustomers: false,
    startDate: null,
    endDate: null,
    downloadReport: false,
    loadingTransporters: false,
    transporters: [],
    emailPlanningModal: false,
    emailLoading: false,
    loadSelection: false,
    plugInData: [],
    plugInStartDate: null,
    plugInEndDate: null,
    containerDataUpdate: [],
    globalExcelContainerData: [],
    favPorts: [],
    noPOHTotal: 0,
    noPOHData: [],
    noPOHHeaders: [
      {
        text: 'Cto No.',
        value: 'ctoNo'
      },
      {
        text: 'Shipper',
        value: 'shipperName'
      },
      {
        text: 'Loading date',
        value: 'loadingDate'
      },
      {
        text: 'Loading Point 1',
        value: 'loadingPoint1'
      },
      {
        text: 'Loading Point 2',
        value: 'loadingPoint2'
      },
      {
        text: 'Vessel',
        value: 'vesselName'
      },
      {
        text: 'User',
        value: 'userName'
      }
    ],
    openPOHDialog: false,
  }),
  watch: {
    plugInLayout(newValue) {
    if (newValue) {
      this.isSendButtonEnabled(); // Revalidate when dialog is shown
    }
    },
    filters: {
      deep: true,
      handler() {
        this.initCheckboxModels;
      },
    },
    transporterDay(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.customers = [];
        this.getTransporter();
      }
    },
    selectedDay(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.customers = [];
        this.getCustomers();
      }
    },
    selectedPluggInDepot(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.customers = [];
        this.getPluggedInShippers();
      }
    },
    fileList: {
      immediate: true,
      handler(val) {
        if (val.length > 0) {
          this.submitFiles();
        }
      },
    },
    // selectedDate(val) {
    //   if (val) {
    //     this.setDateRange(val);
    //   }
    // },
    selectAllContainers(val) {
      if (val) {
        this.selectedContainers = this.filteredResults;
      } else {
        this.selectedContainers = [];
      }
    },

  },
  computed: {
    isSendButtonEnabled() {
      const hasValidEmail = !!this.pluggedInEmailAdresses; // Check if email addresses input is not empty
      const containersValid = this.selectedContainers.every(container => {
        return container.containerNo &&
          container.setPointTemp &&
          container.vents &&
          container.contents &&
          container.carrierReferenceNumber &&
          (container.booking && container.booking.vessel) &&
          (container.booking && container.booking.regime);
      });

      return hasValidEmail && containersValid;
    },

    isSwap() {
      return this.containerDescr.some(descr => descr.selectedLoad);
    },
    uniqueShipperLoadCount() {
      const loadCounts = this.transporterLoads.shippers.reduce((acc, shipper) => {
        acc[shipper.name] = acc[shipper.name] || 0;

        shipper.loads.forEach(load => {
          acc[shipper.name] += load.quantity;
        });
        // console.log('acc', acc)
        return acc;
      }, {});

      return loadCounts;
    },
    // selectedCtoGenerated() {
    //   let result = true;
    //   this.selectedContainers.forEach((container) => {
    //     if (!container.ctoDocument) {
    //       result = false;
    //     }
    //   });
    //   return result;
    // },
    uniqueTransporters() {
      const transporterCount = {};

      this.containers.forEach((container) => {
        if (container.transporterName) {
          if (!transporterCount[container.transporterName]) {
            transporterCount[container.transporterName] = {
              text: container.transporterName,
              value: 1,
            };
          } else {
            transporterCount[container.transporterName].value += 1;
          }
        }
      });

      return Object.values(transporterCount);
    },
    uniqueUnallocated() {
      const unallocatedCount = {};

      this.containers.forEach((container) => {
        // console.log('container', container)
        if (container.transportStatus == 'Unallocated') {
          if (!unallocatedCount[container.transportStatus]) {
            unallocatedCount[container.transportStatus] = {
              text: container.transportStatus,
              value: 1,
            };
          } else {
            unallocatedCount[container.transportStatus].value += 1;
          }
        }
      });
      // console.log("unallocatedCount", unallocatedCount)
      return Object.values(unallocatedCount);
    },

    calculatedHeight() {
      let height = window.innerHeight;
      return height * 0.63 + "px";
    },
    dateText() {
      if (this.params.dates.length === 0) {
        return "Select Dates";
      } else if (this.params.dates.length === 1) {
        return this.params.dates[0];
      } else {
        return `${this.params.dates[0]} - ${this.params.dates[1]}`;
      }
    },
    displayData() {
      let result = this.filteredResults;
      if (this.search) {
        // console.log("this.search", this.search);
        result = result.filter(
          (x) =>
            (x.containerNo &&
              x.containerNo
                .toLowerCase()
                .includes(this.search.toLowerCase())) ||
            (x.orderNo &&
              x.orderNo.toLowerCase().includes(this.search.toLowerCase())) ||
            (x.ctoNo &&
              x.ctoNo.toLowerCase().includes(this.search.toLowerCase())) ||
            (x.sealNo &&
              x.sealNo.toLowerCase().includes(this.search.toLowerCase())) ||
            (x.shipperName &&
              x.shipperName
                .toLowerCase()
                .includes(this.search.toLowerCase())) ||
            (x.customerName &&
              x.customerName
                .toLowerCase()
                .includes(this.search.toLowerCase())) ||
            (x.consigneeName &&
              x.consigneeName
                .toLowerCase()
                .includes(this.search.toLowerCase())) ||
            (x.shippingLineName &&
              x.shippingLineName
                .toLowerCase()
                .includes(this.search.toLowerCase())) ||
            (x.vesselVoyage &&
              x.vesselVoyage
                .toLowerCase()
                .includes(this.search.toLowerCase())) ||
            (x.sealNo &&
              x.sealNo.toLowerCase().includes(this.search.toLowerCase())) ||
            (x.emptyPickupPoint &&
              x.emptyPickupPoint
                .toLowerCase()
                .includes(this.search.toLowerCase())) ||
            (x.firstLoadingPoint &&
              x.firstLoadingPoint
                .toLowerCase()
                .includes(this.search.toLowerCase())) ||
            (x.secondLoadingPoint &&
              x.secondLoadingPoint
                .toLowerCase()
                .includes(this.search.toLowerCase())) ||
            (x.dropOffPoint &&
              x.dropOffPoint
                .toLowerCase()
                .includes(this.search.toLowerCase())) ||
            (x.customerContainerRef &&
              x.customerContainerRef
                .toLowerCase()
                .includes(this.search.toLowerCase())) ||
            (x.carrierReferenceNumber &&
              x.carrierReferenceNumber
                .toLowerCase()
                .includes(this.search.toLowerCase())) ||
            (x.containerTypeCode &&
              x.containerTypeCode
                .toLowerCase()
                .includes(this.search.toLowerCase())) ||
            (x.regimeCode &&
              x.regimeCode.toLowerCase().includes(this.search.toLowerCase())) ||
            (x.portOfLoadValue &&
              x.portOfLoadValue
                .toLowerCase()
                .includes(this.search.toLowerCase())) ||
            (x.portOfDischargeValue &&
              x.portOfDischargeValue
                .toLowerCase()
                .includes(this.search.toLowerCase()))
        );
      }
      if (this.sortBy) {
        result = result.sort((a, b) => {
          if (this.sortDesc) {
            return a[this.sortBy] < b[this.sortBy] ? 1 : -1;
          } else {
            return a[this.sortBy] > b[this.sortBy] ? 1 : -1;
          }
        });
      }
      return result;
    },
    tableWidth() {
      let result = 0;
      this.selectedHeaders.forEach((x) => {
        result += parseInt(x.width ? x.width.replace("px", "") : 150);
      });
      return result + "px";
    },
  },
  created() {
    this.initExpandedShippers();
    // this.getContainers()
    if (localStorage.transportParams) {
      this.params = JSON.parse(localStorage.transportParams);
      if (!this.params.dateType) {
        this.params.dateType = ["LOADING_POINT_1", "LOADING_POINT_2", "FULL_PICKUP","DELIVERY_POINT_1"]
      }
    }
    if (!this.params) {
      this.params = {
        dateType: ["LOADING_POINT_1", "LOADING_POINT_2", "FULL_PICKUP","DELIVERY_POINT_1"],
        selectedDate: "current_week",
        dates: [
          new Date(
            new Date().setDate(new Date().getDate() - new Date().getDay() + 1)
          )
            .toISOString()
            .substring(0, 10),
          new Date(
            new Date().setDate(new Date().getDate() - new Date().getDay() + 7)
          )
            .toISOString()
            .substring(0, 10),
        ]
      };
    }

  },
  beforeCreate() {
    if (localStorage.transportParams) {
      this.params = JSON.parse(localStorage.transportParams);
      if (!this.params.dateType) {
        this.params.dateType = ["LOADING_POINT_1", "LOADING_POINT_2", "FULL_PICKUP",'DELIVERY_POINT_1']
      }
    }

  },
  mounted() {
    // this.connectWebSocket();

    this.getContainers()
    this.getPluggins()
    this.getUserLayout();
    this.resetFilters();
    this.initCheckboxModels();
    this.getUserFavPorts()
    this.getContainerWithNoPOH()

  },
  beforeDestroy() {
    if (this.ws) {
      this.ws.close();
    }
  },
  methods: {
    async createPlugMail() {
      this.emailLoading = true
      let data = {
        depot: this.selectedPlugInDepot.friendlyName ? this.selectedPlugInDepot.friendlyName :
          this.selectedPlugInDepot.name,
        emailAddresses: this.pluggedInEmailAdresses,
        // ccEmailAddresses: localStorage.getItem('intermodal_plug_cc_email_2507'),
        ccAddresses: this.ccPluggedInAddr,
        replyAdresses: this.replyPluggedInAddr,
        containers: this.selectedContainers,
        title: this.formatDate(this.selectedPlugInDate),
        
      }
      let result = await this.$API.createPluggedInMail(data)
      this.emailLoading = false
      this.$message.success('Email sent successfully')
      this.closePlugInLayout()
    },
    closePlugInDialog() {
      this.selectedContainers = []
      this.selectedPlugInDepot = {}
      this.selectedPlugInDate = null
      this.pluggedInEmailAdresses = []
      this.plugInDialog = false
    },
    closePlugInLayout() {
      this.selectedContainers = []
      this.selectedPlugInDepot = {}
      this.pluggedInEmailAdresses = []
      this.selectedPlugInDate = null
      this.plugInLayout = false
    },
    async pluggedInMail() {
      this.pluggedInOptions = await this.$API.getPluggedInDepots()
      this.plugInDialog = true
    },
    async plugInMail() {
      console.log('container', this.selectedContainers)
    },
    async getUserFavPorts() {
      let ports = await this.$API.getFavouritePorts();
      ports.forEach(x => {
        let obj = {
          unLocode: x.unLocode.id,
          locode: x.unLocode.locode,
          name: x.unLocode.name
        }
        this.favPorts.push(obj)
      })
    },
    async updateContainerDescr() {
      console.log('update container descr', this.containerDescr)
      for (let x = 0; x < this.containerDescr.length; x++) {
        if (this.containerDescr[x].selectedLoad) {
          this.containerDescr[x].existingContainer.containerNo = this.containerDescr[x].importContainer.containerNo
        }
        //add this to containerDataUpdate
        this.containerDataUpdate.push(this.containerDescr[x].existingContainer)
        //update bookingContainer
        await this.$API.updateBookingContainerNo(this.containerDescr[x].existingContainer)
      }
      this.confirmContainerDescrModal = false
      let excel = this.globalExcelContainerData
      let result = this.containerDataUpdate
      excel.forEach((x) => {
        x.created = result.find((y) => y.ctoNo == x.ctoNo) ? true : false;
      });
      this.bulkUpdateData = excel;
      this.bulkUpdateModal = true;
      for (let i = 0; i < result.length; i++) {
        let findIndex = this.containers.find((x) => x.id == result[i].id);
        if (findIndex > -1) {

          this.containers[findIndex] = {
            ...this.containers[findIndex],
            ...result[i],
          };
        }
      }
      this.$message.success("Successfully uploaded template data!");
      this.getContainers();
    },
    initCheckboxModels() {
      this.headers.forEach(header => {
        this.$set(this.checkboxModels, header.value, {});
        this.headerOptions(header.value).forEach(item => {
          this.$set(this.checkboxModels[header.value], item.value, this.filters[header.value].includes(item.value));
        });
      });
    },
    isFiltered(value, field) {
      return this.filters[field].includes(value);
    },
    toggleFilter(value, field) {
      const index = this.filters[field].indexOf(value);
      if (index >= 0) {
        this.filters[field].splice(index, 1);
      } else {
        this.filters[field].push(value);
      }
      let keys = Object.keys(this.filters);
      let result = [];
      keys.forEach((x) => {
        result = [
          ...result,
          ...this.filters[x].map((y) => ({
            field: x,
            fieldName: this.headers.find((z) => z.value == x).text,
            value: y,
          })),
        ];
      });
      this.filteredValues = result;
      this.selectAllContainers = false;
      this.selectedContainers = [];
      this.calculateFilteredResults();
    },

    async getPluggedInShippers() {
      this.loadingCustomers = true
      this.customers = await this.$API.obtainIntermodalShippers({
        pluggedInDepotId: this.selectedPluggInDepot.poiId,
      })
      this.loadingCustomers = false
    },
    constructEta(milestones, status) {

      if (status === 'En route LP-1') {
        return milestones.find(milestone => milestone.type === 'EN_ROUTE_LP1')?.time
      }
      else if (status === 'En route LP-2') {
        return milestones.find(milestone => milestone.type === 'EN_ROUTE_LP2')?.time
      }
      else if (status === 'En route WB') {
        return milestones.find(milestone => milestone.type === 'EN_ROUTE_WB')?.time
      }
      else if (status === 'En route Depot') {
        return milestones.find(milestone => milestone.type === 'EN_ROUTE_DEPOT')?.time
      }
      else if (status === 'En route Port') {
        return milestones.find(milestone => milestone.type === 'EN_ROUTE_PORT')?.time
      }
      else if (status === 'En route Gas') {
        return milestones.find(milestone => milestone.type === 'EN_ROUTE_PORT')?.time
      }
      else if (status === 'En route Plug-in') {
        return milestones.find(milestone => milestone.type === 'EN_ROUTE_PORT')?.time
      }
      else {
        return null
      }
    },
    getGassingDescription(item) {
      let gassingMilestone = item.containerMilestones.find(milestone => milestone.type === 'GASSING');
      return item.booking.caProduct + (gassingMilestone ? ' : ' + gassingMilestone.description : '');
    },

    // connectWebSocket() {
    //   // Replace with your WebSocket server URL
    //   this.ws = new WebSocket('wss://your-websocket-server.com');

    //   this.ws.onmessage = (event) => {
    //     const message = JSON.parse(event.data);

    //     // Assuming the message format is { event: 'loadingPoint1', date: 'your-date' }
    //     if (message.event === 'loadingPoint1') {
    //       this.receivedDate = message.date;
    //     }
    //   };

    //   this.ws.onerror = (error) => {
    //     console.error('WebSocket Error:', error);
    //   };

    //   this.ws.onclose = () => {
    //     console.log('WebSocket connection closed');
    //     // Optionally implement reconnection logic
    //   };
    // },


    async getIntermodalData() {
      let result = await this.$API.getIntermodalDashData({
        isoWeek: 46
      })
      // console.log('result dash', result)
    },
    async getPluggins() {
      // console.log('htting plug ins')
      let response = await this.$API.getPluggedContainers()
      this.plugInData = response.totals
      this.pluggedInDepots = response.totals.map((item) => {
        return {
          text: item.text,
          poiId: item.poiId
        }
      })
      this.plugInStartDate = response.startDate
      this.plugInEndDate = response.endDate

    },
    toggleLoadSelection(item) {
      item.selectedLoad = !item.selectedLoad;
      this.transporterLoads = [...this.transporterLoads];
    },
    toggleContainerDescrSelection(item) {
      item.selectedLoad = !item.selectedLoad;

    },
    initExpandedShippers() {
      this.expandedShippers = {};
      if (this.transporterLoads.length > 0) {
        this.transporterLoads.shippers.forEach((_, index) => {
          this.$set(this.expandedShippers, index, true); // Start with all shippers expanded
        });
      }
    },
    toggleShipper(index) {
      this.$set(this.expandedShippers, index, !this.expandedShippers[index]);
    },

    exitEmailPlanning() {
      this.transporterLoads = []
      this.searchTransporter = null
      this.transporterDay = null
      this.emailPlanningModal = false
    },
    cancelTransportModal() {
      this.transporterLoads = []
      this.searchTransporter = null
      this.transporterDay = null
      this.transporterReportModal = false

    },
    async submitEmail() {
      this.emailLoading = true
      // this.transporterLoads.ccAddresses = localStorage.getItem('intermodal_cto_cc_email_2507')
      
      let res = await this.$API.sentTransportCTO(this.transporterLoads)
      if (res) {
        let updateTransporterStatuses = await this.$API.updateTransporterStatuses({
          containerIds: this.transporterLoads.containers,
          transportStatus: 'Instr. Sent'
        })
        this.$message.success("Email send!");
      }
      else {
        this.$message.success("Error sending email!");
      }
      this.emailLoading = false
      this.transporterLoads = []
      this.searchTransporter = null
      this.transporterDay = null
      this.loadSelection = false
      this.emailPlanningModal = false
    },
    formatTime(date) {
      // console.log('datae', date)
      return moment(date).format("HH:mm");
    },

    convertDate(date) {
      return moment(date).format("DD/MM/YYYY HH:mm");
    },
    getProductNames(containerProducts) {
      return containerProducts.map(p => p.product.name).join(', ');
    },
    avoBox(containerProducts) {
      let productIds = [...new Set(containerProducts.map(p => p.productId))];
      //check if any productId === 226
      if (productIds.includes(226)) {
        return true
      }
      else {
        return false
      }
    },
    async submitLoadSelection() {
      this.loadingTransporterLoads = true
      let uniqueIds = this.transporterSelectionLoads.filter((load) => load.selectedLoad).map((load) => load.id)
      let response = await this.$API.obtainIntermodalTransporterLoads({
        transporterId: this.searchTransporter.id,
        bookingContainerIds: uniqueIds,
        startDate: this.startDate,
        endDate: this.endDate,
      })
      this.transporterLoads = response
      this.emailPlanningModal = true
      this.loadingTransporterLoads = false
    },
    async getTransporterLoad() {
      this.loadingTransporterLoads = true


      if (this.transportStatusSelected === 'Allocated') {
        let response = await this.$API.obtainIntermodalTransporterLoads({
          transporterId: this.searchTransporter.id,
          transportStatus: this.transportStatusSelected,
          startDate: this.startDate,
          endDate: this.endDate,
        })
        this.transporterLoads = response
        this.emailPlanningModal = true
      }
      else {
        let response = await this.$API.getIntermodalTransporterSelectionLoads({
          transporterId: this.searchTransporter.id,
          startDate: this.startDate,
          endDate: this.endDate,
        })
        // console.log('response', response)
        this.transporterSelectionLoads = response
        //for each transporterSelectionLoads add selectedLoad = false
        this.transporterSelectionLoads.forEach((load) => {
          load.selectedLoad = false
        })
        this.loadSelection = true
      }
      this.loadingTransporterLoads = false
      this.transporterReportModal = false
    },
    async getPluggedInReport() {
      this.downloadReport = true;
      let query = {
        dateSelectionType: this.dateSelection,
        pluggedInDates: this.dateSelection === 'default' ? [] : this.pluggedInDates,
        pluggedInDepotId: this.selectedPluggInDepot.poiId,
        pluggedDepotName: this.selectedPluggInDepot.text,
      }
      if (this.searchCustomer) {
        query.shipperId = this.searchCustomer.id
      }
      let response = await this.$API.getShipperPluggedIn(query)
      if (response && response.dataUri) {
        const link = document.createElement('a');
        link.href = response.dataUri;
        if (this.searchCustomer) {
          link.setAttribute('download', `${this.searchCustomer.name} Plugged In Report.pdf`);
        }
        else {
          link.setAttribute('download', `${this.selectedPluggInDepot.text} Plugged In Report.pdf`);
        }
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      } else {
        console.error('No data received from server');
      }
      this.downloadReport = false;
      this.resetPluggedInFrom();
    },
    async getShipperReport() {
      this.downloadReport = true;

      let response = await this.$API.getShipperLoads({
        shipperId: this.searchCustomer.id,
        startDate: this.startDate,
        endDate: this.endDate,
        longDistance: this.longDistance,
        movementType: this.movementType,
        selectedPort: this.selectedPort,
        // fileType: this.fileType
      });

      //TEMP commented out so that intermodal can have report in excel
      // if(this.fileType === 'excel'){
      //     if (response && response.dataUri) {
      //     const link = document.createElement('a');
      //     link.href = response.dataUri;
      //     link.setAttribute('download', `${this.searchCustomer.name} Report ${response.date}.pdf`);
      //     document.body.appendChild(link);
      //     link.click();
      //     document.body.removeChild(link);
      //   } else {
      //     console.error('No data received from server');
      //   }
      // }

      // else if(this.fileType==='pdf'){
      //   //TEMP excel doc download for now
      //   if (response && response.dataUri) {
      //     const link = document.createElement('a');
      //     link.href = response.dataUri;
      //     link.setAttribute('download', `${this.searchCustomer.name} Report ${response.date}.xlsx`);
      //     document.body.appendChild(link);
      //     link.click();
      //     document.body.removeChild(link);
      //   } else {
      //     console.error('No data received from server');
      //   }
      // }

      // else{
      //   console.log('No file type selected')
      // }
      if (response && response.dataUri) {
        const link = document.createElement('a');
        link.href = response.dataUri;
        link.setAttribute('download', `${this.searchCustomer.name} Report ${response.date}.xlsx`);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      } else {
        console.error('No data received from server');
      }
      this.resetForm();
    },

    resetForm() {
      this.startDate = null;
      this.endDate = null;
      this.selectedDay = null;
      this.searchCustomer = null;
      this.downloadReport = false;
      this.clientReportModal = false;
    },
    resetPluggedInFrom() {
      this.searchCustomer = null;
      this.selectedPluggInDepot = null
      this.pluggedInReport = false
    },
    //     async getShipperReport() {
    //     this.downloadReport = true;

    //     try {
    //         let response = await this.$API.getShipperLoads({
    //             shipperId: this.searchCustomer.id,
    //             startDate: this.startDate,
    //             endDate: this.endDate,
    //         }, { responseType: 'blob' }); // Set responseType to 'blob'

    //         if (response) {
    //             // Directly using response as it's expected to be a blob now
    //             const url = window.URL.createObjectURL(response);
    //             const link = document.createElement('a');
    //             link.href = url;
    //             link.setAttribute('download', `${this.searchCustomer.name} Report.xlsx`);
    //             document.body.appendChild(link);
    //             link.click();
    //             document.body.removeChild(link);
    //         } else {
    //             console.error('No response received from the server.');
    //         }
    //     } catch (error) {
    //         console.error('Error while downloading report:', error);
    //     } finally {
    //         this.resetForm();
    //     }
    // },

    async getTransporter() {
      this.loadingTransporters = true
      if (this.transporterDay == 'Yesterday') {
        // loadingDate = moment().subtract(1, 'days').format('YYYY-MM-DD')
        this.startDate = moment().subtract(2, 'days').format('YYYY-MM-DD')
        this.endDate = moment().format('YYYY-MM-DD')
      }
      if (this.transporterDay == 'Today') {
        this.startDate = moment().subtract(1, 'days').format('YYYY-MM-DD')
        this.endDate = moment().add(1, 'days').format('YYYY-MM-DD')

      }
      if (this.transporterDay == 'Tomorrow') {
        this.startDate = moment().format('YYYY-MM-DD')
        this.endDate = moment().add(2, 'days').format('YYYY-MM-DD')
      }
      if (this.transporterDay == "2-Days") {
        this.startDate = moment().add(1, 'days').format('YYYY-MM-DD')
        this.endDate = moment().add(3, 'days').format('YYYY-MM-DD')
      }
      if (this.transporterDay == '3-Days') {
        this.startDate = moment().add(2, 'days').format('YYYY-MM-DD')
        this.endDate = moment().add(4, 'days').format('YYYY-MM-DD')
      }
      // console.log('start', this.startDate)
      // console.log('end', this.endDate)

      this.transporters = await this.$API.obtainIntermodalTransporters({
        startDate: this.startDate,
        endDate: this.endDate,
      })
      this.loadingTransporters = false
    },
    async getCustomers() {
      this.loadingCustomers = true

      if (this.selectedDay == 'Yesterday') {
        // loadingDate = moment().subtract(1, 'days').format('YYYY-MM-DD')
        this.startDate = moment().subtract(2, 'days').format('YYYY-MM-DD')
        this.endDate = moment().format('YYYY-MM-DD')
      }
      if (this.selectedDay == 'Yesterday') {
        this.startDate = moment().subtract(2, 'days').format('YYYY-MM-DD')
        this.endDate = moment().format('YYYY-MM-DD')
      }
      if (this.selectedDay == 'Today') {
        this.startDate = moment().subtract(1, 'days').format('YYYY-MM-DD')
        this.endDate = moment().add(1, 'days').format('YYYY-MM-DD')

      }
      if (this.selectedDay == 'Tomorrow') {
        this.startDate = moment().format('YYYY-MM-DD')
        this.endDate = moment().add(2, 'days').format('YYYY-MM-DD')

      }
      this.customers = await this.$API.obtainIntermodalShippers({
        startDate: this.startDate,
        endDate: this.endDate
      })
      this.loadingCustomers = false
    },
    async processMissingCTO() {
      this.processingCTO = true;
      let missingCto = this.selectedContainers
      for (let i = 0; i < missingCto.length; i++) {
        let result = await this.$API.generateCTO({
          containerId: missingCto[i].id,
          movementType: missingCto[i].booking.movementType,
        });
        if (result && result.id) {
          missingCto[i].ctoDocument = result;
          missingCto[i].ctoDocumentId = result.id;
        } else if (result && result.message) {
          missingCto[i].errorMessage = result.message;
        }

        this.ctoUpdateKey++;
      }
      this.processingCTO = false;
      // console.log('done with function')
    },
    async bulkDownloadCTO() {
      this.bulkDownloadLoader = true;
      // if (!this.selectedCtoGenerated) {
      //   await this.processMissingCTO()
      // }
      await this.processMissingCTO()
      // console.log('done')
      const combinedPdf = await PDFDocument.create();

      for (let x = 0; x < this.selectedContainers.length; x++) {
        const pdfBytes = await fetch(
          this.selectedContainers[x].ctoDocument.url
        ).then((res) => res.arrayBuffer());
        const pdfDoc = await PDFDocument.load(pdfBytes);

        const pages = Array.from(
          { length: pdfDoc.getPageCount() },
          (_, i) => i
        );
        const copiedPages = await combinedPdf.copyPages(pdfDoc, pages);

        for (const page of copiedPages) {
          combinedPdf.addPage(page);
        }
      }

      const combinedPdfBytes = await combinedPdf.save();

      // Trigger download for the combined PDF
      const blob = new Blob([combinedPdfBytes], { type: "application/pdf" });
      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.download = "combined_cto.pdf";
      link.click();
      this.bulkDownloadLoader = false;
      this.bulkDownload = false;
    },

    getFilteredTransportStatuses(item) {
      // console.log('item filter status', item)
      let filteredStatuses = this.transportStatuses;

      if (item.isCarrierHaulage || item.privateLoad) {
        filteredStatuses = filteredStatuses.filter(
          (status) =>
            status.value == "preadviseOnly"
        );
      }
      //want to check user organisation
      //if user organisation is not the same as the transport coordinator id then remove preadviseOnly from list

      if (item.isPrivateHaulage && item.privateLoad) {
        filteredStatuses = filteredStatuses.filter(
          (status) =>
            status.value !== "preadviseOnly"
        );
      }
      else {
        //remove preadviseOnly from list
        filteredStatuses = filteredStatuses.filter(
          (status) =>
            status.value !== "preadviseOnly"
        );
      }

      if (!item.LOADING_POINT_2_DESCRIPTION) {
        filteredStatuses = filteredStatuses.filter(
          (status) =>
            status.value !== "EN_ROUTE_LP2" && status.value !== "AT_LP2"
        );
      }
      if (item.verificationMethod === "Method 2") {
        filteredStatuses = filteredStatuses.filter(
          (status) =>
            status.value !== "AT_WB" && status.value !== "EN_ROUTE_WB"
        );
      }
      if (item.containerNo && item.sealNo) {
        filteredStatuses = filteredStatuses.filter(
          (status) =>
            status.value !== "PENDING" && status.value !== "ALLOCATED" && status.value !== "INSTRUCTION_SENT" && status.value !== 'GATE_OUT_EMPTY_PICKUP' && status.value !== "preadviseOnly"
        );
      }
      if (item.transportStatus === 'Cancelled') {
        filteredStatuses = filteredStatuses.filter(
          (status) =>
            status.value == "cancelled"
        );
      }
      else {
        filteredStatuses = filteredStatuses.filter(
          (status) =>
            status.value !== "cancelled"
        );
      }
      return filteredStatuses;
    },

    async addMilestone(item, type = null, autosave = true) {
      if (type && item[type]) {
        // console.log('item addMilestone', item)
        this.milestoneItem = item[type];
        this.milestoneItem.autosave = autosave;
        this.milestoneContainer = item;
        this.milestoneItem.date = null
      } else {
        this.milestoneItem = {
          type,
          bookingContainerId: item.id,
          pol: item.portOfLoadValue,
          date: null,
          autosave,
          isActual: false,
        };
        this.milestoneContainer = item;
      }
      this.bookingType = item.booking.movementType;
      this.milestoneType = type

      this.addMilestoneModal = true;
      this.$nextTick(() => {
        this.milestoneKey++;
      });
    },

    async addPlugInData(data) {
      this.loading = true;
      const isAnyIdSelected = data.bookingContainerIds.some(id => this.selectedBookingContainerIds.includes(id));
      if (isAnyIdSelected) {
        this.selectedBookingContainerIds = this.selectedBookingContainerIds.filter(id => !data.bookingContainerIds.includes(id));
      } else {
        this.selectedBookingContainerIds = [...this.selectedBookingContainerIds, ...data.bookingContainerIds];
      }
      let incomingData = { bookingContainerIds: this.selectedBookingContainerIds, dateType: 'PLUG_IN' };
      this.containers = await this.$API.getPluggInContainers(incomingData);
      this.calculateFilteredResults();
      this.loading = false;
    },

    addToFilter(value, field) {

      // console.log(value, field);
      if (this.filters[field].includes(value)) {
        this.filters[field] = this.filters[field].filter((x) => x != value);
      } else {
        this.filters[field].push(value);
      }
      this.updateFilterValue++;
      let keys = Object.keys(this.filters);
      let result = [];
      keys.forEach((x) => {
        result = [
          ...result,
          ...this.filters[x].map((y) => ({
            field: x,
            fieldName: this.headers.find((z) => z.value == x).text,
            value: y,
          })),
        ];
      });
      this.filteredValues = result;
      this.selectAllContainers = false;
      this.selectedContainers = [];
      this.calculateFilteredResults();
    },
    assignContainers(containers) {
      this.selectedContainers = containers.filter((container) => !container.isCarrierHaulage && !container.privateLoad)
      // this.selectedContainers = containers;
      this.assignToView = true;
    },
    async removeContainerTransporter(item) {
      console.log('remove container transporter', item)
      item.transporter = null;
      item.transporterId = null;
      item.transportStatus = "Unallocated";
      await this.$API.updateContainer({
        intermodalUpdate: true,
        id: item.id,
        transportStatus: "Unallocated",
        transporter: null,
        transporterId: null,
      });
      await this.updateContainer(item.id)
      this.$message.success("Successfully removed transporter!");
    },
    async removeDepotInDate(item) {
      item.PLUG_IN_DATE = null

    },
    async updateContainer(id) {
      let index = this.containers.findIndex(x => x.id == id)
      if (index > -1) {
        this.containers[index].transporterName = null
        this.containers[index].transporterId = null
        this.containers[index].transportStatus = 'Unallocated'
      }
    },
    async updatePlugIn(id) {

    },
    async removeTransporter() {
      this.savingRemoveTransporter = true;
      let data = this.selectedContainers.map((x) => ({
        id: x.id,
        transportStatus: "Unallocated",
        transporter: null,
        transporterId: null,
      }));
      for (let i = 0; i < data.length; i++) {
        await this.$API.updateContainer(data[i]);
      }
      this.savingRemoveTransporter = false;
      this.confirmRemoveTransporter = false;
      this.$message.success("Successfully removed allocated transporter!");
      this.selectedContainers = [];
      this.getContainers();
    },
    bulkUploadCTO() {
      this.$refs.uploader.$el.children[0].click();
    },
    calculateFilteredResults() {
      let result = [...this.containers];
      let keys = Object.keys(this.filters);
      for (let i = 0; i < keys.length; i++) {
        let key = keys[i];
        if (key != "contents") {
          if (this.filters[key] && this.filters[key].length > 0) {
            result = result.filter((x) => this.filters[key].includes(x[key]));
          }
        } else {
          if (this.filters[key] && this.filters[key].length > 0) {
            result = result.filter((x) =>
              x[key].some((y) => this.filters[key].includes(y))
            );
          }
        }
      }
      this.filteredResults = result;
      let statuses = [
        ...new Set(this.filteredResults.map((x) => x.transportStatus)),
      ];
      // this.availableTransportStatuses = this.transportStatuses.filter((x) =>
      //   statuses.includes(x.text)
      // );
      this.selectAllContainers = false;
    },
    changeDateType() {
      if (this.dateType == "ETD") {
        this.dateType = "Loading Date";
      } else {
        this.dateType = "ETD";
      }
      this.getContainers();
    },
    changeSortBy(value) {
      if (this.sortBy == value) {
        if (this.sortDesc) {
          this.sortBy = null;
          this.sortDesc = false;
        } else {
          this.sortDesc = true;
        }
      } else {
        this.sortBy = value;
        this.sortDesc = false;
      }
      this.selectAllContainers = false;
      this.selectedContainers = [];
    },
    async getContainers() {
      // console.log('hitting get containers')
      if (this.containerTimeout) {
        clearTimeout(this.containerTimeout);
      }
      if (this.loading && this.containerQuery) {
        this.containerQuery.abort();
      }
      this.loading = true;
      let params = { ...this.params };

      this.params.dateType = this.milestoneTypes.find(
        (x) => x.name == this.dateType
      ).value;
      if (this.params.dates.length == 1) {
        params.dates = [
          moment(this.params.dates[0])
            .startOf("isoWeek")
            .toISOString()
            .substring(0, 10),
          moment(this.params.dates[0])
            .endOf("isoWeek")
            .toISOString()
            .substring(0, 10),
        ];
      }
      this.containerTimeout = setTimeout(async () => {
        this.containerQuery = new AbortController();
        const signal = this.containerQuery.signal;
        // console.log('signal', signal)
        // console.log('params', this.params)
        this.containers = await this.$API.getBookingTransportContainerOverview({
          params: this.params,
          signal,
        });
        if (this.containers.length > 0) {
          this.containers.forEach((container) => {
            if (container.isCarrierHaulage || container.privateLoad && !container.pluggedInRequired) {
              container.transportStatus = 'Preadvise Only'
            }
            else if (container.isCarrierHaulage || container.privateLoad && container.pluggedInRequired) {
              container.transportStatus = 'Arrange Plugged-In'
            }
          })
        }
        // console.log('containers', this.containers)
        this.calculateFilteredResults();
        this.loading = false;
      }, 750);
    },
    async getContainerWithNoPOH() {
      let result = await this.$API.getContainersNoPOH()
      this.noPOHTotal = result.total
      this.noPOHData = result.data

    },
    containerStatusIcon(item) {
      if (item.isDeleted) {
        return "cancel";
      } else if (item.status) {
        let find = this.containerStatus.find(
          (x) => x.name.toLowerCase() == item.status.toLowerCase()
        );
        if (find) {
          return find.icon;
        } else {
          return "warning";
        }
      } else {
        return "warning";
      }
    },
    containerStatusColor(item) {
      if (item.isDeleted) {
        return "red";
      } else if (item.status) {
        let find = this.containerStatus.find(
          (x) => x.name.toLowerCase() == item.status.toLowerCase()
        );
        if (find) {
          return find.color;
        } else {
          return "orange darken-2";
        }
      } else {
        return "orange darken-2";
      }
    },
    formatDate(date) {
      let result = null;
      if (date) {
        result = dateFormat(new Date(date), "dd-mm-yyyy");
      }
      return result;
    },
    formatReeferContainer(type){
      return type.includes("RF","Reefer");
    },
    formatCommentDateTime(date) {
      let result = null;
      if (date) {
        result = dateFormat(new Date(date), "dd-mm-yyyy HH:MM");
      }
      return result;
    },
    formatDateTime(date, item = null) {
      // console.log(item)
      let result = null;
      if (date) {
        result = dateFormat(new Date(date), "dd-mm-yyyy");
        if (item && item.time) {
          result += " " + item.time;
        }
      }
      return result;
    },
    async commentContainer(container) {
      if (this.containerChatArea) {
        this.sendingMessage = true;
        let obj = {
          comment: this.containerChatArea,
          bookingContainerId: container.id,
          user: this.$store.state.user,
        };
        let result = await this.$API.commentOnContainer(obj);
        if (result) {
          result.user = this.$store.state.user;
          result.user.firstName = this.$store.state.user.firstname;
          container.containerComments.push(result);
          container.hasComments = true;
          this.containerChatArea = "";
          this.sendingMessage = false;
        } else {
          this.$message({
            type: "error",
            message: `Message could not go through. Please check your connection and try again.`,
          });
        }
      }
    },
    csvJSON(csv) {
      var lines = csv.split("\n");
      var result = lines.map((x) => x.split(",").filter((y) => y != "\r"));
      result = result.filter((x) => x.length > 0);
      // console.log(result);
    },
    async excelJson(excel) {
      // console.log(excel);
      this.bulkUpdateData = [];
      excel = excel.filter((x, index) => x.length > 0 && index != 0);
      excel = excel.map((x) => ({
        ctoNo: x[0] ?? null,
        containerNo: x[1] ?? null,
        sealNo: x[2] ?? null,
        tareWeight: x[3] ?? null,
        tempSerialNo: x[4] ?? null,
      }));
      let result = await this.$API.bulkUploadCTO(excel);
      if (result.bookingContainerGroup.length > 0) {
        this.containerDescr = result.bookingContainerGroup
        this.containerDataUpdate = result.create
        this.globalExcelContainerData = excel
        this.confirmContainerDescrModal = true
      }
      else {
        result = result.create
        excel.forEach((x) => {
          x.created = result.find((y) => y.ctoNo == x.ctoNo) ? true : false;
        });
        this.bulkUpdateData = excel;
        this.bulkUpdateModal = true;
        for (let i = 0; i < result.length; i++) {
          // console.log('result returning', result[i])
          let findIndex = this.containers.find((x) => x.id == result[i].id);
          if (findIndex > -1) {

            this.containers[findIndex] = {
              ...this.containers[findIndex],
              ...result[i],
            };
            // console.log(this.containers[findIndex]);
          }
        }
        this.$message.success("Successfully uploaded template data!");
        this.getContainers();
      }
      // this.calculateFilteredResults()
    },
    async generateCTO(id, container) {
      this.chosenContainer = container;
      this.viewCTO = true;
      this.loadingCto = true;
      let obj = {
        containerId: id,
        movementType: container.booking.movementType,
      };
      let result = await this.$API.generateCTO(obj);
      if (result.error) {
        this.$message.error(result.error)
        this.loadingCto = false;
      }
      else {
        this.previewItem = result;
        this.loadingCto = false;
      }

    },

    async downloadDocument(doc) {
      var hiddenElement = document.createElement("a");
      hiddenElement.href = doc.url;
      hiddenElement.target = "_blank";
      hiddenElement.download = doc.name;
      hiddenElement.click();
    },
    editField(type, container, field) {
      this.fieldItem = {
        type: type,
        container: container,
        value: container[field],
        field: field,
        name: this.headers.find((x) => x.value == field).text,
      };
      this.editFieldModal = true;
    },
    async getCTO(container) {
      this.previewItem = container.ctoDocument;
      this.previewModal = true;
    },
    async getContainerComments(container) {
      this.loadingComments = true;
      let result = await this.$API.getContainerComments(container.id);
      if (result) {
        container.containerComments = result;
      }
      this.loadingComments = false;
    },
    async getUserLayout() {
      this.selectedHeaders = this.headers.filter((x) => x.show == true);
      this.userLayout = await this.$API.getUserTableLayout({
        params: {
          module: "Intermodal",
        },
      });
      if (this.userLayout && this.userLayout.config) {
        this.selectedHeaders = this.userLayout.config.filter((x) =>
          this.headers.map((y) => y.value).includes(x.value)
        );
        this.headers.forEach((x) => {
          x.show = this.selectedHeaders.map((y) => y.value).includes(x.value);
        });
      }
      //Default layout
      else {
        let layout = await this.$API.getDefaultTableLayout({
          params: {
            module: "Intermodal",
          },
        });
        this.selectedHeaders = layout.config.filter((x) =>
          this.headers.map((y) => y.value).includes(x.value)
        );
        this.headers.forEach((x) => {
          x.show = this.selectedHeaders.map((y) => y.value).includes(x.value);
        });

        // this.selectedHeaders = this.headers.filter(x=>x.show == true);
      }
    },
    getStatusColor(status) {
      let find = this.transportStatuses.find((x) => x.text == status);
      return find ? find.color : "grey";
    },
    handleResize(event) {
      // console.log(event);
    },
    headerOptions(value) {
      if (value != "contents") {
        let result = [
          ...new Set(
            this.containers
              // .filter(x => x[value])
              .map((x) => ({ name: x[value] ?? "(Blank)", value: x[value] }))
          ),
        ];
        let uniqueValues = [...new Set(result.map((x) => x.value))];
        if (this.searchHeaders[value]) {
          uniqueValues = uniqueValues.filter(
            (x) =>
              x &&
              x.toLowerCase().includes(this.searchHeaders[value].toLowerCase())
          );
        }
        result = uniqueValues.map((x) => result.find((y) => y.value === x));
        if (value == "status") {
          result.push({ name: "Unallocated", value: null });
        }
        result.sort((a, b) => (a.name > b.name ? 1 : b.name > a.name ? -1 : 0));

        return result;
      } else {
        let products = [
          ...new Set(
            [].concat.apply(
              [],
              this.containers.map((x) => x.contents)
            )
          ),
        ];
        let result = products.map((x) => ({ name: x, value: x }));
        result.sort((a, b) => (a.name > b.name ? 1 : b.name > a.name ? -1 : 0));
        return result;
      }
    },
    async updateMilestoneDate(containerMilestone) {
      // console.log('containerMilestone', containerMilestone)
      containerMilestone.date = null
      let bookingContainerId = containerMilestone.bookingContainerId
      await this.$API.createMilestone(containerMilestone)
      await this.updateContainerPlugin(bookingContainerId)
      await this.getPluggins()
      //update this.containers.bookingContainer
    },
    async updateContainerPlugin(bookingContainerId) {
      let index = this.containers.findIndex(x => x.id == bookingContainerId)
      if (index > -1) {
        let milestoneIndex = this.containers[index].containerMilestones.findIndex(x => x.type == 'PLUG_IN')
        if (milestoneIndex > -1) {
          this.$set(this.containers[index].containerMilestones[milestoneIndex], 'date', null)
          this.containers[index].PLUG_IN_DATE = null
          this.$message.success("Successfully removed plug in date!");

        }
      }
    },
    async milestoneUpdate(data) {
      this.milestoneContainer.containerMilestones = data;
      this.addMilestoneModal = false;
      let find = this.containers.findIndex(
        (x) => x.id == this.milestoneContainer.id
      );
      this.containers[find].containerMilestones = data;


      for (let i = 0; i < data.length; i++) {
        // this.containers[find][data[i].type] = data[i];
        if (data[i].type == "PLUG_IN") {
          this.$set(this.containers[find], 'PLUG_IN_DESCRIPTION', data[i].description);
          this.$set(this.containers[find], 'PLUG_IN_DATE', data[i].date);
        } else if (data[i].type == "PLUG_OUT") {
          this.$set(this.containers[find], 'PLUG_OUT_DATE', data[i].date);
        }
      }
      let index = this.containers.findIndex(
        (x) => x.id == data[0].bookingContainerId
      );

      let gotPlugIn = data.find((x) => x.type == "PLUG_IN");
      let gotPlugOut = data.find((x) => x.type == "PLUG_OUT");



      if (this.containers[index].PLUG_IN && !gotPlugIn) {
        this.containers[index].PLUG_IN_DATE = null
        this.containers[index].PLUG_IN_DESCRIPTION = null
        //remove this.containers[index].PLUG_IN
        this.containers[index].PLUG_IN = null

      }

      if (this.containers[index].PLUG_OUT && !gotPlugOut) {
        this.containers[index].PLUG_OUT_DATE = null
      }
      // if(this.milestoneType === 'PLUG_IN'){
      //   this.containers[index].transportStatus = 'Plugged-in'
      //   await this.$API.updateContainer({
      //     id: this.containers[index].id,
      //     transportStatus: 'Plugged-in'
      //   })
      // }

      this.milestoneType = null
      await this.getPluggins()
      this.milestoneKey++;
    },

    openContainer(item) {
      // console.log("open container", item);
      this.chosenContainer = item;
      console.log('chosen container', this.chosenContainer)
      this.viewContainer = true;
    },
    setDateFilter() {
      console.log('this.params', this.params)
      if (this.params.dates.length == 2) {
        if (this.params.dates[1] < this.params.dates[0]) {
          let dates = [this.params.dates[1], this.params.dates[0]];
          this.params.dates = dates;
        }
      }

      localStorage.setItem("transportParams", JSON.stringify(this.params));
      this.params.selectedDate = null;
      this.getContainers();
      this.dateMenu = false;
    },

    setDateRange(val) {
      switch (val) {
        case "current_3day":
          this.params.dates = [
            new Date(new Date().setDate(new Date().getDate() - 1))
              .toISOString()
              .substring(0, 10),
            new Date(new Date().setDate(new Date().getDate() + 1))
              .toISOString()
              .substring(0, 10),
          ];
          break;
        case "past_month":
          this.params.dates = [
            new Date(new Date().setDate(new Date().getDate() - 30))
              .toISOString()
              .substring(0, 10),
            new Date(new Date().setDate(new Date().getDate() - 1))
              .toISOString()
              .substring(0, 10),
          ];
          break;

        case "current_week":
          this.params.dates = [
            new Date(
              new Date().setDate(new Date().getDate() - new Date().getDay() + 1)
            )
              .toISOString()
              .substring(0, 10),
            new Date(
              new Date().setDate(new Date().getDate() - new Date().getDay() + 7)
            )
              .toISOString()
              .substring(0, 10),
          ];
          break;

        case "current_month":
          this.params.dates = [
            new Date(new Date().setDate(1)).toISOString().substring(0, 10),
            new Date().toISOString().substring(0, 10),
          ];
          break;
        case "past_day":
          this.params.dates = [
            new Date(new Date().setDate(new Date().getDate() - 1))
              .toISOString()
              .substring(0, 10),
            new Date(new Date().setDate(new Date().getDate() - 1))
              .toISOString()
              .substring(0, 10),
          ];
          break;
        case "past_3day":
          this.params.dates = [
            new Date(new Date().setDate(new Date().getDate() - 3))
              .toISOString()
              .substring(0, 10),
            new Date(new Date().setDate(new Date().getDate() - 1))
              .toISOString()
              .substring(0, 10),
          ];
          break;
        case "past_week":
          this.params.dates = [
            new Date(new Date().setDate(new Date().getDate() - 7))
              .toISOString()
              .substring(0, 10),
            new Date(new Date().setDate(new Date().getDate() - 1))
              .toISOString()
              .substring(0, 10),
          ];
          break;
        case "yesterday":
          this.params.dates = [
            new Date(new Date().setDate(new Date().getDate() - 1))
              .toISOString()
              .substring(0, 10),
            new Date(new Date().setDate(new Date().getDate() - 1))
              .toISOString()
              .substring(0, 10),
          ];
          break;
        case "today":
          this.params.dates = [
            new Date().toISOString().substring(0, 10),
            new Date().toISOString().substring(0, 10),
          ];
          break;
        case "tomorrow":
          this.params.dates = [
            new Date(new Date().setDate(new Date().getDate() + 1))
              .toISOString()
              .substring(0, 10),
            new Date(new Date().setDate(new Date().getDate() + 1))
              .toISOString()
              .substring(0, 10),
          ];
          break;
        case "next_week":
          this.params.dates = [
            new Date(new Date().setDate(new Date().getDate() + 1))
              .toISOString()
              .substring(0, 10),
            new Date(new Date().setDate(new Date().getDate() + 7))
              .toISOString()
              .substring(0, 10),
          ];
          break;
        case "next_month":
          this.params.dates = [
            new Date(new Date().setDate(new Date().getDate() + 1))
              .toISOString()
              .substring(0, 10),
            new Date(new Date().setDate(new Date().getDate() + 30))
              .toISOString()
              .substring(0, 10),
          ];
          break;
      }
      this.getContainers();
    },
    resetFilters() {
      this.filters = {};
      this.filteredValues = [];
      this.selectAllContainers = false;
      this.selectedContainers = [];
      let keys = this.headers.map((x) => x.value);
      keys.forEach((x) => {
        this.searchHeaders[x] = null;
        this.filters[x] = [];
      });
      Object.keys(this.checkboxModels).forEach(key => {
        this.$set(this.checkboxModels, key, {});
      });

      this.updateFilterValue++;
      this.calculateFilteredResults();
    },
    async saveCustomHeaders() {
      this.loadingSaveCustom = true;
      if (this.userLayout && this.userLayout.id) {
        let result = await this.$API.updateUserTableLayout({
          id: this.userLayout.id,
          config: this.selectedHeaders,
        });
        this.settingsChange = false;
        this.userLayout = result;
        this.userLayout.config = this.selectedHeaders;
        this.$message.success("Successfully updated!");
      } else {
        let result = await this.$API.createUserTableLayout({
          module: "Intermodal",
          config: this.selectedHeaders,
        });
        this.settingsChange = false;
        this.userLayout = result;
        this.userLayout.config = this.selectedHeaders;
        this.$message.success("Successfully saved setup!");
      }
      this.loadingSaveCustom = false;
    },
    async editETA(item, value) {
      this.etaItem = item
      this.etaTime = value
      this.editETAModal = true
    },
    // async updateETA(){
    //   this.savingField = true;
    //   await this.$API.updateContainer({
    //     id: this.etaItem.id,
    //     eta: this.etaTime,
    //   });
    //   this.savingField = false;
    //   //update the this.containers.eta where id = this.etaItem.id
    //   let index = this.containers.findIndex(x => x.id == this.etaItem.id)
    //   if (index > -1) {
    //     this.containers[index].eta = this.etaTime
    //   }
    //   this.etaItem={}
    //   this.etaTime=null
    //   this.editETAModal = false
    //   this.$message.success("Successfully updated!");
    // },
    async saveField() {
      this.savingField = true;
      this.fieldItem.container[this.fieldItem.field] = this.fieldItem.value;
      await this.$API.updateContainer({
        id: this.fieldItem.container.id,
        [this.fieldItem.field]: this.fieldItem.value,
      });
      this.editFieldModal = false;
      this.savingField = false;
    },
    async setBulkStatus(status, statusCode) {
      this.savingBulkStatus = true;
      let data = this.selectedContainers.map((x) => ({
        id: x.id,
        transportStatus: status,
        transportStatusCode: statusCode,
        intermodalUpdate: true,

      }));
      for (let i = 0; i < data.length; i++) {
        await this.$API.updateContainer(data[i]);
      }
      this.selectedContainers.forEach((x) => {
        x.transportStatus = status;
        x.transportStatusCode = statusCode;
      });
      this.savingBulkStatus = false;
      this.$message.success("Successfully updated!");
      this.bulkUpdateStatusModal = false;
    },
    selectPort(value) {
      if (this.params && !this.params.selectedPorts) {
        this.params.selectedPorts = []
        this.params.selectedPorts.push(value)
      }
      else {
        let find = this.params.selectedPorts.find(x => x.unLocode == value.unLocode)
        if (find) {
          this.params.selectedPorts = this.params.selectedPorts.filter(x => x.unLocode != value.unLocode)
        }
        else {
          this.params.selectedPorts.push(value)
        }
      }
      localStorage.setItem("transportParams", JSON.stringify(this.params));
      this.getContainers();

    },
    selectDate(value) {
      console.log('this.params', this.params)
      this.params.selectedDate = value;
      this.setDateRange(value);
      localStorage.setItem("transportParams", JSON.stringify(this.params));

    },
    selectContainer(item) {
      let find = this.selectedContainers.find((x) => x.id == item.id);
      if (!find) {
        this.selectedContainers.push(item);
      } else {
        this.selectedContainers = this.selectedContainers.filter(
          (x) => x.id != item.id
        );
      }
    },
    async setStatus(container, status) {
      container.transportStatus = status.text;
      container.transportStatusCode = status.value;
      if (status.value == "EN_ROUTE_LP1" || status.value == "EN_ROUTE_PORT" || status.value == "EN_ROUTE_DEPOT" || status.value == "EN_ROUTE_WB" || status.value == "EN_ROUTE_LP2") {
        this.milestoneContainer = container
        this.milestoneItem = await this.formatEnrouteMilestone(container, status.value)
        this.addMilestoneModal = true
      }
      await this.$API.updateContainer({
        id: container.id,
        transportStatus: status.text,
        transportStatusCode: status.value,
        intermodalUpdate: true,

      });
    },
    async formatEnrouteMilestone(container, status) {
      let milestone = {}
      if (status === "EN_ROUTE_LP1") {
        let loadPointDetails = container.LOADING_POINT_1
        milestone = {
          type: status,
          poiId: loadPointDetails.poiId,
          bookingContainerId: container.id,
          date: loadPointDetails.date,
          poi: loadPointDetails.poi,
          autosave: true,
          isActual: false,
        };
      }
      else if (status === 'EN_ROUTE_PORT') {
        let loadPointDetails = container.FULL_DROP_OFF
        milestone = {
          type: status,
          poiId: loadPointDetails.poiId,
          bookingContainerId: container.id,
          date: loadPointDetails.date,
          poi: loadPointDetails.poi,
          autosave: true,
          isActual: false,
        }
      }
      else if (status === 'EN_ROUTE_WB') {
        let loadPointDetails = container.EN_ROUTE_WB
        console.log('loadPointDetails', loadPointDetails);
        milestone = {
          type: status,
          // poiId: loadPointDetails.poiId,

          bookingContainerId: container.id,
          // date: loadPointDetails.date,
          // poi: loadPointDetails.poi,
          autosave: true,
          isActual: false,
        }
        if (loadPointDetails && loadPointDetails.poiId) {
          milestone.poiId = loadPointDetails.poiId
        }
        if (loadPointDetails && loadPointDetails.poi) {
          milestone.poi = loadPointDetails.poi
        }
        if (loadPointDetails && loadPointDetails.date) {
          milestone.date = loadPointDetails.date
        }
      }
      else if (status === 'EN_ROUTE_LP2') {
        let loadPointDetails = container.LOADING_POINT_2
        milestone = {
          type: status,
          poiId: loadPointDetails.poiId,

          bookingContainerId: container.id,
          date: loadPointDetails.date,
          poi: loadPointDetails.poi,
          autosave: true,
          isActual: false,
        }
      }
      else if (status === 'EN_ROUTE_DEPOT') {
        let loadPointDetails = container.EMPTY_PICKUP
        milestone = {
          type: status,
          poiId: loadPointDetails.poiId,

          bookingContainerId: container.id,
          date: loadPointDetails.date,
          poi: loadPointDetails.poi,
          autosave: true,
          isActual: false,
        }
      }

      return milestone
    },

    async startProcessingCTO() {
      this.processingCTO = true;
      for (let i = 0; i < this.selectedContainers.length; i++) {
        let result = await this.$API.generateCTO({
          containerId: this.selectedContainers[i].id,
        });
        if (result && result.id) {
          this.selectedContainers[i].ctoDocument = result;
          this.selectedContainers[i].ctoDocumentId = result.id;
        } else if (result && result.message) {
          this.selectedContainers[i].errorMessage = result.message;
        }

        this.ctoUpdateKey++;
      }
      this.processingCTO = false;
    },
    statusColor(status) {
      let color = this.bookingStatus.find(
        (x) => x.name.toLowerCase() === status.toLowerCase()
      );
      return color ? color.color : "grey";
    },
    statusIcon(status) {
      let find = this.bookingStatus.find(
        (x) => x.name.toLowerCase() === status.toLowerCase()
      );
      return find ? find.icon : "";
    },
    async submitFiles() {
      let pending = this.fileList.filter((x) => !x.loading);
      for (let i = 0; i < pending.length; i++) {
        var vm = this;
        let file = pending[i].file;
        if (window.FileReader) {
          var reader = new FileReader();
          if (pending[i].file.type == "text/csv") {
            reader.readAsText(pending[i].file);
            // Handle errors load
            reader.onload = function (event) {
              var csv = event.target.result;
              vm.parse_csv = vm.csvJSON(csv, file);
            };
          } else {
            reader.onload = (e) => {
              /* Parse data */
              const bstr = e.target.result;
              const wb = XLSX.read(bstr, { type: "binary" });
              /* Get first worksheet */
              const wsname = wb.SheetNames[0];
              const ws = wb.Sheets[wsname];
              /* Convert array of arrays */
              const data = XLSX.utils.sheet_to_json(ws, { header: 1 });
              //   console.log(data)
              vm.excelJson(data, file);
            };

            reader.readAsBinaryString(file);
          }

          reader.onerror = function (evt) {
            if (evt.target.error.name == "NotReadableError") {
              alert("Cannot read file !");
            }
          };
        } else {
          alert("FileReader are not supported in this browser.");
        }
      }
    },
    toggleColumn(item) {
      if (item.show === true) {
        this.headers.find((header) => header.value === item.value).show = true;
      } else {
        this.headers.find((header) => header.value === item.value).show = false;
      }
      this.selectedHeaders = this.headers.filter((x) => x.show);
    },
    updateAssignedContainers(containers) {
      containers.forEach((x) => {
        let find = this.containers.find((y) => y.id == x.id);
        if (find) {
          find = { ...find, ...x };
          if (x.transporter) {
            // console.log("x.transporter", x.transporter);
            x.transporterName = x.transporter.alias;
          }
        }
      });
      this.assignToView = false;
      this.selectedContainers = [];
    },
    updateSearchKey() {
      this.searchKey++;
    },
    preadviseIcon(status) {
      switch (status) {
        case 'Submitted':
          return 'forward_to_inbox'
        case 'Confirmed':
          return 'check_circle_outline'
        case 'Cancelled':
          return 'cancel'
        case 'Error':
          return 'warning'
        case 'null':
          return 'cancel'
        default: return 'notifications'
      }
    },
    preadviseStatusColor(status) {
      switch (status) {
        case 'Submitted':
          return 'blue'
        case 'Confirmed':
          return 'success'
        case 'Cancelled':
          return 'red'
        case 'Error':
          return 'red'
        case null:
          return 'orange'
        default: return 'grey'
      }
    },
    updateUserLayout() {
      this.settingsChange = true;
    },
    viewBooking(item) {
      this.$router.push({
        path: "/booking/" + item.systemReference,
      });
    },
    viewShipmentFile(file) {
      this.selectedFile = file;
      this.previewKey++;
      this.previewShipmentFile = true;
    },
  },
};
</script>
<style scoped>
/* .non-sticky-header {
  z-index: 1;
}
.sticky-column {
  position: sticky;
  left: 0;
  z-index: 5;


} */

.time-input-container {
  display: flex;
  justify-content: center;
  color: var(--v-primary-base);

}

.time-input {
  font-size: 2em;
  color: var(--v-primary-base);
}

.v-list-item {
  min-height: 32px !important;
  padding-top: 4px !important;
  padding-bottom: 4px !important;
}

.container {
  width: 100%;
  min-width: 750px;
  height: 650px;
  margin: auto;
  /* outline: solid 1px black; */
  display: flex;
  justify-content: center;
  align-items: center;
}

th div {
  border: 0;
  min-height: 20px;
  min-width: 20px;
}

.resizable {
  resize: horizontal;
  overflow: auto;
  width: 120px;
  height: 40px;
  margin: 0px;
  padding: 0px;
  display: block;
}

.resizable-content {
  min-width: 120px;
  resize: horizontal;
  overflow: auto;
  max-width: fit-content;
}
</style>
